import "./CuratedCollection.scss";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Breakpoint } from "react-socks";

export default function CuratedCollection({ homeData }) {
  const navigate = useNavigate();

  return (
    <div className="curated_Container bg-gray lg:pb-[50px] emd:pb-[40px] small:pb-[10px] ">
      <div className="BrowseWiseFaceShape_Title lg:py-10 emd:py-5 small:py-4">
        <h1 className="lg:text-[34px] emd:text-[30px] small:text-[25px] font-sabon">
          {homeData?.curated_section?.title}
        </h1>
      </div>
      <Breakpoint large up className="w-[100%] overflow-hidden">
        <div className="BrowseWiseFaceShape_Block lg:px-10 emd:px-10 small:px-0 w-full grid gap-x-0 xl:gap-y-24 fix_lg:gap-y-16 grid-cols-4">
          {homeData?.curated_section?.banners
            ?.slice(0, 4)
            ?.map((item, index) => (
              <div
                onClick={() =>
                  navigate(item?.redirect, {
                    state: { color: item?.button_text },
                  })
                }
                className="BrowseWiseFaceShape_card m-auto"
                key={index}
              >
                <div className="Card_img flex items-center">
                  <img
                    src={item.background}
                    alt="glass_img"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div className="Overlay"></div>
                <div className="Card_img_Overlay">
                  <img
                    src={item.image}
                    alt="glass_img"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div className="Card_Title lg:text-[24px] emd:text-[20px] small:text-[15px]">
                  <span>{item.button_text}</span>
                </div>
              </div>
            ))}
        </div>
      </Breakpoint>

      <Breakpoint className="w-full" medium down>
        <div className="carousel w-full px-4 grid gap-x-4 gap-y-2 grid-cols-2 emd:pb-[40px] small:pb-[10px]">
          {homeData?.curated_section?.banners
            ?.slice(0, 4)
            ?.map((item, index) => (
              <div
                onClick={() =>
                  navigate(item?.redirect, {
                    state: { color: item?.button_text },
                  })
                }
                className="BrowseWiseFaceShape_card bg-[#f7f7f8] flex flex-col items-center cursor-pointer"
                key={index}
              >
                <div className="Card_img flex items-center">
                  <img
                    src={item.background}
                    alt="glass_img"
                    height="100%"
                    width="90%"
                    className="m-auto"
                  />
                </div>
                <div className="Overlay"></div>
                <div className="Card_img_Overlay">
                  <img
                    src={item.image}
                    alt="glass_img"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div className="Card_Title lg:text-[24px] emd:text-[20px] small:text-[15px] py-2">
                  <span>{item.button_text}</span>
                </div>
              </div>
            ))}
        </div>
      </Breakpoint>

      {/* <div
        onClick={() => navigate(homeData?.curated_section?.redirect)}
        className="try-now-btn See_All_Frame_btn py-2 px-3 lg:text-base emd:text-base small:text-sm lg:mt-[10px] emd:mt-[10px] small:mt-[10px] text-nowrap whitespace-pre "
      >
        {homeData?.curated_section?.button_text}
        <div className="overlay"></div>
      </div> */}
    </div>
  );
}

// import React from "react";

// const CuratedCollection = ({ homeData }) => {
//   return (
//     <div className="w-full pt-[40px] pb-[60px] lg:px-[40px] emd:px-[30px] small:px-[20px] flex flex-col items-center bg-gray">
//       <span className="lg:text-3xl text text-center">Curated Collection</span>
//       <div className="w-full mt-10 grid lg:grid-cols-4 emd:grid-cols-2 small:grid-cols-2 lg:gap-x-20 lg:gap-y-20 emd:gap-x-10 emd:gap-y-10 small:gap-x-5 small:gap-y-5 lg:px-12 emd:px-4 small:px-0">
//         <div className="border-opacity-[0.6] border border-[#848484] flex flex-col px-4">
//           <div className="flex items-center justify-center">
//             <img src={homeData?.curated_section?.banners[0].background} alt="" />
//           </div>
//           <div className="w-full lg:px-4 emd:px-2 small:px-0">
//             <div className="border-t border-opacity-[0.6] border-[#848484] py-4 lg:px-4 emd:px-2 small:px-0 flex items-center justify-center text-center text-lg">
//               TORTOISE
//             </div>
//           </div>
//         </div>
//         <div className="border-opacity-[0.6] border border-[#848484] flex flex-col px-4">
//           <div className="flex items-center justify-center">
//             <img src={homeData?.curated_section?.banners[1].background} alt="" />
//           </div>
//           <div className="w-full px-4">
//             <div className="border-t border-opacity-[0.6] border-[#848484] py-4 px-4 flex items-center justify-center text-center text-lg">
//               TRANSPARENT
//             </div>
//           </div>
//         </div>
//         <div className="border-opacity-[0.6] border border-[#848484] flex flex-col px-4">
//           <div className="flex items-center justify-center">
//             <img src={homeData?.curated_section?.banners[2].background} alt="" />
//           </div>
//           <div className="w-full px-4">
//             <div className="border-t border-opacity-[0.6] border-[#848484] py-4 px-4 flex items-center justify-center text-center text-lg">
//               BLACK
//             </div>
//           </div>
//         </div>
//         <div className="border-opacity-[0.6] border border-[#848484] flex flex-col px-4">
//           <div className="flex items-center justify-center">
//             <img src={homeData?.curated_section?.banners[3].background} alt="" />
//           </div>
//           <div className="w-full px-4">
//             <div className="border-t border-opacity-[0.6] border-[#848484] py-4 px-4 flex items-center justify-center text-center text-lg">
//               GREEN
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CuratedCollection;
