import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import INPUT from "../../Master/INPUT";
import BTN from "../../Master/BTN";
import { useGetOrderDetailsDataQuery } from "../../../redux/slices/homeSlice";
import {
  ARROW_BACK_BLACK_IC,
  ARROW_KEYWORD_DOWN,
  ARROW_KEYWORD_UP,
  CALL_IC,
  CLOSE_BLACK_IC,
  MAIL_IC,
  OFFER_TAG_IC,
} from "../../../assets";
import MODEL from "../../Master/MODEL";

const TrackOrder = () => {
  // [
  //   "Placed",
  //   "In making",
  //   "Prepared",
  //   "Delivered",
  //   "Returned",
  //   "Return Initiated",
  //   "Exchanged",
  //   "Exchange Initiated",
  //   "Cancelled",
  //   "Not Placed",
  // ];

  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();

  const user = localStorage.getItem("user");
  const [isSelected, setIsSelected] = useState("none"); // shopping_details, payment_details

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("isServiceable");
    navigate("/login");
  };

  const {
    data: customerOrderDetailsData,
    isSuccess: customerOrderDetailsDataIsSuccess,
    isLoading: customerOrderDetailsDataIsLoading,
    isError: customerOrderDetailsDataIsError,
    error: customerOrderDetailsDataError,
  } = useGetOrderDetailsDataQuery(
    {
      user: user,
      orderId: params?.trackOrderId,
    },
    { skip: !user }
  );

  // estimated date is the date of order + 7 days
  // const estimatedDeliveryDate = new Date(customerOrderDetailsData?.updatedAt);
  // estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 7);
  // const options = { year: "numeric", day: "numeric", month: "numeric" };
  // const formattedEstimatedDeliveryDate =
  //   estimatedDeliveryDate.toLocaleDateString("en-US", options);

  // Formate Date
  // const formateOrderDate = new Date(customerOrderDetailsData?.updatedAt);
  // formateOrderDate.setDate(formateOrderDate.getDate());
  // const formattedOrderDate = formateOrderDate.toLocaleDateString(
  //   "en-US",
  //   options
  // );

  const formateDate = (date) => {
    const estimatedDeliveryDate = new Date(customerOrderDetailsData?.updatedAt);
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 7);
    const formateOrderDate = new Date(customerOrderDetailsData?.updatedAt);
    formateOrderDate.setDate(formateOrderDate.getDate());
    const orderDOptions = { year: "numeric", month: "long", day: "numeric" };
    const formattedOrderDate = formateOrderDate.toLocaleDateString(
      "en-GB",
      orderDOptions
    );
    return formattedOrderDate;
  };

  const estimatedDeliveryDate = new Date(customerOrderDetailsData?.updatedAt);
  estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 7);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedEstimatedDeliveryDate =
    estimatedDeliveryDate.toLocaleDateString("en-GB", options);

  const formateOrderDate = new Date(customerOrderDetailsData?.updatedAt);
  formateOrderDate.setDate(formateOrderDate.getDate());
  const orderDOptions = { year: "numeric", month: "long", day: "numeric" };
  const formattedOrderDate = formateOrderDate.toLocaleDateString(
    "en-GB",
    orderDOptions
  );

  const formatedSchedualeDeliveryDate = (dateStr) => {
    const date = new Date(dateStr);

    // Extract day, month, and year
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = date.toLocaleString("default", { month: "long" }); // Gets full month name
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const itemDetail = customerOrderDetailsData?.cart?.find(
    (item) => item?.sku === params?.trackProductSku
  );

  return (
    <div className="w-full flex lg:flex-row emd:flex-col small:flex-col">
      <div className="lg:w-[20%] h-[100dvh] edm:hidden small:hidden py-6 px-6 bg-gray lg:flex flex-col items-start justify-start">
        <Link
          to="/profile-details#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Your Account
        </Link>
        <Link
          to="/profile-details#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Order History
        </Link>
        <Link
          to="/profile-details#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Coupons
        </Link>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>

        <span className="text-xl mt-10">Need Assistance ?</span>
        <div className="flex flex-col items-start">
          <span className="underline mt-3 cursor-pointer select-none">
            Contact Us
          </span>
          <div className="flex items-center mt-3">
            <div className="material-symbols-outlined">
              <img src={CALL_IC} alt="" className="w-6" />
            </div>
            <a href={`tel:18002028223`} className="ms-2 cursor-pointer">
              18002028223{" "}
            </a>
          </div>
          <div className="flex items-center">
            <div className="material-symbols-outlined">
              <img src={MAIL_IC} alt="" className="w-6" />
            </div>
            <span className="ms-2">
              <a href="mailto:info@netre.in" className="email-link">
                info@netre.in
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray p-4 small:flex emd:flex lg:hidden items-center justify-start overflow-x-auto overflow-y-hidden text-nowrap whitespace-pre  hide_scrollbar">
        <Link
          to="/profile-details#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  select-none cursor-pointer`}
        >
          Your Account
        </Link>
        <Link
          to="/profile-details#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Order History
        </Link>
        <Link
          to="/profile-details#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Coupons
        </Link>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>
      </div>

      <div className="lg:w-[80%] emd:w-full small:w-full p-6">
        <div className="w-full flex pb-4 border-b border-light_secondary">
          <div
            className="material-symbols-outlined text-2xl cursor-pointer"
            onClick={() => navigate("/profile-details#order-history")}
          >
            <img src={ARROW_BACK_BLACK_IC} alt="" className="w-6" />
          </div>
          <span className="text-2xl font-bold ms-4">Track Order</span>
        </div>
        <div className="w-full my-5 flex justify-between flex-wrap">
          <div className="lg:w-1/2 emd:w-full small:w-full flex flex-col lg:pr-2">
            <div className="w-full flex flex-col pb-4 border-b">
              <span className="text-base font-semibold">
                Order Number : {customerOrderDetailsData?.invoice}
              </span>
              <span className="text-base font-semibold">
                Order Date : {formattedOrderDate}
              </span>
              <span className="text-base font-semibold">
                {customerOrderDetailsData?.deliveryDate
                  ? `Your Scheduled delivery date is : ${formatedSchedualeDeliveryDate(
                      customerOrderDetailsData?.deliveryDate
                    )}`
                  : `Estimated Delivery : ${formattedEstimatedDeliveryDate}`}
              </span>
            </div>
            <div className="w-fit mt-5 flex flex-col">
              <ul className="events">
                {itemDetail?.statusHistory?.map((item, index) => {
                  return (
                    <li key={index} className={`flex text-gray-500`}>
                      {itemDetail?.statusHistory?.length - 1 === index ? (
                        <time className="relative px-2 text-[#4caf50]"></time>
                      ) : (
                        <div className="relative px-2"></div>
                      )}
                      {itemDetail?.statusHistory?.length - 1 !== index ? (
                        <span className="relative px-4 pb-10 text-nowrap whitespace-pre ">
                          {item?.status} ({formateDate(item?.timestamp)})
                        </span>
                      ) : (
                        <div className="relative px-4 pb-10 last_upper text-nowrap whitespace-pre  text-[#4caf50]">
                          {item?.status} ({formateDate(item?.timestamp)})
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-full mt-5 flex lg:flex-row emd:flex-col small:flex-col justify-between items-start">
              <div className="lg:w-[48%] emd:w-full small:w-full flex flex-col">
                <span className="text-lg">Deliver to</span>
                <span className="text-sm">
                  <span>
                    {customerOrderDetailsData?.user_info?.address?.firstname +
                      " " +
                      customerOrderDetailsData?.user_info?.address?.lastname}
                  </span>
                  ,{" "}
                  <span>
                    {customerOrderDetailsData?.user_info?.address?.address1}
                  </span>
                  ,{" "}
                  {customerOrderDetailsData?.user_info?.address?.address2 && (
                    <span>
                      {customerOrderDetailsData?.user_info?.address?.address2}
                    </span>
                  )}
                  <span>
                    {customerOrderDetailsData?.user_info?.address?.zipCode}
                  </span>
                  ,{" "}
                  <span>
                    {customerOrderDetailsData?.user_info?.address?.city}
                  </span>
                  ,{" "}
                  <span>
                    {customerOrderDetailsData?.user_info?.address?.zipCode}
                  </span>
                  ,{" "}
                  <span>
                    {customerOrderDetailsData?.user_info?.address?.country}
                  </span>
                  <span className="flex items-center mt-1">
                    <img src={CALL_IC} alt="" className="w-5" />
                    <span className="ms-2">
                      {customerOrderDetailsData?.user_info?.address?.phone}
                    </span>
                  </span>
                </span>
              </div>
              <div className="lg:w-[48%] emd:w-full small:w-full lg:mt-0 emd:mt-5 small:mt-5 flex flex-col">
                <span className="text-lg">Mode of payment</span>
                <span className="text-sm">
                  {customerOrderDetailsData?.paymentMethod}
                </span>
              </div>
            </div>
          </div>
          <FilterProductCardWithSku
            data={customerOrderDetailsData}
            sku={params?.trackProductSku}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;

function FilterProductCardWithSku({ data, sku }) {
  const [openLensDetails, setOpenLensDetails] = useState(null);
  const [selectedShowPrescription, setSelectedShowPrescription] =
    useState(null);
  const itemDetail = data?.cart?.find((item) => item?.sku === sku);
  const navigate = useNavigate();
  // const calculatePrice = () => {
  //   const subTotal = item?.price * item?.quantity;
  //   // const discount =
  //   //   item?.prescription[0]?.discount === ""
  //   //     ? "-"
  //   //     : item?.prescription[0]?.discount;
  //   // const calcDiscount = discount === "-" ? 0 : discount;
  //   // const total = subTotal - calcDiscount;
  //   const total = subTotal;
  //   return { subTotal, /* discount, */ total };
  // };

  const mainItemTotalAddonsCount = itemDetail?.lensDetails?.reduce(
    (lensTotal, lens) => {
      return lensTotal + lens?.addons?.length;
    },
    0
  );

  const totalAddonsCount = data?.cart?.map((product) => {
    const total = product?.lensDetails?.reduce((lensTotal, lens) => {
      return lensTotal + (lens?.addons?.length || 0);
    }, 0);
    return total;
  });

  return (
    <div className="lg:w-1/2 emd:w-full small:w-full flex flex-col p-5 lg:mt-0 emd:mt-5 small:mt-5 bg-gray">
      <span className="text-2xl font-semibold">Order Details</span>
      <div className="w-full flex flex-col mt-5">
        <span className="text-xl mont-bold">Item Details</span>
        <div className="w-full mt-3 flex flex-col items-start justify-between">
          <div className="w-full flex flex-col border-b pb-4 border-light_secondary">
            <div className="w-full flex">
              <div
                onClick={() =>
                  navigate(
                    itemDetail?.slug
                      ? `/products/${itemDetail?.slug}`
                      : `/products/${itemDetail?.product_id}`
                  )
                }
                className="w-1/2 overflow-hidden flex items-center justify-center bg-white cursor-pointer"
              >
                <img
                  src={itemDetail?.images?.[0]?.replace(
                    "upload/",
                    "upload/if_w_gt_250/w_250/if_end/"
                  )}
                  alt=""
                  className="w-[250px] h-[150px] object-contain"
                />
              </div>
              <div className="flex flex-col items-start ps-4 justify-start w-[48%] h-full overflow-hidden">
                <span
                  onClick={() =>
                    navigate(
                      itemDetail?.slug
                        ? `/products/${itemDetail?.slug}`
                        : `/products/${itemDetail?.product_id}`
                    )
                  }
                  className="text-lg font-semibold cursor-pointer"
                >
                  {itemDetail?.title}
                </span>
                <span>₹{itemDetail?.price}</span>
                <div className="flex justify-evenly flex-col mt-2">
                  <div className="flex items-center">
                    <span className="text-sm text-nowrap whitespace-pre ">
                      SKU no :
                    </span>
                    <span className="text-sm ms-2">{itemDetail?.sku}</span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-sm">Qty :</span>
                    <span className="text-sm ms-4">{itemDetail?.quantity}</span>
                  </div>
                </div>
              </div>
            </div>
            {itemDetail?.lensDetails?.length > 0 && (
              <div className="w-full flex flex-col mt-4">
                <div className=" w-full flex flex-col">
                  <div
                    onClick={() =>
                      setOpenLensDetails(
                        itemDetail?.product_id !== openLensDetails
                          ? itemDetail?.product_id
                          : null
                      )
                    }
                    className="cursor-pointer w-full flex items-center justify-between select-none"
                  >
                    <div className="text-base mont-regular uppercase">
                      Lens Details{" "}
                      <span className="text-sm mont-bold">
                        <span className="mont-regular">( </span>
                        {itemDetail?.lensDetails?.length}{" "}
                        <span className="mont-regular"> Lens</span>
                        <span className="mont-regular">
                          {mainItemTotalAddonsCount > 0 && " + "}
                        </span>
                      </span>
                      {mainItemTotalAddonsCount > 0 ? (
                        <span className="text-sm mont-bold text-primary">
                          {mainItemTotalAddonsCount}
                          <span className="mont-regular text-primary">
                            {" "}
                            Addons
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="mont-regular"> )</span>
                    </div>
                    <img
                      src={
                        itemDetail?.product_id === openLensDetails
                          ? ARROW_KEYWORD_UP
                          : ARROW_KEYWORD_DOWN
                      }
                      alt=""
                      className=""
                    />
                  </div>

                  {itemDetail?.product_id === openLensDetails &&
                    itemDetail?.lensDetails?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full flex items-start justify-between mt-2 pb-2"
                        >
                          <div
                            onClick={() =>
                              setSelectedShowPrescription(
                                item?.prescriptionDetail
                              )
                            }
                            className="flex items-start"
                          >
                            <span>{index + 1}.</span>
                            <div className="text-base ms-2 cursor-pointer flex flex-col">
                              <span className="flex text-base">
                                {item.name + " Lens"} &nbsp;
                                <span className="text-nowrap whitespace-pre ">
                                  (₹ {item.price})
                                </span>
                              </span>
                              {item?.addons?.map((addon, addonIndex) => {
                                return (
                                  <span
                                    key={addonIndex}
                                    className="ms-2 text-light_secondary text-sm"
                                  >
                                    <span className="me-2 text-light_secondary">
                                      {addonIndex + 1}.
                                    </span>
                                    {addon?.name} &nbsp;
                                    <span className="text-nowrap whitespace-pre  text-light_secondary">
                                      (₹ {addon.price})
                                    </span>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {itemDetail?.isReadingGlasses === true && (
              <div className="w-full flex flex-col mt-4">
                <div className=" w-full flex flex-col">
                  <div
                    onClick={() =>
                      setOpenLensDetails(
                        itemDetail?.product_id !== openLensDetails
                          ? itemDetail?.product_id
                          : null
                      )
                    }
                    className="cursor-pointer w-full flex items-center justify-between select-none"
                  >
                    <div className="text-base mont-regular uppercase">
                      Power Details{" "}
                    </div>
                    <img
                      src={
                        itemDetail?.product_id === openLensDetails
                          ? ARROW_KEYWORD_UP
                          : ARROW_KEYWORD_DOWN
                      }
                      alt=""
                      className=""
                    />
                  </div>

                  {itemDetail?.product_id === openLensDetails &&
                    itemDetail?.readingGlassesPower?.map((power, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full flex items-start justify-start mt-2"
                        >
                          <span className="flex text-base items-center w-full text-light_secondary">
                            Frame {index + 1} Power = &nbsp;
                            <span className="text-secondary text-base">
                              {power}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
        {data?.cart?.length > 1 && (
          <div className="flex flex-col">
            <span className="text-xl mont-bold mt-5">
              Other Items in this Order
            </span>
            <div className="w-full mt-3 flex flex-col items-start justify-between p-2">
              {data?.cart?.map((item, index) => {
                return (
                  itemDetail?.sku !== item?.sku && (
                    <div
                      key={index}
                      className="w-full flex flex-col pb-2 border-b mb-5 border-b-light_secondary"
                    >
                      <div className="w-full flex items-start">
                        <div
                          onClick={() =>
                            navigate(
                              item?.slug
                                ? `/products/${item?.slug}`
                                : `/products/${item?.product_id}`
                            )
                          }
                          className="w-1/2 overflow-hidden bg-white cursor-pointer"
                        >
                          <img
                            src={item?.images?.[0]?.replace(
                              "upload/",
                              "upload/if_w_gt_250/w_250/if_end/"
                            )}
                            alt=""
                            className="w-[250px] h-[150px] object-contain"
                          />
                        </div>
                        <div className="flex flex-col items-start ps-4 justify-start w-[48%] h-full overflow-hidden">
                          <span
                            onClick={() =>
                              navigate(
                                item?.slug
                                  ? `/products/${item?.slug}`
                                  : `/products/${item?.product_id}`
                              )
                            }
                            className="text-lg font-semibold cursor-pointer"
                          >
                            {item?.title}
                          </span>
                          <span>₹{item?.price}</span>
                          <div className="flex justify-evenly flex-col mt-2">
                            <div className="flex items-center">
                              <span className="text-sm">SKU no. :</span>
                              <span className="text-sm ms-4">{item?.sku}</span>
                            </div>
                            <div className="flex items-center">
                              <span className="text-sm">Qty :</span>
                              <span className="text-sm ms-4">
                                {item?.quantity}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item?.lensDetails?.length > 0 && (
                        <div className="w-full flex flex-col mt-4">
                          <div className=" w-full flex flex-col">
                            <div
                              onClick={() =>
                                setOpenLensDetails(
                                  item?.product_id !== openLensDetails
                                    ? item?.product_id
                                    : null
                                )
                              }
                              className="cursor-pointer w-full flex items-center justify-between select-none"
                            >
                              <div className="text-base mont-regular uppercase">
                                Lens Details{" "}
                                <span className="text-sm mont-bold">
                                  <span className="mont-regular">( </span>
                                  {item?.lensDetails?.length}{" "}
                                  <span className="mont-regular"> Lens</span>
                                  <span className="mont-regular">
                                    {mainItemTotalAddonsCount > 0 && " + "}
                                  </span>
                                </span>
                                {mainItemTotalAddonsCount > 0 ? (
                                  <span className="text-sm mont-bold text-primary">
                                    {mainItemTotalAddonsCount}
                                    <span className="mont-regular text-primary">
                                      {" "}
                                      Addons
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                <span className="mont-regular"> )</span>
                              </div>

                              <img
                                src={
                                  item?.product_id === openLensDetails
                                    ? ARROW_KEYWORD_UP
                                    : ARROW_KEYWORD_DOWN
                                }
                                alt=""
                                className=""
                              />
                            </div>

                            {item?.product_id === openLensDetails &&
                              item?.lensDetails?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="w-full flex items-start justify-between mt-2 pb-2"
                                  >
                                    <div
                                      onClick={() =>
                                        setSelectedShowPrescription(
                                          item?.prescriptionDetail
                                        )
                                      }
                                      className="flex items-start"
                                    >
                                      <span>{index + 1}.</span>
                                      <div className="text-base ms-2 cursor-pointer flex flex-col">
                                        <span className="flex text-base">
                                          {item.name + " Lens"} &nbsp;
                                          <span className="text-nowrap whitespace-pre ">
                                            (₹ {item.price})
                                          </span>
                                        </span>
                                        {item?.addons?.map(
                                          (addon, addonIndex) => {
                                            return (
                                              <span
                                                key={addonIndex}
                                                className="ms-2 text-light_secondary text-sm"
                                              >
                                                <span className="me-2 text-light_secondary">
                                                  {addonIndex + 1}.
                                                </span>
                                                {addon?.name} &nbsp;
                                                <span className="text-nowrap whitespace-pre  text-light_secondary">
                                                  (₹ {addon.price})
                                                </span>
                                              </span>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                      {item?.isReadingGlasses === true && (
                        <div className="w-full flex flex-col mt-4">
                          <div className=" w-full flex flex-col">
                            <div
                              onClick={() =>
                                setOpenLensDetails(
                                  item?.product_id !== openLensDetails
                                    ? item?.product_id
                                    : null
                                )
                              }
                              className="cursor-pointer w-full flex items-center justify-between select-none"
                            >
                              <div className="text-base mont-regular uppercase">
                                Power Details{" "}
                              </div>

                              <img
                                src={
                                  item?.product_id === openLensDetails
                                    ? ARROW_KEYWORD_UP
                                    : ARROW_KEYWORD_DOWN
                                }
                                alt=""
                                className=""
                              />
                            </div>

                            {item?.product_id === openLensDetails &&
                              item?.readingGlassesPower?.map((power, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="w-full flex items-start justify-start mt-2"
                                  >
                                    <span className="flex text-base items-center w-full text-light_secondary">
                                      Frame {index + 1} Power = &nbsp;
                                      <span className="text-secondary text-base">
                                        {power}
                                      </span>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="pt-3 w-full flex items-center justify-between">
        <span className="text-base">Sub total</span>
        <span className="text-base">₹{data?.subTotal} </span>
      </div>
      <div className="w-full mt-1 flex items-center justify-between">
        <span className="text-base">Delivery</span>
        <span className="text-base">₹{data?.shippingCost}</span>
      </div>
      <div className="w-full mt-1 pb-2 flex items-center justify-between">
        <span className="text-base">Offer discount</span>
        <span className="text-base">₹{data?.discount}</span>
      </div>
      {data?.couponCode && (
        <div className="my-2">
          <div className="flex items-center">
            <div className="bg-primary px-2 py-1 rounded flex items-center">
              <div className="flex items-center text-base">
                <img src={OFFER_TAG_IC} alt="" />
                <span className="text-white ms-2">{data?.couponCode}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full pt-3 border-t flex items-center justify-between">
        <span className="text-lg">Total</span>
        <span className="text-lg">₹{data?.total}</span>
      </div>

      {/* =================== PRESCRIPTION DETAILS MODEL =============== */}
      <MODEL
        setShowModel={() => setSelectedShowPrescription(null)}
        showModel={selectedShowPrescription !== null}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Prescription details</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setSelectedShowPrescription(null)}
            />
          </div>
          <div className="w-full my-5 px-5">
            <div className="xl:flex-row emd:flex-row small:flex-col fix_lg:flex-col flex items-center justify-between">
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 flex flex-col justify-evenly items-center xl:border-r emd:border-r small:border-b fix_lg:border-b xl:border-b-0 emd:border-b-0 small:border-r-0 fix_lg:border-r-0 border-light_secondary  xl:pr-3 fix_lg:pr-0 emd:pr-3 small:pr-0 xl:pb-0 emd:pb-0 small:pb-2 fix_lg:pb-2">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Left Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.leftEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {selectedShowPrescription?.leftEye?.left_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.leftEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.leftEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 lg:pl-3 fix_lg:pl-0 emd:pl-3 small:pl-0 flex flex-col justify-evenly items-center">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Right Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.rightEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {item?.leftEye?.right_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.rightEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.rightEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MODEL>
    </div>
  );
}
