import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { homeSlice } from "../slices/homeSlice";
import wishlistReducer from "../slices/wishlistSlice";
import myBagReducer from "../slices/myBagSlice";
import myBagToggleReducer from "../slices/myBagToggleSlice";
import recentProductsReducer from "../slices/recentProductsSlice";
import homeTryOnReducer from "../slices/homeTryonSlice";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use sessionStorage if needed

const rootReducer = combineReducers({
  wishlist: wishlistReducer,
  myBagData: myBagReducer,
  homeTryOnData: homeTryOnReducer,
  myBagToggle: myBagToggleReducer,
  recentProducts: recentProductsReducer,
  [homeSlice.reducerPath]: homeSlice.reducer,
});

const persistConfig = {
  key: 'root', // Key to save the persisted data
  storage,     // Default is localStorage,
  version : 1,
  whitelist: [ 'myBagData', 'homeTryOnData', 'myBagToggle', 'recentProducts'] // this data will be persisted
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(homeSlice.middleware),
});
setupListeners(store.dispatch);
export const persistor = persistStore(store);

// store.subscribe(() => {
//   console.log('Current State:', store.getState()); // Log current Redux state
// });

// persistor.subscribe(() => {
//   console.log('Rehydrated:', persistor.getState()); // Log rehydration state
// });