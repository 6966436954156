import React, { useState } from "react";
import { Range, Direction } from "react-range";
import {
  WHITE_ARROW_KEYWORD_DOWN,
  WHITE_ARROW_KEYWORD_LEFT,
  WHITE_ARROW_KEYWORD_RIGHT,
  WHITE_ARROW_KEYWORD_UP,
} from "../../assets";

const VerticalSlider = ({ className, value, setValue, isVertical }) => {
  const MIN = 0.50;
  const MAX = 3;
  const STEP = 0.25;

  // Generate tick values based on the step size
  const ticks = Array.from(
    { length: (MAX - MIN) / STEP + 1 },
    (_, i) => MIN + i * STEP
  );

  // Handle "Up" button click
  const handleUp = () => {
    setValue((prev) => {
      const newValue = Math.min(prev[0] + STEP, MAX);
      return [newValue];
    });
  };

  // Handle "Down" button click
  const handleDown = () => {
    setValue((prev) => {
      const newValue = Math.max(prev[0] - STEP, MIN);
      return [newValue];
    });
  };

  // Handle "Right" button click
  const handleRight = () => {
    setValue((prev) => {
      const newValue = Math.min(prev[0] + STEP, MAX);
      return [newValue];
    });
  };

  // Handle "Left" button click
  const handleLeft = () => {
    setValue((prev) => {
      const newValue = Math.max(prev[0] - STEP, MIN);
      return [newValue];
    });
  };

  return (
    <div>
      {isVertical ? (
        <div
          className={`${className} flex flex-col items-center justify-center`}
        >
          {/* Up Arrow */}
          <button
            className="w-6 h-6 bg-secondary flex items-center justify-center rounded-full mb-4"
            onClick={handleUp}
          >
            <img src={WHITE_ARROW_KEYWORD_UP} alt="Up" />
          </button>

          {/* Vertical Slider */}
          <div className="relative h-60 w-12">
            <Range
              values={value}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(values) => setValue(values)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  className="h-full w-1 bg-[#BEBEBE] rounded relative"
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {/* Render Ticks */}
                  {ticks.map((tick, index) => {
                    // Customize border sizes
                    const isLarge = tick % 1 === 0; // Large border for integers
                    const isSmall = tick % 0.5 === 0 && !isLarge; // Small border for halves
                    const borderSize = isLarge
                      ? "px-2.5 border"
                      : isSmall
                      ? "px-2 border-[0.7px]"
                      : "px-1 border-[0.5px]";

                    return (
                      <div
                        key={index}
                        className={`absolute flex items-center right-1 w-full ${borderSize} border-[#BEBEBE]`}
                        style={{
                          top: `${((MAX - tick) / (MAX - MIN)) * 100}%`,
                        }}
                      >
                        {/* Tick Value */}
                        <div className="absolute right-6 text-[12px]">
                          {isLarge ? tick : ""}
                        </div>
                      </div>
                    );
                  })}
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  className="cursor-pointer w-3 h-3 bg-gray border-2 border-primary rounded-full flex items-center justify-center"
                  style={{
                    outline: "none",
                  }}
                >
                  {/* Tooltip */}
                  <div className="absolute -right-12 bg-primary text-white text-xs px-2 py-1 rounded flex items-start">
                    <span className="absolute -left-1 arrow-left -z-9"></span>
                    {value[0].toFixed(2)}
                  </div>
                </div>
              )}
              orientation="vertical" // Vertical orientation
              direction={Direction.Up} // Use Direction.Up for top-to-bottom slider
            />
          </div>

          {/* Down Arrow */}
          <button
            className="w-6 h-6 bg-secondary flex items-center justify-center rounded-full mt-4"
            onClick={handleDown}
          >
            <img src={WHITE_ARROW_KEYWORD_DOWN} alt="Down" />
          </button>
        </div>
      ) : (
        <div
          className={`${className} lg:hidden emd:flex small:flex  flex-row items-center justify-center`}
        >
          {/* Left Arrow */}
          <button
            className="min-w-4 min-h-4 max-w-4 max-h-4 bg-secondary flex items-center justify-center rounded-full mr-2"
            onClick={handleLeft}
          >
            <img src={WHITE_ARROW_KEYWORD_LEFT} alt="Left" />
          </button>

          {/* Horizontal Slider */}
          <div className="relative w-60 h-12">
            <Range
              values={value}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(values) => setValue(values)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  className="w-full h-1 bg-[#BEBEBE] rounded relative"
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {/* Render Ticks */}
                  {ticks.map((tick, index) => {
                    // Customize border sizes
                    const isLarge = tick % 1 === 0; // Large border for integers
                    const isSmall = tick % 0.5 === 0 && !isLarge; // Small border for halves
                    const borderSize = isLarge
                      ? "h-2.5 border"
                      : isSmall
                      ? "h-2 border-[0.7px]"
                      : "h-1 border-[0.5px]";

                    return (
                      <div
                        key={index}
                        className={`absolute flex flex-col items-center top-1 h-full ${borderSize} border-[#BEBEBE]`}
                        style={{
                          left: `${((tick - MIN) / (MAX - MIN)) * 100}%`,
                        }}
                      >
                        {/* Tick Value */}
                        <div className="absolute top-3 text-[12px]">
                          {isLarge ? tick : ""}
                        </div>
                      </div>
                    );
                  })}
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  className="cursor-pointer w-3 h-3 bg-gray border-2 border-primary rounded-full flex items-center justify-center"
                  style={{
                    outline: "none",
                  }}
                >
                  {/* Tooltip */}
                  <div className="absolute -top-9 bg-primary text-white text-xs px-2 py-1 rounded flex items-center">
                    <span className="absolute -bottom-1.5 arrow-bottom left-3.5 -z-9"></span>
                    {value[0].toFixed(2)}
                  </div>
                </div>
              )}
              orientation="horizontal" // Horizontal orientation
              direction={Direction.Right} // Use Direction.Right for left-to-right slider
            />
          </div>

          {/* Right Arrow */}
          <button
            className="min-w-4 min-h-4 max-w-4 max-h-4 bg-secondary flex items-center justify-center rounded-full ml-2"
            onClick={handleRight}
          >
            <img src={WHITE_ARROW_KEYWORD_RIGHT} alt="Right" />
          </button>
        </div>
      )}
    </div>
  );
};

export default VerticalSlider;
