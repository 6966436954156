import React from "react";
import INPUT from "../../Master/INPUT";
import BTN from "../../Master/BTN";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useContactUsMutation } from "../../../redux/slices/homeSlice";
import { isValidEmail } from "../../Footer/Footer";
import { CALL_IC, MAIL_IC } from "../../../assets";

const ContactUs = () => {
  const [contactUs] = useContactUsMutation();

  const contactFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    onSubmit: async function (values) {
      contactUs({
        body: {
          name: values?.name,
          email: values?.email,
          phone: values?.phone,
          message: values?.message,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          contactFormik?.resetForm();
          toast.success("Message sent successfully.");
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.name) {
        errors.name = "Name is Required!";
      } else if (!values?.email) {
        errors.email = "email id is Required!";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Invalid Email!";
      } else if (!values?.phone) {
        errors.phone = "Phone number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phone)) {
        errors.phone = "Invalid Phone Number";
      } else if (values.phone.length !== 10) {
        errors.phone = "Phone Number must be 10 digits";
      } else if (!values?.message) {
        errors.message = "Message is Required!";
      }
      return errors;
    },
  });

  return (
    <div className="w-full h-full bg-gray flex items-start justify-between flex-wrap px-8">
      <div className="flex flex-col mt-16">
        <span className="text-4xl text-secondary">Contact Us</span>
        <span className="text-secondary mt-2">
          Get in touch! You can email us, call, <br /> or complete the form for
          any inquiries.
        </span>
        <div className="flex items-center mt-3">
          <div className="material-symbols-outlined ">
            <img src={CALL_IC} alt="" className="w-6" />
          </div>
          <a href={`tel:18002028223`} className="ms-2 cursor-pointer">18002028223 </a>
        </div>
        <div className="flex items-center mt-2">
          <div className="material-symbols-outlined ">
            <img src={MAIL_IC} alt="" className="w-6" />
          </div>
          <span className="ms-2">
            <a href="mailto:info@netre.in" className="email-link">
              info@netre.in
            </a>
          </span>
        </div>
      </div>
      <div className="lg:w-[40%] md:w-[50%] small:w-full mb-16 mt-16 py-10 flx flex-col items-center justify-center px-5 bg-white rounded-lg">
        <div className="flex flex-col">
          <span className="text-2xl">Get in Touch</span>
          <span className="text-lg">You can reach us anytime</span>
        </div>
        <INPUT
          placeHolder="Name"
          className="mt-8"
          name="name"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.name}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.name}
          touched={contactFormik.touched.name}
        />
        <INPUT
          placeHolder="Email"
          className="mt-5"
          name="email"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.email}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.email}
          touched={contactFormik.touched.email}
        />
        <INPUT
          placeHolder="Phone No."
          className="mt-5"
          name="phone"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.phone}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.phone}
          touched={contactFormik.touched.phone}
        />
        <INPUT
          placeHolder="Message"
          rows={3}
          className="mt-5"
          name="message"
          onChange={contactFormik.handleChange}
          val={contactFormik.values.message}
          onBlur={contactFormik.handleBlur}
          errorMsg={contactFormik.errors.message}
          touched={contactFormik.touched.message}
        />
        <BTN
          className={"mt-8 m-auto small:w-full md:w-auto lg:w-auto"}
          title="Submit"
          type="submit"
          onP={contactFormik?.handleSubmit}
        />
      </div>
    </div>
  );
};

export default ContactUs;
