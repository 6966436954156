import React from "react";
import { useNavigate } from "react-router-dom";
import { Breakpoint } from "react-socks";

const Tryon1BlockNew = ({ homeData }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full text-center">
     <div className="lg:text-[34px] emd:text-[30px] small:text-[25px] font-sabon text-color text-center lg:mb-5 emd:mb-5 small:mb-5">
        Our Collections
      </div>
      <Breakpoint large up>
        <div
          className="tryon_new gap-[1.6vw] grid grid-cols-2 mb-[30px] px-[3%]"
          style={{
            width: "100%",
            maxWidth: "100%",
            position: "relative",
            zIndex: 1,
          }}
        >
          {homeData?.grid_banners?.map((item, index) => {
            return (
              <div
                key={index}
                className={`aspect-[9/4] bg-opacity-35 relative`}
                style={{
                  backgroundImage: `url(${item?.desktop})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => navigate(item?.redirect)}
              >
                {/* <img
                  // src={item?.desktop}
                  src={`/new/grid_${index + 1}_desktop.jpg`}
                  alt=""
                  className=" aspect-[9/4] "
                  onClick={() => navigate(item?.redirect)}
                /> */}
                {/* <div
                className="try-now-btn mont-regular text-nowrap whitespace-pre  w-fit h-fit px-3 py-2 border cursor-pointer absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                onClick={() => navigate(item?.redirect)}
              >
                {homeData?.grid_banners_button_text}
                <div className="overlay"></div>
              </div> */}
              </div>
            );
          })}

          {/* <div className="Plus_Block">
        <div
          className="Buy1Get1_btn"
          onClick={() =>
            navigate(homeData?.grid_banners?.left_banner?.redirect)
          }
        >
          {homeData?.grid_banners?.left_banner?.button_text}
        </div>
      </div> */}
        </div>
      </Breakpoint>
      <Breakpoint medium down>
        <div
          className="tryon_new gap-5 grid grid-cols-2 mb-5"
          style={{
            width: "100%",
            maxWidth: "100%",
            position: "relative",
            zIndex: 1,
          }}
        >
          {homeData?.grid_banners?.map((item, index) => {
            return (
              <div
                key={index}
                className="aspect-[1/2] overflow-hidden bg-opacity-35 relative"
                onClick={() => navigate(item?.redirect)}
                style={{
                  backgroundImage: `url(${item?.mobile})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* <img
                  src={item?.mobile}
                  alt=""
                  className="w-full h-full object-cover"
                  onClick={() => navigate(item?.redirect)}
                /> */}
                {/* <div
                className="try-now-btn mont-regular text-nowrap whitespace-pre  w-fit h-fit px-3 py-2 border cursor-pointer absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                onClick={() => navigate(item?.redirect)}
              >
                {homeData?.grid_banners_button_text}
                <div className="overlay"></div>
              </div> */}
              </div>
            );
          })}
        </div>
      </Breakpoint>
    </div>
  );
};

export default Tryon1BlockNew;
