import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import INPUT from "../../Master/INPUT";
import BTN from "../../Master/BTN";
import {
  ADD_IC,
  ARROW_BACK_BLACK_IC,
  ARROW_FORWARD_BLACK_IC,
  CALL_IC,
  DOWN_ARROW_IC,
  MAIL_IC,
} from "../../../assets";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  useExchangeOrderMutation,
  useGetOrderDetailsDataQuery,
  useGetProductsQuery,
  useGetUserProfileQuery,
} from "../../../redux/slices/homeSlice";
import { ProductCard } from "../Category/Category";

const ExchangeOrder = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();
  const [isSelected, setIsSelected] = useState("none"); // shopping_details, payment_details
  const virtualProductsRef = useRef(null);
  const sectionStartRef = useRef(null);
  const user = localStorage.getItem("user");

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("isServiceable");
    navigate("/login");
  };

  const [damagedProductVal, setDamagedProductVal] = useState("");
  const [wrongSizeVal, setWrongSizeVal] = useState("");
  const [wrongProductVal, setWrongProductVal] = useState("");
  const [otherToggle, setOtherToggle] = useState("");

  const [exchangeOrder] = useExchangeOrderMutation();
  const exchangeOrderFormik = useFormik({
    initialValues: {
      other_reason: "",
    },
    onSubmit: async function (values) {
      if (!newFrame) {
        toast.error("Please select a frame to exchange with.");
        return;
      } else if (!selectedAddress) {
        toast.error("Please select a shipping address.");
        return;
      }
      // ₹{oldFrame?.price && newFrame?.prices?.price && (newFrame.prices.price - oldFrame.price)}
      if (
        newFrame?.prices?.price &&
        oldFrame?.price &&
        newFrame.prices.price - oldFrame.price < 0
      ) {
        toast.error("New frame price should be greater than old frame price.");
        return;
      }
      const exchangeReason = `${
        damagedProductVal +
        " " +
        wrongSizeVal +
        " " +
        wrongProductVal +
        " " +
        values.other_reason
      }`;
      const body = {
        ...exchangeOrderBody,
        exchangedCart: {
          ...exchangeOrderBody.exchangedCart,
          exchangeReason: exchangeReason,
        },
      };
      exchangeOrder({ user: user, body: body }).then((res) => {
        if (res.data) {
          toast.success("Exchange Order Placed Successfully.");
          navigate("/profile-details#order-history");
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (
        damagedProductVal === "" &&
        wrongProductVal === "" &&
        wrongSizeVal === "" &&
        otherToggle === ""
      ) {
        // toast.error("Select reason for exchange order.");
        errors.other_reason = "Select reason for exchange order.";
      } else if (otherToggle !== "" && values?.other_reason === "") {
        errors.other_reason = "Other Reason is Required!";
      }
      return errors;
    },
  });

  const {
    data: customerOrderDetailsData,
    isSuccess: customerOrderDetailsDataIsSuccess,
    isLoading: customerOrderDetailsDataIsLoading,
    isError: customerOrderDetailsDataIsError,
    error: customerOrderDetailsDataError,
  } = useGetOrderDetailsDataQuery(
    {
      user: user,
      orderId: params?.exchangeOrderId,
    },
    { skip: !user }
  );

  const [oldFrame, setOldFrame] = useState(null);
  const [newFrame, setNewFrame] = useState(null);
  useEffect(() => {
    if (newFrame) {
      sectionStartRef?.current?.scrollIntoView({
        behavior: "smooth",
        top: 0,
        left: 0,
      });
    }
  }, [newFrame]);
  // find the productData from the customerOrderDetailsData.cart array which _id is equal to params?.exchangeProductId
  useEffect(() => {
    if (customerOrderDetailsDataIsSuccess) {
      const data = customerOrderDetailsData.cart;
      const oldFrameData = data.find(
        (item) => item.product_id === params?.exchangeProductId
      );
      setOldFrame(oldFrameData);
    }
  }, [customerOrderDetailsData, customerOrderDetailsDataIsSuccess]);
  const [showInstoreProducts, setShowInstoreProducts] = useState(false);

  // ================== Customer Profile data ==================
  const {
    data: customerProfileData,
    isSuccess: customerProfileDataIsSuccess,
    isLoading: customerProfileDataIsLoading,
    isError: customerProfileDataIsError,
    error: customerProfileDataError,
  } = useGetUserProfileQuery({ user: user }, { skip: !user });

  const [selectedAddress, setSelectedAddress] = useState(null);
  //select the first address as default
  useEffect(() => {
    if (customerProfileDataIsSuccess) {
      setSelectedAddress(customerProfileData?.customer?.addresses[0]);
    }
  }, [customerProfileDataIsSuccess, customerProfileData]);

  const [exchangeOrderBody, setExchangeOrderBody] = useState(null);
  useEffect(() => {
    if (
      customerOrderDetailsData &&
      oldFrame &&
      selectedAddress &&
      newFrame &&
      customerProfileData
    ) {
      const body = {
        orderId: customerOrderDetailsData.orderId,
        cart: [
          {
            images: newFrame.image,
            name: newFrame.name,
            sku: newFrame.sku,
            price: newFrame.prices.price,
            product_id: newFrame._id,
            quantity: 1,
            title: newFrame?.title?.en,
            variants: newFrame?.variants || [],
            attributes: newFrame?.attributes || [],
            orderStatus: "Placed",
            netreCare: [],
            exchangedItem_old: [
              {
                product_id: oldFrame.product_id,
                name: oldFrame.name,
                images: oldFrame.images,
                sku: oldFrame.sku,
                quantity: oldFrame.quantity,
                price: oldFrame.price,
                variants: [],
                orderStatus: "Exchange Initiated",
                netreCare: [],
                cluster: oldFrame.cluster,
                title: oldFrame.name,
                storeId: oldFrame?.storeId,
                exchangeReason: `${damagedProductVal+ ", " + wrongSizeVal+ ", " + wrongProductVal+ ", " + exchangeOrderFormik.values.other_reason}`,
                orderId: customerOrderDetailsData.orderId,
              },
            ],
          },
        ],
        discount: 0,
        user_info: {
          firstname: customerOrderDetailsData.user_info.firstname,
          lastname: customerOrderDetailsData.user_info.lastname,
          email: customerOrderDetailsData.user_info.email,
          contact: customerOrderDetailsData.user_info.contact,
          address: {
            _id: selectedAddress._id,
            firstname: selectedAddress.firstname,
            lastname: selectedAddress.lastname,
            phone: selectedAddress.phone,
            address1: selectedAddress.address1,
            address2: selectedAddress.address2,
            city: selectedAddress.city,
            zipCode: selectedAddress.zipCode,
            country: selectedAddress.country,
            label: selectedAddress.label,
          },
        },
        shippingOption: customerOrderDetailsData.shippingOption,
        couponCode: "",
        paymentMethod: customerOrderDetailsData.paymentMethod,
        status: "Placed",
        subTotal: customerOrderDetailsData.subTotal,
        shippingCost: customerOrderDetailsData.shippingCost,
        total: customerOrderDetailsData.total,
        netreCashUsed: 0,
        customerId: customerProfileData.customer._id,
        checkoutType: "customer",
        exchangedCart: {
          name: oldFrame.name,
          product_id: oldFrame.product_id,
          sku: oldFrame.sku,
          exchangeReason: `${damagedProductVal+ ", " + wrongSizeVal+ ", " + wrongProductVal+ ", " + exchangeOrderFormik.values.other_reason}`,
        },
      };
      setExchangeOrderBody(body);
    }
  }, [
    customerOrderDetailsData,
    oldFrame,
    selectedAddress,
    newFrame,
    customerProfileData,
  ]);
console.log("------>",damagedProductVal+ ", " + wrongSizeVal+ ", " + wrongProductVal+ ", " + exchangeOrderFormik.values.other_reason)
  return (
    <div className="exchange-order" ref={sectionStartRef}>
      <div className="w-full flex lg:flex-row emd:flex-col small:flex-col">
        <div className="lg:w-[20%] h-[100dvh] edm:hidden small:hidden py-6 px-6 bg-gray lg:flex flex-col items-start justify-start">
          <Link
            to="/profile-details#your-account"
            className={` ${
              hash === "#your-account" ? "text-primary" : ""
            }  mb-6 select-none cursor-pointer`}
          >
            Your Account
          </Link>
          <Link
            to="/profile-details#order-history"
            className={` ${
              hash === "#order-history" ? "text-primary" : ""
            }  mb-6 select-none cursor-pointer`}
          >
            Order History
          </Link>
          <Link
            to="/profile-details#coupons"
            className={` ${
              hash === "#coupons" ? "text-primary" : ""
            }  mb-6 select-none cursor-pointer`}
          >
            Coupons
          </Link>
          <span
            className={` ${
              hash === "#" ? "text-primary" : ""
            }  mb-6 select-none cursor-pointer`}
            onClick={handleLogout}
          >
            Logout
          </span>

          <span className="text-xl mt-10">Need Assistance ?</span>
          <div className="flex flex-col items-start">
            <span className="underline mt-3 cursor-pointer select-none">
              Contact Us
            </span>
            <div className="flex items-center mt-3">
              <div className="material-symbols-outlined">
                <img src={CALL_IC} alt="" className="w-6" />
              </div>
              <a href={`tel:18002028223`} className="ms-2 cursor-pointer">18002028223 </a>
            </div>
            <div className="flex items-center">
              <div className="material-symbols-outlined">
                <img src={MAIL_IC} alt="" className="w-6" />
              </div>
              <span className="ms-2">
                <a href="mailto:info@netre.in" className="email-link">
                  info@netre.in
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="w-full bg-gray p-4 small:flex emd:flex lg:hidden items-center justify-start overflow-x-auto overflow-y-hidden text-nowrap whitespace-pre  hide_scrollbar">
          <Link
            to="profile-details#your-account"
            className={` ${
              hash === "#your-account" ? "text-primary" : ""
            }  select-none cursor-pointer`}
          >
            Your Account
          </Link>
          <Link
            to="/profile-details#order-history"
            className={` ${
              hash === "#order-history" ? "text-primary" : ""
            }  ms-6 select-none cursor-pointer`}
          >
            Order History
          </Link>
          <Link
            to="/profile-details#coupons"
            className={` ${
              hash === "#coupons" ? "text-primary" : ""
            }  ms-6 select-none cursor-pointer`}
          >
            Coupons
          </Link>
          <span
            className={` ${
              hash === "#" ? "text-primary" : ""
            }  ms-6 select-none cursor-pointer`}
            onClick={handleLogout}
          >
            Logout
          </span>
        </div>
        <div className="lg:w-[80%] emd:w-full small:w-full p-6">
          <div
            className="w-full flex pb-4 border-b border-light_secondary"
            ref={sectionStartRef}
          >
            <div
              className="material-symbols-outlined cursor-pointer"
              onClick={() => navigate("/profile-details#order-history")}
            >
              <img src={ARROW_BACK_BLACK_IC} alt="" className="w-6" />
            </div>
            <span className="text-2xl font-bold ms-4">Exchange Order</span>
          </div>
          <div className="w-full my-5 flex justify-between flex-wrap">
            <div className="lg:w-1/2 emd:w-full small:w-full flex flex-col lg:pr-2 lg:border-r border-secondary">
              <span className="text-lg font-semibold">Select Reason</span>
              <div className="grid grid-rows-2 grid-flow-col gap-4 mt-5 w-full pb-5 border-b">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="damaged"
                    className="w-4 h-4 accent-black "
                    value={"damaged"}
                    checked={damagedProductVal === "damaged"}
                    onChange={(e) =>
                      setDamagedProductVal(
                        damagedProductVal === "" ? e.target.value : ""
                      )
                    }
                  />
                  <label htmlFor="damaged" className="ms-2">
                    Damaged Product
                  </label>
                </div>
                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id="wrong_size"
                    className="w-4 h-4 accent-black "
                    value={"wrong_size"}
                    checked={wrongSizeVal === "wrong_size"}
                    onChange={(e) =>
                      setWrongSizeVal(wrongSizeVal === "" ? e.target.value : "")
                    }
                  />
                  <label htmlFor="wrong_size" className="ms-2">
                    Wrong Size
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="wrong_product"
                    className="w-4 h-4 accent-black "
                    value={"wrong_product"}
                    checked={wrongProductVal === "wrong_product"}
                    onChange={(e) =>
                      setWrongProductVal(
                        wrongProductVal === "" ? e.target.value : ""
                      )
                    }
                  />
                  <label htmlFor="wrong_product" className="ms-2">
                    Wrong Product
                  </label>
                </div>
                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id="size"
                    className="min-w-4 max-w-4 min-h-4 max-h-4 accent-black mb-4"
                    value={"other"}
                    checked={otherToggle === "other"}
                    onChange={(e) => {
                      setOtherToggle(otherToggle === "" ? e.target.value : "");
                      otherToggle !== "" &&
                        exchangeOrderFormik?.setErrors({ other_reason: "" });
                    }}
                  />
                  <INPUT
                    placeHolder="Other"
                    mainClass="w-[110px] ms-2 py-0 mb-0"
                    className="py-0 px-1 w-full"
                    name="other_reason"
                    isDisabled={otherToggle !== "other"}
                    onChange={exchangeOrderFormik.handleChange}
                    val={exchangeOrderFormik.values.other_reason}
                    onBlur={exchangeOrderFormik.handleBlur}
                    errorMsg={exchangeOrderFormik.errors.other_reason}
                    touched={exchangeOrderFormik.touched.other_reason}
                  />
                </div>
              </div>
              <div className="w-full mt-5 flex flex-col">
                <span className="text-xl">Exchange with</span>
                <div className="w-full mt-5 flex justify-between flex-wrap">
                  <div className="lg:w-[40%] emd:w-full justify-between small:w-full flex lg:flex-col emd:flex-row small:flex-row mt-1 mb-1">
                    <div className="lg:w-full emd:w-[48%] small:w-[48%] h-52 flex items-center justify-center bg-gray">
                      <img
                        src={oldFrame?.images?.[0]}
                        alt=""
                        className="object-contain"
                      />
                    </div>
                    <div className="lg:w-full emd:w-[48%] small:w-[48%] lg:mt-2 flex flex-col">
                      <span>{oldFrame?.name}</span>
                      <span>SKU: {oldFrame?.sku}</span>
                    </div>
                  </div>

                  <div className=" lg:flex emd:hidden small:hidden h-52 flex-col items-center justify-center">
                    <div className="material-symbols-outlined">
                      <img
                        src={ARROW_FORWARD_BLACK_IC}
                        alt=""
                        className="w-6"
                      />
                    </div>
                  </div>

                  {newFrame ? (
                    <div className="lg:w-[40%] emd:w-full justify-between small:w-full flex lg:flex-col emd:flex-row small:flex-row mt-1 mb-1">
                      <div className="lg:w-full emd:w-[48%] small:w-[48%] h-52 flex items-center justify-center bg-gray">
                        <img
                          src={newFrame?.image?.[0]}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                      <div className="lg:w-full emd:w-[48%] small:w-[48%] lg:mt-2 flex flex-col">
                        <span>{newFrame?.name}</span>
                        <span>SKU: {newFrame?.sku}</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="lg:w-[40%] emd:w-full justify-between small:w-full lg:mt-0 emd:mt-5 small:mt-5 flex flex-col"
                      onClick={() => {
                        virtualProductsRef.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <div className="w-full h-52 cursor-pointer flex flex-col items-center justify-center bg-gray">
                        <div className="material-symbols-outlined">
                          <img src={ADD_IC} alt="" />
                        </div>
                        <span className="text-base mt-2">Add Frame</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 emd:w-full small:w-full flex flex-col lg:pl-2 lg:mt-0 emd:mt-5 small:mt-5">
              <span className="text-lg font-semibold">Price Breakup</span>
              <div className="w-full flex flex-col mt-5 pb-5 border-b">
                <div className="w-full flex justify-between items-center mt-2">
                  <span>Old frame price</span>
                  <span>₹{oldFrame?.price}</span>
                </div>
                {newFrame?.prices?.price && (
                  <div className="w-full flex justify-between items-center mt-2">
                    <span>New frame price</span>
                    <span>₹{newFrame?.prices?.price}</span>
                  </div>
                )}
                {newFrame?.prices?.price && (
                  <div className="w-full flex justify-between items-center mt-2">
                    <span>Price difference</span>
                    <span>
                      ₹
                      {oldFrame?.price &&
                        newFrame?.prices?.price &&
                        newFrame.prices.price - oldFrame.price}
                    </span>
                  </div>
                )}
              </div>
              {newFrame?.prices?.price && (
                <div className="mt-1 w-full flex justify-between items-center">
                  <span className="text-lg">Total amount to be paid</span>
                  <span className="text-lg">
                    ₹
                    {oldFrame?.price &&
                      newFrame?.prices?.price &&
                      newFrame.prices.price - oldFrame.price}
                  </span>
                </div>
              )}

              <div className="w-full border-b flex flex-col mt-7 pb-2">
                <div
                  className="w-full flex items-center justify-between cursor-pointer select-none"
                  onClick={() =>
                    setIsSelected(
                      isSelected !== "shopping_details"
                        ? "shopping_details"
                        : "none"
                    )
                  }
                >
                  <span className="text-lg">Shipping Details</span>
                  <img
                    src={DOWN_ARROW_IC}
                    className={`${
                      isSelected === "shopping_details" ? "ac_tr_arrow_ic" : ""
                    } ac_arrow_ic`}
                    alt=""
                  />
                </div>
                {isSelected === "shopping_details" &&
                  customerProfileData?.customer?.addresses.map(
                    (item, index) => {
                      return (
                        <div
                          className="w-full mt-5 flex flex-col items-start"
                          key={index}
                        >
                          <div className="w-full flex items-start">
                            <input
                              type="radio"
                              id={`${index + "_select_address"}`}
                              className="mt-1 w-5 h-5 accent-black cursor-pointer"
                              name="shopping_details"
                              value={item}
                              checked={selectedAddress === item}
                              onChange={(e) => setSelectedAddress(item)}
                            />
                            <div className="w-full flex flex-col ms-2 justify-between ">
                              <div className="w-full flex justify-between items-center">
                                <label
                                  htmlFor={`${index + "_select_address"}`}
                                  className="text-lg font-bold cursor-pointer"
                                >
                                  {item?.firstname +
                                    " " +
                                    item?.lastname +
                                    " ( " +
                                    item?.label +
                                    " ) "}
                                </label>
                              </div>
                              <span className="text-sm mt-2">
                                {item?.address1 +
                                  ", " +
                                  item?.address2 +
                                  ", " +
                                  item?.city +
                                  ", " +
                                  item?.zipCode}
                              </span>
                              <div className="flex text-sm items-center mt-2">
                                <div className="material-symbols-outlined">
                                  <img src={CALL_IC} alt="" className="w-5" />
                                </div>
                                <span className="ms-1 text-sm">
                                  {item?.phone}
                                </span>
                              </div>
                              {/* <div className="text-primary underline mt-2">Select</div> */}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>

              {/* <div className="w-full border-b flex flex-col mt-7 pb-2">
                <div
                  className="w-full flex items-center justify-between cursor-pointer select-none"
                  onClick={() =>
                    setIsSelected(
                      isSelected !== "payment_details"
                        ? "payment_details"
                        : "none"
                    )
                  }
                >
                  <span className="text-lg">Payment Details</span>
                  <img
                    src={DOWN_ARROW_IC}
                    className={`${
                      isSelected === "payment_details" ? "ac_tr_arrow_ic" : ""
                    } ac_arrow_ic`}
                    alt=""
                    />
                  </div>
                  {isSelected === "payment_details" && (
                    <div className="w-full mt-5 flex flex-col items-start">
                      <div className="w-full flex items-start">
                        <input
                          type="radio"
                          className="mt-1 w-5 h-5 accent-black cursor-pointer"
                        />
                        <div className="w-full flex flex-col ms-2 justify-between ">
                          <div className="w-full flex justify-between items-center">
                            <span className="text-lg font-bold">
                              {customerOrderDetailsData?.billing_info?.address?.firstname +" "+ customerOrderDetailsData?.billing_info?.address?.lastname + " ( " + customerOrderDetailsData?.billing_info?.address?.label + " ) " }
                            </span>
                          </div>
                          <span className="text-sm mt-2">
                            {customerOrderDetailsData?.billing_info?.address?.address1 +", "+ customerOrderDetailsData?.billing_info?.address?.address2
                            +", "+customerOrderDetailsData?.billing_info?.address?.city +", "+customerOrderDetailsData?.billing_info?.address?.zipCode
                            }
                          </span>
                          <div className="flex text-sm items-center mt-2">
                            <div className="material-symbols-outlined text-xl">
                              call
                            </div>
                            <span className="ms-1 text-sm">{customerOrderDetailsData?.billing_info?.address?.phone}</span>
                          </div>
                          <div className="text-primary underline mt-2">Select</div>
                        </div>
                      </div>
                    </div>
                  )}
              </div> */}
              <div className="w-full flex items-center justify-center mt-7">
                <BTN
                  bordered
                  title="CANCEL"
                  className="lg:w-48 emd:w-48 small:w-[48%] py-1 px-4 text-sm rounded-none"
                  onP={() => navigate("/profile-details#order-history")}
                />
                <BTN
                  title="EXCHANGE"
                  className="ms-3 lg:w-48 emd:w-48 small:w-[48%]  py-1 px-4 text-sm rounded-none"
                  type={"submit"}
                  onP={exchangeOrderFormik.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={virtualProductsRef}>
        <VirtualProducts newFrame={newFrame} setNewFrame={setNewFrame} />
      </div>
    </div>
  );
};

export default ExchangeOrder;

function VirtualProducts(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem("user");

  const params = useParams();
  const [newData, setNewData] = useState();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [paramVal, setParamVal] = useState("");
  const {
    data: productsData,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetProductsQuery(
    {
      page: page,
      queryParams: params?.name
        ? `category=${props.category}`
        : `${
            params?.searchProductParams
              ? `title=${params?.searchProductParams}`
              : ""
          }`,
    },
    { skip: !user }
  );

  useEffect(() => {
    setParamVal((prev) => prev);
    if (paramVal !== params.searchProductParams) {
      setProducts([]);
      setPage(1);
    }
  }, [params?.searchProductParams]);

  useEffect(() => {
    if (isError) {
      console.log(error);
      toast.error(error?.data?.message);
    }
  }, [isError]);

  const totalProducts = productsData?.totalDoc || 0;

  useEffect(() => {
    if (productsData) {
      setNewData(productsData);
    }
  }, [productsData]);

  useEffect(() => {
    if (newData) {
      setProducts([...products, ...newData.products]);
    }
  }, [newData]);

  useEffect(() => {
    const footer = document.querySelector(".Footer_Container");
    const handleScroll = () => {
      // exclude footer height
      if (!isSuccess) return;
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - footer.clientHeight &&
        !isFetching
      ) {
        if (products.length < totalProducts) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, isSuccess]);

  return (
    <div>
      {!products?.length > 0 ? (
        <div className="no_product_found">No Product Found</div>
      ) : (
        <div className="cards">
          {/* {isLoading && <div>Loading..</div>} */}
          {isSuccess &&
            products &&
            products.map((item, index) => (
              <ProductCard
                key={index}
                product={item}
                route={"/virtual-tryon"}
                storeType={"Virtual"}
                {...props}
              />
            ))}
        </div>
      )}
    </div>
  );
}
