import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useGetCustomerFilterMutationMutation, useGetFiltersQuery, useSetAsMyFilterMutation } from '../../../redux/slices/homeSlice';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Accordion from '../ShoppingBag/Accordion';
import { toast } from 'react-toastify';
import { CLOSE_IC } from '../../../assets';

export const FilterSidePanel = ({ showFilter, showFilterHandler, category, setSortBy , totalDocs, setAttributeFilters}) => {
  const user = localStorage.getItem("user");
  const params = useParams();
  const sortby = [
    { name: 'Newest Arrivals', value: 'date-added-desc' },
    { name: 'Price- High to Low', value: 'high' },
    { name: 'Price- Low to High', value: 'low' },
  ];
  const [selectedSortby, setSelectedSortby] = useState({});
  useEffect(() => {
    setSelectedSortby(sortby[0]);
  }, []);
  useEffect(() => {
    setSortBy(selectedSortby);
  }, [selectedSortby]);
  const { data: filtersData, isLoading, isFetching, isSuccess, isError, error } = useGetFiltersQuery({
    queryParams: category
    ? `category=${category}`
    : `${
        params?.searchProductParams
          ? `title=${params?.searchProductParams}`
          : ""
      }`,
  });


  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
    },
    onSubmit: values => {
      // console.log(values);
    },
    validate: (values) => {
      let errors = {};
      return errors;
    },
  });

  const [setAsMyFilter] = useSetAsMyFilterMutation();
  const [getCustomerFilterMutation] = useGetCustomerFilterMutationMutation();
  const setAsMyFilterClickHandler = () => {
    // toast if not logged in
    if(!user){
      toast.error('Please login to set as my filter');
      return;
    }
    // toast if no filters selected
    if(Object.keys(filterFormik.values).length === 0){
      toast.error('No filters selected');
      return;
    }
    let attributeFilters = {};
    Object.keys(filterFormik.values).forEach((key) => {
      if (filterFormik.values[key].length > 0) {
        attributeFilters[key] = filterFormik.values[key].join(',');
      }
    });
    const setAsMyFilterBody = {
      attributes : attributeFilters
    };
    setAsMyFilter({body:setAsMyFilterBody, user:user}).then((res) => {
      if(res?.data){
        toast.success(res?.data?.message);
      }
      if(res?.error){
        toast.error(res?.error?.data?.message);
      }
    }
    );
  }
  const applyMyFilterClickHandler = () => {
    // toast if not logged in
    if(!user){
      toast.error('Please login to apply my filter');
      return;
    }
    // get customer filters
    getCustomerFilterMutation({user:user}).then((res) => {
      if(res?.data?.productFilter){
        //converting the response to formik values
        const input = res.data.productFilter?.attributes;
        const output = {};
        for (let key in input) {
          // If the value contains commas, split it into an array
          if (typeof input[key] === 'string' && input[key].includes(',')) {
            output[key] = input[key].split(',');
          } else {
            // If no commas, wrap the value in an array
            output[key] = [input[key]];
          }
        }
        filterFormik.setValues(output);
        toast.success('My filter applied');
      }
      if(res?.error){
        toast.error(res?.error?.data?.message);
      }
    });

  }
  const applyMyInitialHandler = () => {
    if(Object.keys(savedInitialFilters).length > 0 && isSuccess && !isFetching && !isLoading){
      console.log(savedInitialFilters, Object.keys(savedInitialFilters).length)
      const input = savedInitialFilters;
      const output = {};
      for (let key in input) {
        // If the value contains commas, split it into an array
        if (typeof input[key] === 'string' && input[key].includes(',')) {
          output[key] = input[key].split(',');
        } else {
          // If no commas, wrap the value in an array
          output[key] = [input[key]];
        }
      }
      filterFormik.setValues(output);
      setTimeout(filterFormik.handleSubmit(), 0);
      console.log(isSuccess, isFetching, isLoading)
      setSavedInitialFilters({});
    }
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilters = Object.fromEntries(searchParams.entries());
  const [savedInitialFilters, setSavedInitialFilters] = useState({});
  useEffect(() => {
    setSavedInitialFilters(initialFilters)
  }, []); 
  useLayoutEffect(()=>{
    const timer = setTimeout(() => {
      applyMyInitialHandler();
    }, 1000); // Adjust delay if necessary

    return () => clearTimeout(timer);
  },[savedInitialFilters, filtersData, isSuccess, isFetching, isLoading])
  const location = useLocation();

  // json data for filters using filterFormik.values making key value pairs
  useEffect(() => {
    let filters = {};
    Object.keys(filterFormik.values).forEach((key) => {
      if (filterFormik.values[key].length > 0) {
        filters[key] = filterFormik.values[key].join(',');
      }
    });
    // console.log(filters);
    setAttributeFilters(filters);

    const newSearchParams = {};

    // Convert filters to search params format
    for (const key in filters) {
      if (filters[key]) {
        newSearchParams[key] = filters[key];
      }
    }
    setSearchParams(newSearchParams, { replace: true });

  }, [filterFormik.values, searchParams]);  

useEffect(() => {
  filterFormik.resetForm({ values: filterFormik.initialValues });
  setSearchParams({replace:true});
  setTimeout(filterFormik.handleSubmit(), 0);
}, [params?.searchProductParams, category]);

  return (
    <div className={`filter-side-panel ${showFilter ? 'show' : ''}`}>
      <div className="overlay" onClick={showFilterHandler}></div>
      <div className="panel">
        <div className="filter-body">
          <div className="sortby">
            <div className="title w-full flex items-center justify-between">Sort By
            <img src={CLOSE_IC} alt="" className='cursor-pointer' onClick={()=>showFilterHandler()} />
            </div>
            {sortby.map((item, index) => (
              <div key={index}
                className={item.name === selectedSortby.name ? "sortby-item sortby-item-active" : "sortby-item"}
                onClick={() => setSelectedSortby(item)}
              >{item.name}</div>
            ))}
          </div>
          <div className="filterby">
            <div className="d-flex j-sb">
              <div className="title">Filter By</div>
              <div className="clear-all" 
              onClick={()=>{
                filterFormik.resetForm({ values: filterFormik.initialValues });
                setSearchParams({})
                setTimeout(filterFormik.handleSubmit(), 0);
              }}>Clear all</div>
            </div>
            <div className="set-my-filter my-2" onClick={setAsMyFilterClickHandler}>Set as my filter</div>
            <div className="set-my-filter apply-my-filter  my-2 ms-2" onClick={applyMyFilterClickHandler}>Apply my filter</div>
            <div className="attribute-filters">
              {filtersData?.attribute_filters?.map((item, index) => (
                  <Accordion
                    key={index}
                    title={<div className="title">{item.attributeName}</div>}
                    content={
                      <>
                      <div className="filter-items">
                        {item?.attributeValues.map((option, index) => {
                          return (
                            <div className='check-container d-flex' key={`${index}-${option}`}>
                              <input type="checkbox" 
                              id={`${item.attributeName}-${option}`}
                              name={item.attributeName}
                              onChange={(e) => {
                                filterFormik.handleChange(e);
                                setTimeout(filterFormik.handleSubmit(), 0);
                              }}
                              value={option}
                              onBlur={filterFormik.handleBlur}
                              checked={filterFormik.values[item.attributeName]?.includes(option) || false}
                              className='accent-black'
                              />
                              <label 
                              className='ps-3 flex-grow cursor-pointer align-middle'
                              htmlFor={`${item.attributeName}-${option}`}>{option}</label><br></br>
                            </div>
                          );
                        })}
                      </div>
                      </>
                    }
                  />
              ))}
            </div>
          </div>
        </div>
        <div className="results-btn"
          onClick={() => {
            filterFormik.handleSubmit();
            showFilterHandler();
          }}
        >{totalDocs} RESULTS FOR YOUR SEARCH</div>
      </div>
    </div>
  );
};
