import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  items: [],
  orderDetails: {
    isGift: false,
    giftMessage: "",
    couponCode: null,
  },
};

const myBagSlice = createSlice({
  name: "myBagData",
  initialState,
  reducers: {
    setMyBag: (state, action) => {
      state.items = action.payload;
    },
    addToMyBag: (state, action) => {
      const product = action.payload;
      const existingProduct = state.items.find(
        (item) => item._id === product._id
      );
      
      if (existingProduct) {
        existingProduct.quantity += product.quantity || 1;
        existingProduct.readingGlassesPower.push(product.readingGlassesPower);
        state.orderDetails = { ...state.orderDetails };
      } else {
        state.items.push({
          ...product,
          quantity: product.quantity || 1,
          readingGlassesPower: [product.readingGlassesPower],
        });
        state.orderDetails = { ...state.orderDetails };
      }
    },
    // removeFromMyBag: (state, action) => {
    //   const productId = action.payload?.id;
    //   const decrement = action.payload.isAllDelete;
    //   const existingProduct = state.items.find(
    //     (item) => item._id === productId
    //   );

    //   if (existingProduct) {
    //     existingProduct.quantity -= 1;
    //     if (existingProduct.quantity <= 0) {
    //       state.items = state.items.filter((item) => item._id !== productId);
    //       state.orderDetails = { ...state.orderDetails };
    //     }
    //     if (decrement) {
    //       state.items = state.items.filter((item) => item._id !== productId);
    //       state.orderDetails = { ...state.orderDetails };
    //     }
    //   }
    // },

    removeFromMyBag: (state, action) => {
      const productId = action.payload?.id;
      const decrement = action.payload.isAllDelete;
      const existingProduct = state.items.find(
        (item) => item._id === productId
      );

      if (existingProduct) {
        if (existingProduct.quantity > 0) {
          existingProduct.quantity -= 1;
        }

        // Remove last added readingPower if quantity is decremented
        if (
          existingProduct.quantity > 0 &&
          existingProduct.readingGlassesPower.length > 0
        ) {
          existingProduct.readingGlassesPower.pop();
        }

        // Remove product entirely if quantity is 0 or `isAllDelete` is true
        if (existingProduct.quantity <= 0 || decrement) {
          state.items = state.items.filter((item) => item._id !== productId);
        }

        state.orderDetails = { ...state.orderDetails };
      }
    },
    
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    addOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    removeOrderDetails: (state, action) => {
      state.items = action.payload.items;
      state.orderDetails = action.payload.orderDetails;
    },
  },
});

export const {
  setMyBag,
  addToMyBag,
  removeFromMyBag,
  setOrderDetails,
  addOrderDetails,
  removeOrderDetails,
} = myBagSlice.actions;
export default myBagSlice.reducer;
