import React from "react";
import { Link, useNavigate } from "react-router-dom";

const StoreLocator = () => {
  const navigate = useNavigate();
  const redirect =
    "https://www.google.com/search?gs_ssp=eJzj4tVP1zc0rDQrLDbKySs2YLRSNagwtjRNtTA2tUixSDU0NbVIszKoMDRPTjQ3TjUzNzRJMbVMNPUSzUstKUpVSK1MLU9NLFJIT8zJSS2qBABS8hdX&q=netre+eyewear+gallery&rlz=1C1UEAD_enIN1080IN1080&oq=netre&gs_lcrp=EgZjaHJvbWUqDQgEEC4YrwEYxwEYgAQyBggAEEUYOTIGCAEQRRg8MgYIAhBFGDwyBggDEEUYPDINCAQQLhivARjHARiABDIGCAUQRRg8MgYIBhBFGEEyBggHEEUYPNIBCDMwMjdqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8";
  return (
    <div className="w-full flex items-start justify-between lg:flex-row emd:flex-row small:flex-col py-20 lg:px-10 emd:px-8 small:px-2">
      <div className="lg:w-1/2 emd:w-1/2 small:w-full flex flex-col">
        <h1 className="mb-5 mont-bold text-lg">Store Locator</h1>
        <span
          onClick={() =>
            window.open(redirect, "_blank", "rel=noopener noreferrer")
          }
          className="cursor-pointer"
        >
          Store no.4, Vandematram Fabula, Nirma University Rd, <br /> Ahmedabad,
          Gujarat 382481
        </span>
      </div>
      <div className="lg:w-1/2 emd:w-1/2 small:w-full lg:mt-0 emd:mt-0 small:mt-8">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14933.884578972018!2d72.54721564985371!3d23.121522121319586!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8358d8e1558f%3A0x17ca73e6714d59a5!2sNetre!5e0!3m2!1sen!2sin!4v1733914001961!5m2!1sen!2sin"
          className="w-full h-80"
          style={{ border: "0px" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default StoreLocator;
