import "./App.scss";

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Home from "./b2b/home/Home";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { lazy } from "react";
import Header from "./b2b/header/Header";
import Footer, { isValidEmail } from "./b2b/Footer/Footer";

import Favorites from "./b2b/Pages/Favorites/Favorites";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginSignup from "./auth/LoginSignup";
import {
  useCheckPaymentStatusMutation,
  useGetCouponListQuery,
  useGetCustomerBagDataQuery,
  useGetCustomerHomeTryOnDataQuery,
  useGetCustomerWishlistQuery,
  useGetHeaderCategoriesDataQuery,
  useGetHeaderLabelsDataQuery,
  useSubscribeMutation,
} from "./redux/slices/homeSlice";
import { setWishlist } from "./redux/slices/wishlistSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import ProductPage from "./b2b/Pages/Favorites/ProductPage/ProductPage";
import Category from "./b2b/Pages/Category/Category";
import {
  addOrderDetails,
  addToMyBag,
  removeOrderDetails,
  setMyBag,
  setOrderDetails,
} from "./redux/slices/myBagSlice";
import MyBag from "./b2b/Pages/MyBag/MyBag";
import ShoppingBag from "./b2b/Pages/ShoppingBag/ShoppingBag.jsx";
import ShoppingTray from "./b2b/Pages/ShoppingTray/ShoppingTray.jsx";
import OrderPlaced from "./b2b/Pages/OrderPlaced/OrderPlaced.jsx";
import ProfileDetails from "./b2b/Pages/ProfileDetails/ProfileDetails.jsx";
import BTN from "./b2b/Master/BTN.jsx";
import INPUT from "./b2b/Master/INPUT.jsx";
import Sidebar from "./b2b/header/Sidebar.jsx";
import Lens from "./b2b/Pages/Lens/Lens.jsx";
import TrackOrder from "./b2b/Pages/ProfileDetails/TrackOrder.jsx";
import ExchangeOrder from "./b2b/Pages/ProfileDetails/ExchangeOrder.jsx";
import CancelOrder from "./b2b/Pages/ProfileDetails/CancelOrder.jsx";
import ReturnOrder from "./b2b/Pages/ProfileDetails/ReturnOrder.jsx";
import ContactUs from "./b2b/Pages/ContactUs/ContactUs.jsx";
import BecomeRetailer from "./b2b/Pages/ContactUs/BecomeRetailer.jsx";
import Retailer from "./b2b/Pages/Retailer/Retailer.jsx";
import "react-loading-skeleton/dist/skeleton.css";
import RetailerHeader from "./b2b/header/RetailerHeader.jsx";
import AboutUs from "./b2b/Pages/BrandInfo/AboutUs.jsx";
import PrivacyPolicy from "./b2b/Pages/BrandInfo/PrivacyPolicy.jsx";
import TermsCondition from "./b2b/Pages/BrandInfo/TermsCondition.jsx";
import ExchangePolicy from "./b2b/Pages/BrandInfo/ExchangePolicy.jsx";
import ReturnPolicy from "./b2b/Pages/BrandInfo/ReturnPolicy.jsx";
import OrderFailed from "./b2b/Pages/OrderPlaced/OrderFailed.jsx";
import {
  CLOSE_BLACK_IC,
  LOGIN_MODEL_BANNER,
  PAGE_NOT_FOUND,
  TIMER_JPEG,
  TIMER_PAGE_GIRL,
  TIMER_PAGE_GIRL_,
  TIMER_PAGE_SKYE,
} from "./assets/index.js";
import Skeleton from "react-loading-skeleton";
import { useFormik } from "formik";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { Breakpoint } from "react-socks";
import { setHomeTryOn } from "./redux/slices/homeTryonSlice.js";
import HTO from "./b2b/Pages/HTO/HTO.jsx";
import Accessories from "./b2b/Pages/Accessories/Accessories.jsx";
import LensOnly from "./b2b/Pages/Lens/LensOnly.jsx";
import BestSellers from "./b2b/Pages/BestSellers/BestSellers.jsx";
import VisionWellness from "./b2b/Pages/VisionWellness/VisionWellness.jsx";
import StoreLocator from "./b2b/Pages/StoreLocator/StoreLocator.jsx";
import useWindowSize from "./hooks/useWindowSize.js";
import ReactConfetti from "react-confetti";
import { Helmet } from "react-helmet";
function App() {
  const location = useLocation();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  // lens screen steps
  const [lensStep, setLensStep] = useState(1);
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loginModelToggle, setLoginModelToggle] = useState(false);
  const myBagitems = useSelector((state) => state.myBagData.items);
  const orderDetails = useSelector((state) => state?.myBagData?.orderDetails);

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);
  const {
    data: headerLabelsData,
    isSuccess: headerLabelsSuccess,
    isLoading: headerLabelsLoading,
    isError: headerLabelsIsError,
    error: headerLabelsError,
  } = useGetHeaderLabelsDataQuery();

  const {
    data: headerCategoriesData,
    isSuccess: headerCategoriesSuccess,
    isLoading: headerCategoriesLoading,
    isError: headerCategoriesIsError,
    error: headerCategoriesError,
  } = useGetHeaderCategoriesDataQuery();

  const {
    data: wishlistData,
    isSuccess: wishlistSuccess,
    isLoading: wishlistLoading,
    isError: wishlistIsError,
    error: wishlistError,
  } = useGetCustomerWishlistQuery({ user: user }, { skip: !user });

  useEffect(() => {
    if (wishlistSuccess) {
      const data = wishlistData.wishlist;
      dispatch(setWishlist(data));
    } else if (wishlistIsError) {
      dispatch(setWishlist([]));
    }
  }, [wishlistData, wishlistSuccess, wishlistIsError]);

  const {
    data: customerBagData,
    isSuccess: customerBagIsSuccess,
    isLoading: customerBagIsLoading,
    isError: customerBagIsError,
    error: customerBagError,
  } = useGetCustomerBagDataQuery({ user: user }, { skip: !user });

  const {
    data: customerHomeTryOnData,
    isSuccess: customerHomeTryOnDataIsSuccess,
    isLoading: customerHomeTryOnDataIsLoading,
    isError: customerHomeTryOnDataIsError,
    error: customerHomeTryOnDataError,
  } = useGetCustomerHomeTryOnDataQuery({ user: user }, { skip: !user });

  // const {
  //   data: couponData,
  //   isSuccess: couponDataIsSuccess,
  //   isLoading: couponDataIsLoading,
  //   isError: couponDataIsError,
  //   error: couponDataError,
  // } = useGetCouponListQuery({ user: user, couponType: "" }, { skip: !user });

  const findAutoAppliedCouponData = (
    couponArray,
    couponCode,
    minimumAmount
  ) => {
    const data = couponArray?.find((item) => {
      return (
        minimumAmount > item?.minimumAmount &&
        item?.couponCode === couponCode &&
        item
      );
    });
    return data;
  };

  const calculateCartTotalWithoutOffer = () => {
    return customerBagData?.cart?.reduce((total, item) => {
      return total + item?.prices?.originalPrice * item?.quantity;
    }, 0);
  };

  useEffect(() => {
    if (customerBagIsSuccess) {
      const data = customerBagData.cart;
      dispatch(setMyBag(data));
      // dispatch(
      //   setOrderDetails({
      //     couponCode: findAutoAppliedCouponData(
      //       couponData,
      //       customerBagData?.appliedCoupon,
      //       calculateCartTotalWithoutOffer()
      //     ),
      //   })
      // );
    } else if (customerBagError && user !== null) {
      dispatch(setMyBag([]));
    }
  }, [customerBagData, customerBagIsSuccess, customerBagIsError]);

  useEffect(() => {
    if (customerHomeTryOnDataIsSuccess) {
      const data = customerHomeTryOnData.inquiryTray;
      dispatch(setHomeTryOn(data));
    } else if (customerHomeTryOnDataIsError && user !== null) {
      dispatch(setMyBag([]));
    }
  }, [
    customerHomeTryOnData,
    customerHomeTryOnDataIsSuccess,
    customerHomeTryOnDataIsError,
  ]);

  const [isRetailerPage, setIsRetailerPage] = useState(false);

  useEffect(() => {
    setIsRetailerPage(false);
  }, [location.pathname]);

  const eyeFramesRef = useRef(null);
  const newArrivalsRef = useRef(null);
  const ourStoreRef = useRef(null);
  const contactUsRef = useRef(null);

  // ===================== CLEAR WISHLIST AND CART ON LOGOUT ===================

  // useEffect(() => {
  //   if (!user) {
  //     dispatch(setMyBag([]));
  //     dispatch(setWishlist([]));
  //     dispatch(setHomeTryOn([]));
  //   }
  // }, [user]);

  // user subscribe email

  const [formLoader, setFormLoader] = useState(false);
  const [subscribe] = useSubscribeMutation();
  const subscribeFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async function (values) {
      if (!values?.email) {
        toast.error("Email id is Required!");
        return;
      } else if (!isValidEmail(values?.email)) {
        toast.error("Email is not valid!");
        return;
      }

      setFormLoader(true);
      subscribe({
        body: {
          email: values?.email,
        },
      }).then((res) => {
        if (res?.data) {
          subscribeFormik?.resetForm();
          toast.success(res?.data.message);
          // setSuccessMsg(res?.data.message);
        } else {
          // console.log(res?.error?.data?.message);
          toast.error(`${res?.error?.data?.message}`);
          // setErrorMsg(res?.error?.data?.message);
        }
        setTimeout(() => {
          setSuccessMsg("");
          setErrorMsg("");
        }, 5000);
        setFormLoader(false);
      });
    },
    validate: (values) => {
      // let errors = {};
      // if (!values?.email) {
      //   toast.error("Email id is Required!");
      // } else if (!isValidEmail(values?.email)) {
      //   toast.error("Email is not valid!");
      // }
      // return errors;
    },
  });

  // =================== CHECK COUPON IS VALID OR NOT WHEN CART CHANGE ====================
  const subTotal = () => {
    return myBagitems?.reduce((total, item) => {
      // Calculate the product price based on quantity
      const productPrice = item?.prices?.price * item?.quantity;

      // Calculate the total lens price for this product (sum of all lenses)
      const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
        // Add prices of all addons for this lens
        const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
          return addonsSum + addon.price;
        }, 0);
        return addonsPrice + lensTotal + lens?.price || 0;
      }, 0);

      // Add the total lens price to the product price
      const itemTotal = productPrice + (totalLensPrice || 0);

      return total + itemTotal;
    }, 0);
  };

  const checkCouponCodeIsValid = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();

    if (currentTime > endTime) {
      return false;
    }
    if (subTotal() < coupon?.minimumAmount) {
      return false;
    }
    if (coupon?.couponQuantity === 0) {
      return false;
    }
    return true;
  };

  const checkEndDateOfCoupon = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();
    if (currentTime > endTime || coupon?.couponQuantity === 0) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (orderDetails?.couponCode) {
      if (
        checkCouponCodeIsValid(orderDetails?.couponCode) &&
        checkEndDateOfCoupon(orderDetails?.couponCode)
      ) {
        dispatch(
          addOrderDetails({
            ...orderDetails,
            couponCode: orderDetails?.couponCode,
          })
        );
      } else {
        dispatch(
          removeOrderDetails({
            items: myBagitems,
            orderDetails: {
              isGift: orderDetails?.isGift,
              giftMessage: orderDetails?.giftMessage,
              couponCode: null,
            },
          })
        );
      }
    }
  }, [myBagitems]);

  // ========================== count down timer ==========================
  const navigate = useNavigate();
  const finalDate = new Date(process.env.REACT_APP_LAUNCH_DATE_TIME).getTime();
  const [timeLeft, setTimeLeft] = useState(finalDate - new Date().getTime());
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(finalDate - new Date().getTime());
      setDays(Math.floor(timeLeft / (1000 * 60 * 60 * 24)));
      setHours(
        Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((timeLeft % (1000 * 60)) / 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft]);

  const modalClosed = sessionStorage.getItem("loginModelClosed");

  const modalRef = useRef(null);

  useEffect(() => {
    const showModal = () => {
      if (!user) {
        if (
          !location.pathname.includes("login") &&
          !location.pathname.includes("signup")
        ) {
          const modalClosed = sessionStorage.getItem("loginModelClosed");
          if (!modalClosed) {
            setTimeout(() => {
              setLoginModelToggle(true);
            }, 60000); // Show modal after 1
          }
        }
      }
    };

    showModal();
  }, [user, location.pathname]);

  const { width, height } = useWindowSize();
  const [showConfetti1, setShowConfetti1] = useState(false);
  const [showConfetti2, setShowConfetti2] = useState(false);
  // set setShowConfetti2 true and after 30 seconds set it to false
  useEffect(() => {
    if (showConfetti2) {
      setTimeout(() => {
        setShowConfetti2(false);
      }, 30000);
    }
  }, [showConfetti2]);
  useEffect(() => {
    if (showConfetti1) {
      setTimeout(() => {
        setShowConfetti1(false);
        setShowConfetti2(true);
      }, 10000);
    }
  }, [showConfetti1]);
  useEffect(() => {
    if (timeLeft > 0) {
      navigate("/");
    }
  }, [timeLeft]);
  if (timeLeft > 0) {
    return (
      <div
        className="coming_soon_bg relative w-[100vw] h-[100svh] max-h-[100svh] overflow-hidden"
        // style={{
        //   background: `url(${TIMER_PAGE_SKYE})`,
        //   objectFit: "cover",
        //   backgroundSize: "cover",
        // }}
      >
        <div className="w-full h-[100vh] xl:flex lg:flex emd:hidden small:hidden justify-between items-center overflow-hidden">
          <div className="w-1/2 h-full flex flex-col items-center justify-center ps-8">
            <img
              src="/Netre-logo.svg"
              alt=""
              className="lg:w-[10rem] xl:w-[14rem] xl:ml-[3rem]"
            />
            <span className="text-center leading-[1.3em] text-secondary xl:ml-[3.2rem] lg:mt-5 xl:mt-[2rem] xl:text-[1.8rem] lg:text-[1.2rem] mont-semibold">
              India's Premier destination <br />
              for Quality Eyewear
            </span>
            <div className="xl:mt-10 lg:mt-8 xl:mb-8 lg:mb-6 fix_lg:ml-[3rem] xl:ml-[3rem]">
              <div className="">
                <FlipClockCountdown
                  to={finalDate}
                  labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                  labelStyle={{
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }}
                  digitBlockStyle={{
                    width: 65,
                    height: 85,
                    fontWeight: 500,
                    fontSize: 50,
                    backgroundColor: "#e9d193",
                  }}
                  dividerStyle={{ color: "#ffffff66", height: 1 }}
                  showSeparators={false}
                  duration={0.5}
                  spacing={0}
                  onComplete={() => {
                    setShowConfetti1(true);
                  }}
                ></FlipClockCountdown>
              </div>
            </div>
            <span className="xl:ml-[3rem] xl:mt-[1.8rem] lg:mt-[1rem] mont-book text-[#fcf8c2] text-center xl:text-[3rem] lg:text-[2rem] text-nowrap leading-[1.2em]">
              SOMETHING AMAZING <br />
              IS COMING SOON!
            </span>
            <div className="relative flex flex-col items-center">
              <input
                className="text-center xl:ml-[3.5rem] xl:mt-[2rem] lg:mt-[1rem] min-w-[300px] xl:text-[1rem] lg:text-[16px] shadow-lg border outline-none py-[1rem] placeholder-light_secondary placeholder:text-center border-[#ffffffcc] bg-[#ffffff5b] px-6 rounded-xl cursor-pointer"
                type="text"
                placeholder="ENTER EMAIL ADDRESS"
                name="email"
                onChange={subscribeFormik.handleChange}
                value={subscribeFormik.values.email}
                onBlur={subscribeFormik.handleBlur}
                errorMsg={subscribeFormik.errors.email}
                touched={subscribeFormik.touched.email}
              />
              {subscribeFormik.errors.email &&
                subscribeFormik.touched &&
                !subscribeFormik.isDisabled && (
                  <span
                    className={`absolute text-center min-w-[200px] translate-x-5 text-xs text-red-600 mt-1 -bottom-5`}
                  >
                    {subscribeFormik.errors.email}
                  </span>
                )}
            </div>
            <div className="relative flex flex-col items-center">
              <div
                className={` ${
                  formLoader && "px-10 py-[1rem]"
                } xl:ml-[3rem] xl:mt-[2rem] lg:mt-[1.5rem] min-w-[300px] xl:text-[1rem] lg:text-[16px] shadow-lg text-center hover:border-[#749fca] hover:bg-[#ffffffdb] border border-[#ffffffcc] bg-[#ffffff79] px-10 py-[0.5rem] rounded-xl cursor-pointer`}
                onClick={subscribeFormik.handleSubmit}
              >
                {formLoader ? (
                  <span className="animate-pulse mont-semibold text-center emd:translate-x-1/3 small:translate-x-0 text-xs">
                    Submitting...
                  </span>
                ) : (
                  <span>
                    REGISTER TO GET 40% OFF <br />
                    ON YOUR FIRST PURCHASE
                  </span>
                )}
                {/* REGISTER TO GET 50% OFF <br />
                ON YOUR FIRST PURCHASE */}
              </div>
              {/* {formLoader ? (
                <span className="text-[#fcf8c2] animate-pulse mont-semibold absolute text-center min-w-[200px] translate-x-5 mt-1 -bottom-8">
                  Submitting...
                </span>
              ) : successMsg !== "" ? (
                <span className="text-[#309138] mont-semibold absolute text-center min-w-[200px] translate-x-5 mt-1 -bottom-8">
                  {successMsg}
                </span>
              ) : (
                <span className="text-red-600 mont-semibold absolute text-center min-w-[200px] translate-x-5 mt-1 -bottom-8 text-nowrap">
                  {errorMsg}
                </span>
              )} */}
            </div>
          </div>
          <div className="w-1/2 relative h-full flex flex-col items-end justify-end">
            {/* <span className="absolute text-nowrap text-[#fcf8c2] xl:text-[6rem] lg:text-[4.5rem] xl:leading-[115px] lg:leading-[80px] xl:top-[3rem] lg:top-[2rem] xl:right-[6rem] lg:right-[4rem] text-right mont-light">
              GET READY TO <br />
              <span className="text-nowrap text-[#fcf8c2] tracking-[-0.1rem] xl:text-[6rem] lg:text-[4.5rem] xl:leading-[130px] lg:leading-[80px] xl:top-[3rem] lg:top-[2rem] xl:right-[6rem] lg:right-[4rem] text-right mont-light">
                BE NOTICED
              </span>
            </span> */}
            {/* <img src={TIMER_PAGE_GIRL} alt="" className="fixed xl:top-0 fix_lg:bottom-0 right-0 fix_lg:h-[100dvh] lg:h-[100vh] lg:w-[unset] xl:h-[100vh] fix_lg:w-[50%] xl:min-w-[unset] xl:max-w-[unset] pt-10" /> */}
            {/* <img
              src={TIMER_PAGE_GIRL}
              alt=""
              className="fixed lg:bottom-0 right-0 fix_xl:h-[90vh] xl:h-[90vh] lg:h-[90vh] w-[unset] pt-10"
            /> */}
            <span
              className="lg:text-[3.5rem] lg:top-[2rem] lg:right-[4rem] 
            e_lg:text-[4rem] e_lg:top-[2rem] e_lg:right-[4rem] 
            xl:text-[4rem] xl:top-[2rem] xl:right-[4rem] 
            m_xl:text-[4rem] m_xl:top-[2rem] m_xl:right-[4rem] 
            e_xl:text-[6rem] e_xl:top-[2rem] e_xl:right-[5.5rem] 
            leading-[1.2em] absolute text-nowrap text-[#fcf8c2] text-right mont-light"
            >
              GET READY TO <br />
              <span className="text-nowrap text-[#fcf8c2] tracking-[-0.1rem] text-right mont-light">
                BE NOTICED
              </span>
            </span>
            <img src={TIMER_PAGE_GIRL_} alt="" className="max-h-[75vh]" />
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full h-[100svh] max-h-[100svh] lg:hidden lemd:flex emd:flex mxs:flex lm:flex se:flex xs:flex small:flex items-center flex-col justify-between overflow-hidden">
          <div className="absolute top-0 left-0 w-full lemd:h-[55svh] emd:h-[55svh] mxs:h-[50svh] lm:h-[52svh] se:h-[55svh] xs:h-[55svh] overflow-hidden flex flex-col items-center justify-end">
            <div className="w-full flex flex-col items-center justify-end lm:mb-[5px] se:mb-[5px] xs:mb-[5px] emd:mb-[5px]">
              <img
                src="/Netre-logo.svg"
                alt=""
                // className="lemd:w-[12rem] emd:w-[12rem] small:w-[8rem] lemd:mt-[2rem] emd:mt-[2rem] mxs:mt-[1.5rem] lm:mt-[1rem] se:mt-[10px] xs:mt-[10px] "
                className="lemd:w-[12rem] emd:w-[12rem] small:w-[8rem] lemd:mt-0 emd:mt-0 mxs:mt-0 lm:mt-0 se:mt-[10px] xs:mt-[10px] "
              />
              <span className="text-center emd:leading-[1.2em] small:leading-[1em] text-secondary lemd:mt-5  emd:mt-5 small:mt-[1rem] lemd:text-[1.6rem] emd:text-[1.6rem] small:text-base mont-semibold">
                India's Premier destination <br />
                for Quality Eyewear
              </span>
              <div className="small:mt-2 small:mb-1 xs:mt-2 xs:mb-1 se:mt-2 se:mb-1 lm:mt-3 lm:mb-2 mxs:mt-3 mxs:mb-2">
                <FlipClockCountdown
                  to={finalDate}
                  className=""
                  labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                  labelStyle={{
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }}
                  digitBlockStyle={{
                    width: 40,
                    height: 50,
                    fontWeight: 500,
                    fontSize: 20,
                    backgroundColor: "#e9d193",
                  }}
                  dividerStyle={{ color: "#ffffff66", height: 1 }}
                  showSeparators={false}
                  duration={0.5}
                  spacing={0}
                  onComplete={() => {
                    setShowConfetti1(true);
                  }}
                ></FlipClockCountdown>
              </div>
              <span className="text-[#fcf8c2] text-center mont-book lemd:text-[2rem] emd:text-[2rem] se:text-[1.6rem] small:text-[1.8rem] text-nowrap leading-[1.3em]">
                SOMETHING AMAZING <br />
                IS COMING SOON!
              </span>
              <input
                className="lemd:mt-2 emd:mt-2 small:mt-[0.2rem] lemd:min-w-[420px] emd:min-w-[420px] lm:min-w-[260px] se:min-w-[260px] xs:min-w-[260px] text-center lemd:text-[16px] emd:text-[16px] small:text-[14px] lemd:px-6 emd:px-6 lemd:py-5 emd:py-5 small:px-2 small:py-3 leading-[1.4em] shadow-md border outline-none placeholder-light_secondary placeholder:text-center border-[#ffffffcc] bg-[#ffffff5b] rounded-lg cursor-pointer"
                type="text"
                placeholder="ENTER EMAIL ADDRESS"
                name="email"
                onChange={subscribeFormik.handleChange}
                value={subscribeFormik.values.email}
                onBlur={subscribeFormik.handleBlur}
                errorMsg={subscribeFormik.errors.email}
                touched={subscribeFormik.touched.email}
              />
              {/* {subscribeFormik.errors.email &&
              subscribeFormik.touched &&
              !subscribeFormik.isDisabled ? (
                <span
                  className={`text-xs text-center lemd:min-w-[420px] emd:min-w-[420px] lm:min-w-[260px] se:min-w-[260px] xs:min-w-[260px] text-red-600 mt-1`}
                >
                  {subscribeFormik.errors.email}
                </span>
              ) : (
                <span className={`mb-6`}></span>
              )} */}
              <div className="relative">
                <div
                  className={` ${
                    formLoader && "lemd:py-4 emd:py-4 small:px-3 small:py-3"
                  } mt-2 leading-[1.3em] lemd:min-w-[420px] emd:min-w-[420px] lm:min-w-[260px] se:min-w-[260px] xs:min-w-[260px] lemd:text-[16px] emd:text-[16px] small:text-[14px] lemd:px-2 emd:px-2 lemd:py-2 emd:py-2 small:px-2 small:py-1 shadow-md text-center border border-[#ffffffcc] bg-[#ffffff79] rounded-lg cursor-pointer`}
                  onClick={subscribeFormik.handleSubmit}
                >
                  {formLoader ? (
                    <span className="animate-pulse mont-semibold text-center emd:translate-x-1/3 small:translate-x-0 text-xs">
                      Submitting...
                    </span>
                  ) : (
                    <span>
                      REGISTER TO GET 40% OFF <br />
                      ON YOUR FIRST PURCHASE
                    </span>
                  )}
                </div>
                {/* 
                {formLoader && (
                  <span className="text-[#fcf8c2] animate-pulse mont-semibold absolute text-center min-w-[260px] emd:translate-x-1/3 small:translate-x-0 text-xs mt-1 -bottom-5">
                    Submitting...
                  </span>
                )} */}
                {/* {formLoader ? (
                  <span className="text-[#fcf8c2] animate-pulse mont-semibold absolute text-center min-w-[260px] emd:translate-x-1/3 small:translate-x-0 text-xs mt-1 -bottom-5">
                    Submitting...
                  </span>
                ) : successMsg !== "" ? (
                  <span className="text-[#309138] mont-semibold absolute text-center min-w-[200px] text-xs emd:translate-x-1/3 small:translate-x-1 mt-1 -bottom-5 text-nowrap">
                    {successMsg}
                  </span>
                ) : (
                  <span className="text-red-600 mont-semibold absolute text-center min-w-[260px] text-xs emd:translate-x-1/3 small:-translate-x-1 mt-1 -bottom-5 text-nowrap">
                    {errorMsg}
                  </span>
                )} */}
              </div>
            </div>
          </div>
          <div className="absolute lemd:top-[55svh] emd:top-[55svh] mxs:top-[5svh] lm:top-[50svh] se:top-[55svh] xs:top-[55svh] left-0 w-full lemd:h-[45svh] emd:h-[45svh] mxs:h-[50svh] lm:h-[50svh] se:h-[45svh] xs:h-[45svh] overflow-hidden lemd:text-[3.5rem] emd:text-[3.3rem] mxs:text-[2.5rem] lm:text-[2.5rem] se:text-[2rem] xs:text-[2rem] small:text-[2rem] flex justify-end">
            <span className="emd:left-[1.8rem] lm:left-[2rem] se:left-[1rem] xs:left-[1rem] emd:top-[1em] lm:top-[1em] se:top-[15px] xs:top-[15px] leading-[1em] absolute text-nowrap text-[#fcf8c2] text-left mont-light">
              GET READY TO <br />
              <span className="text-nowrap text-[#fcf8c2] tracking-[-0.1rem] text-right mont-light">
                BE NOTICED
              </span>
            </span>
            <img
              src={TIMER_PAGE_GIRL_}
              alt=""
              className="absolute bottom-0 right-0 w-[unset] emd:max-h-[40vh] se:max-h-[35vh] xs:max-h-[35vh] object-contain"
            />
          </div>
        </div>

        {/* <div className="w-full h-full absolute top-0 left-0 ">
              // className="fixed bottom-0 right-0 w-[unset] emd:h-auto lm:h-auto se:h-[40%] xs:h-[40%] object-contain"
          <img src={TIMER_JPEG} alt="" className="w-full h-full opacity-20" />
        </div> */}
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          transition={Bounce}
          toastStyle={{
            // opacity: 0.9,
            backgroundColor: "#fcf5ccbb",
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`App ${
          location.pathname.includes("retailer") &&
          location.pathname !== "/become-retailer"
            ? "pt-[68px]"
            : "lg:pt-[140px] emd:pt-[45px] small:pt-[45px]"
        } `}
        onClick={() => {
          setLoginModelToggle(false);
          loginModelToggle && sessionStorage.setItem("loginModelClosed", true); // Prevent modal from showing again during the current session
        }}
      >
        <Helmet>
            <title>Netre</title>
            <meta name="description" content=" " />
            <meta name="keywords" content=" "/>
        </Helmet>
        {location.pathname.includes("retailer") &&
        location.pathname !== "/become-retailer" ? (
          <RetailerHeader
            eyeFramesRef={eyeFramesRef}
            newArrivalsRef={newArrivalsRef}
            ourStoreRef={ourStoreRef}
            contactUsRef={contactUsRef}
          />
        ) : (
          <Header
            isRetailerPage={isRetailerPage}
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
            headerLabelsData={headerLabelsData}
            setLoginModelToggle={setLoginModelToggle}
            loginModelToggle={loginModelToggle}
            headerCategoriesData={headerCategoriesData}
          />
        )}
        {/* {isRetailerPage ? (
        <RetailerHeader />
      ) : (
        <Header
          isRetailerPage={isRetailerPage}
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        />
      )} */}
        {/* <Header
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        /> */}
        <MyBag
          setLensStep={setLensStep}
          blueCutLensIdDataLabel={headerLabelsData}
        />
        <Sidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
          headerCategoriesData={headerCategoriesData}
        />
        {showConfetti1 && (
          <ReactConfetti
            width={width}
            height={height}
            gravity={0.1}
            initialVelocityX={7}
            initialVelocityY={15}
            tweenDuration={8000}
            numberOfPieces={500}
            confettiSource={{
              w: 10,
              h: 10,
              x: width / 2,
              y: height / 2,
            }}
            recycle={false}
          />
        )}
        {showConfetti2 && (
          <ReactConfetti
            width={width}
            height={height}
            gravity={0.1}
            initialVelocityX={3}
            initialVelocityY={6}
            numberOfPieces={250}
            recycle={true}
          />
        )}
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Home blueCutLensIdDataLabel={headerLabelsData} />
              </>
            }
          />
          {/* <Route
          exact
          path="/:retailer"
          element={
            <>
              <Retailer setIsRetailerPage={setIsRetailerPage} />
            </>
          }
        /> */}
          <Route
            exact
            path="/retailer/:retailerName"
            element={
              <>
                <Retailer
                  setIsRetailerPage={setIsRetailerPage}
                  eyeFramesRef={eyeFramesRef}
                  newArrivalsRef={newArrivalsRef}
                  ourStoreRef={ourStoreRef}
                  contactUsRef={contactUsRef}
                />
              </>
            }
          />
          <Route
            exact
            path="/products/:id"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ProductPage blueCutLensIdDataLabel={headerLabelsData} />
              </Suspense>
            }
          />
          <Route
            path="/categories/:name"
            element={
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <Category blueCutLensIdDataLabel={headerLabelsData} />
                </Suspense>
              </>
            }
          />
          <Route
            path="/search/:searchProductParams?"
            element={
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <Category blueCutLensIdDataLabel={headerLabelsData} />
                </Suspense>
              </>
            }
          />
          <Route
            path="/favourite"
            element={
              <>
                <Favorites blueCutLensIdDataLabel={headerLabelsData} />
              </>
            }
          />
          <Route
            path="/shopping-bag"
            element={
              <ShoppingBag
                setLensStep={setLensStep}
                blueCutLensIdDataLabel={headerLabelsData}
              />
            }
          />
          <Route path="/profile-details" element={<ProfileDetails />} />
          <Route path="/order-check/:orderId" element={<OrderCheck />} />
          <Route
            path="/order-placed/:orderId"
            element={<OrderPlaced blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/order-failed/:orderId"
            element={<OrderFailed blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route path="/shopping-tray" element={<ShoppingTray />} />
          <Route path="/home-tryon" element={<HTO />} />
          <Route path="/accessories" element={<Accessories />} />
          <Route
            path="/track-order/:trackOrderId/:trackProductSku"
            element={<TrackOrder />}
          />
          <Route
            path="/exchange-order/:exchangeOrderId/:exchangeProductId/:exchangeProductSku"
            element={<ExchangeOrder />}
          />
          <Route
            path="/cancel-order/:cancelOrderId/:cancelProductId"
            element={<CancelOrder />}
          />
          <Route
            path="/return-order/:returnOrderId/:returnProductId/:returnProductSku"
            element={<ReturnOrder />}
          />
          <Route
            path="/shopping-tray/confirm"
            element={
              <>
                <div className="">Confirmation page</div>
              </>
            }
          />

          <Route
            path="/login"
            element={<LoginSignup blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/signup"
            element={<LoginSignup blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/lens"
            element={<Lens step={lensStep} setStep={setLensStep} />}
          />
          <Route path="/lens-only" element={<LensOnly />} />
          <Route path="/vision-wellness" element={<VisionWellness />} />
          <Route path="/best-sellers" element={<BestSellers />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/become-retailer" element={<BecomeRetailer />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/exchange-policy" element={<ExchangePolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/store-locator" element={<StoreLocator />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/become-retailer" &&
        location.pathname.includes("retailer") ? null : (
          <Footer lensStep={lensStep} />
        )}

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          transition={Bounce}
          toastStyle={{
            // opacity: 0.9,
            backgroundColor: "#fcf5ccbb",
          }}
          style={{ marginTop: "68px" }} // Add desired top margin
        />
        {loginModelToggle && (
          <>
            <div
              className="fixed left-0 top-0 z-9999 flex h-full min-h-screen w-full items-center justify-center bg-black/50 px-4 py-5"
              // onClick={handleClickOutside}
            >
              <div
                ref={modalRef}
                className={`border-0 overflow-hidden cursor-pointer shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none`}
              >
                <div className="flex items-center justify-between relative">
                  <img src={LOGIN_MODEL_BANNER} alt="" className="max-h-[90vh] object-contain" onClick={()=>navigate("/login")} />
                  <img src={CLOSE_BLACK_IC} alt= "" className="absolute right-0 top-0 p-2 " />
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-999 bg-black"></div>
          </>
        )}
      </div>
    );
  }
}

export default App;

function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
  // scrooll .App to top
  const scrollArea = document.querySelector(".App");
  if (!scrollArea) return;
  scrollArea.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
}

export const PageNotFound = () => {
  return (
    <Link
      to="/"
      style={{
        width: "100%",
        height: "70vh",
        objectFit: "contain",
        display: "block",
      }}
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          verticalAlign: "middle",
          objectFit: "contain",
        }}
        src={PAGE_NOT_FOUND}
        alt="PAGE NOT FOUND"
      />
    </Link>
  );
};

const OrderCheck = () => {
  const { orderId } = useParams();
  const [checkPaymentStatus] = useCheckPaymentStatusMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (orderId) {
      console.log(orderId);
      const body = {
        order_id: orderId,
      };
      checkPaymentStatus({ body: body })
        .then((res) => {
          if (res.data.status == "Placed") {
            navigate(`/order-placed/${orderId}`);
          } else {
            navigate(`/order-failed/${orderId}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orderId]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999999,
        width: "100vw",
        maxWidth: "100%",
        height: "100dvh",
        overflow: "hidden",
      }}
    >
      <Skeleton height={"100%"} />
      <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <p>
          Please do not go back or refresh the page. Your payment is being
          processed...
        </p>
      </div>
    </div>
  );
};

{
  /* <div className="mt-5 hidden opacity-0">
<FlipClockCountdown
  to={finalDate}
  labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
  labelStyle={{
    fontSize: 8,
    fontWeight: 500,
    textTransform: "uppercase",
  }}
  digitBlockStyle={{
    width: 32,
    height: 42,
    fontWeight: 500,
    fontSize: 24,
    backgroundColor: "#e9d193",
  }}
  dividerStyle={{ color: "#ffffff66", height: 1 }}
  showSeparators={false}
  duration={0.5}
  spacing={0}
>
  Finished
</FlipClockCountdown>
</div> */
}
