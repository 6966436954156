import { toast } from "react-toastify";
import React, { useEffect } from "react";
import SliderChild from "./SliderChild";
import { ARROW_BACK_IC, ARROW_FORWARD_IC } from "../../../assets";
import "./slider.scss";
import SlicerCard from "../../Master/SlicerCard";

export default function Slider(props) {
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      const scrollImages = document.querySelector(".premium_ware");
      const scrollLength = scrollImages.scrollWidth - scrollImages.clientWidth;
      const leftButton = document.querySelector(".left");
      const rightButton = document.querySelector(".right");

      function checkScroll() {
        const currentScroll = scrollImages.scrollLeft;
        if (currentScroll === 0) {
          leftButton.setAttribute("disabled", "true");
          rightButton.removeAttribute("disabled");
        } else if (currentScroll === scrollLength) {
          rightButton.setAttribute("disabled", "true");
          leftButton.removeAttribute("disabled");
        } else {
          leftButton.removeAttribute("disabled");
          rightButton.removeAttribute("disabled");
        }
      }

      scrollImages.addEventListener("scroll", checkScroll);
      window.addEventListener("resize", checkScroll);
      checkScroll();
    });
  }, []);

  function leftScroll() {
    const scrollImages = document.querySelector(".premium_ware");
    scrollImages?.scrollBy({
      left: -295,
      behavior: "smooth",
    });
  }

  function rightScroll() {
    const scrollImages = document.querySelector(".premium_ware");
    scrollImages?.scrollBy({
      left: 295,
      behavior: "smooth",
    });
  }

  return (
    <div className="cover mt-2">
      {props?.products?.length > 4 && (
        <button className="left" onClick={leftScroll}>
          <div className="material-symbols-outlined" style={{ color: "black" }}>
            <img src={ARROW_BACK_IC} alt="" className="w-6" />
          </div>
        </button>
      )}
      {props?.products && (
        <div
          className={`premium_ware cards ${
            props?.products?.length < 4
              ? "lg:justify-center emd:justify-center small:justify-start"
              : ""
          }`}
        >
          {props?.products?.slice(0, 8)?.map((item, index) => {
            return (
              item?.isAccessories !== true && (
                <SlicerCard
                  data={item}
                  key={index}
                  blueCutLensIdDataLabel={props?.blueCutLensIdDataLabel}
                  isNewArrival={props?.isNewArrival}
                />
              )
            );
          })}
        </div>
      )}
      {props?.products?.length > 4 && (
        <button className="right" onClick={rightScroll}>
          <div className="material-symbols-outlined" style={{ color: "black" }}>
            <img src={ARROW_FORWARD_IC} alt="" className="w-8" />
          </div>
        </button>
      )}
    </div>
  );
}
