import React, { useEffect, useRef, useState } from "react";
import INPUT from "../../Master/INPUT";
import BTN from "../../Master/BTN";
import MODEL from "../../Master/MODEL";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Breadcrumbs from "../../Utilities/BreadCrumbs/Breadcrumbs";
import {
  ARROW_FORWARD_BLACK_IC,
  ARROW_KEYWORD_DOWN,
  ARROW_KEYWORD_UP,
  CALL_IC,
  CHECK_CIRCLE_IC,
  CLOSE_BLACK_IC,
  CLOSE_IC,
  CLOSE_IC_18,
  CREDIT_CARD_IC,
  CURRENCY_IC,
  OFFER_TAG_IC,
  SCHEDULE_IC,
  SEARCH_IC,
} from "../../../assets";
import {
  useAddOrderMutation,
  useCheckAddCouponMutation,
  useGetCODStatusQuery,
  useGetCouponListQuery,
  useGetCustomerBagDataQuery,
  useGetUserProfileQuery,
  useSendPaymentRequestMutation,
} from "../../../redux/slices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isValidEmail } from "../../Footer/Footer";
import { formatCurrency } from "../../../functions";
import {
  addOrderDetails,
  removeOrderDetails,
} from "../../../redux/slices/myBagSlice";

const ShoppingTray = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = localStorage.getItem("user");
  const [isSelected, setIsSelected] = useState("shipping"); // billing, shipping, payment
  const [isStandardDelivery, setIsStandardDeliver] = useState(true);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("payzapp");
  const [checkoutLoader, setCheckoutLoader] = useState(false);

  // ----------------------------- GET PROFILE DETAILS API CALLING -----------------------
  const [selectShippingAddressToggle, setSelectShippingAddressToggle] =
    useState(false);
  const [selectBillingAddressToggle, setSelectBillingAddressToggle] =
    useState(false);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);

  const myBagitems = useSelector((state) => state.myBagData.items);
  const orderDetails = useSelector((state) => state?.myBagData?.orderDetails);

  const [couponsToggle, setCouponsToggle] = useState(false);
  // const subTotal = myBagitems.reduce(
  //   (acc, item) =>
  //     acc + item.prices.price * item.quantity + (item?.lensDetails?.price || 0),
  //   0
  // );

  // const subTotal = myBagitems?.reduce(
  //   (acc, item) =>
  //     acc +
  //     item?.prices?.price * item?.quantity +
  //     item?.lensDetails?.reduce(
  //       (lensTotal, lens) => lensTotal + lens?.price,
  //       0
  //     ),
  //   0
  // );

  // const subTotal = myBagitems?.reduce((total, item) => {
  //   // Calculate the product price based on quantity
  //   const productPrice = item?.prices?.price * item?.quantity;

  //   // Calculate the total lens price for this product (sum of all lenses)
  //   const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
  //     // Add prices of all addons for this lens
  //     const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
  //       return addonsSum + addon.price;
  //     }, 0);
  //     return addonsPrice + lensTotal + lens?.price || 0;
  //   }, 0);
  //   // Add the total lens price to the product price
  //   const itemTotal = productPrice + (totalLensPrice || 0);

  //   return total + itemTotal;
  // }, 0);

  const subTotal = () => {
    return myBagitems?.reduce((total, item) => {
      // Calculate the product price based on quantity
      const productPrice = item?.prices?.price * item?.quantity;

      // Calculate the total lens price for this product (sum of all lenses)
      const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
        // Add prices of all addons for this lens
        const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
          return addonsSum + addon.price;
        }, 0);
        return addonsPrice + lensTotal + lens?.price || 0;
      }, 0);

      // Add the total lens price to the product price
      const itemTotal = productPrice + (totalLensPrice || 0);

      return total + itemTotal;
    }, 0);
  };

  // const total = myBagitems.reduce(
  //   (acc, item) =>
  //     acc + item.prices.price * item.quantity + (item?.lensDetails?.price || 0),
  //   0
  // );

  // const total = myBagitems?.reduce(
  //   (acc, item) =>
  //     acc +
  //     item?.prices?.price * item?.quantity +
  //     item?.lensDetails?.reduce(
  //       (lensTotal, lens) => lensTotal + lens?.price,
  //       0
  //     ),
  //   0
  // );

  const total = myBagitems?.reduce((total, item) => {
    // Calculate the product price based on quantity
    const productPrice = item?.prices?.price * item?.quantity;

    // Calculate the total lens price for this product (sum of all lenses)
    const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
      // Add prices of all addons for this lens
      const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
        return addonsSum + addon.price;
      }, 0);
      return addonsPrice + lensTotal + lens?.price || 0;
    }, 0);
    // Add the total lens price to the product price
    const itemTotal = productPrice + (totalLensPrice || 0);

    return total + itemTotal;
  }, 0);

  const calculateCouponOffer = (coupon) => {
    if (coupon) {
      if (coupon?.discountType?.type === "fixed") {
        return total - coupon?.discountType?.value;
      } else {
        return parseFloat(
          total - (total * coupon?.discountType?.value) / 100
        ).toFixed(2);
      }
    } else {
      return total;
    }
  };

  const frameTotal = myBagitems?.reduce((total, item) => {
    // Calculate the product price based on quantity
    const productPrice = item?.prices?.price * item?.quantity;

    // Calculate the total lens price for this product (sum of all lenses)

    return total + productPrice;
  }, 0);

  const lensTotal = myBagitems?.reduce((total, item) => {
    const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
      return lensTotal + lens?.price || 0;
    }, 0);

    return total + totalLensPrice;
  }, 0);

  const addonTotal = myBagitems?.reduce((total, item) => {
    const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
      const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
        return addonsSum + addon.price;
      }, 0);
      return lensTotal + addonsPrice;
    }, 0);
    return total + totalLensPrice;
  }, 0);

  const {
    data: customerProfileData,
    isSuccess: customerProfileDataIsSuccess,
    isLoading: customerProfileDataIsLoading,
    isError: customerProfileDataIsError,
    error: customerProfileDataError,
  } = useGetUserProfileQuery({ user: user }, { skip: !user });

  // --------------------------------- ADD ORDER HANDLE --------------------------------

  const [addOrderBody, setAddOrderBody] = useState(null);
  const [remarkMsgVel, setRemarkMsgVal] = useState("");

  const shippingFormik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
      country: "",
      phone: "",
      email: "",
      label: "",
    },
    onSubmit: async function (values) {
      setAddOrderBody({
        ...addOrderBody,
        user_info: {
          firstname: values?.firstname,
          lastname: values?.lastname,
          email: values?.email,
          contact: values?.phone,
          address: selectedShippingAddress
            ? {
                ...selectedShippingAddress,
                address1: values?.address1,
                address2: values?.address2,
                city: values?.city,
                country: values?.country,
                firstname: values?.firstname,
                label: values?.label,
                lastname: values?.lastname,
                phone: values?.phone,
                zipCode: values?.zipCode,
              }
            : {
                address1: values?.address1,
                address2: values?.address2,
                city: values?.city,
                country: values?.country,
                firstname: values?.firstname,
                label: values?.label,
                lastname: values?.lastname,
                phone: values?.phone,
                zipCode: values?.zipCode,
              },
        },
      });
      setIsSelected("billing");

      window.scrollTo({
        top: 0,
        behavior: "smooth", // This makes the scroll smooth
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.firstname) {
        errors.firstname = "First Name is Required!";
      } else if (!values?.lastname) {
        errors.lastname = "Last Name is Required!";
      } else if (!values?.address1) {
        errors.address1 = "Address One is Required!";
      } else if (!values?.zipCode) {
        errors.zipCode = "Pin Code is Required!";
      } else if (!values?.city) {
        errors.city = "City is Required!";
      } else if (!values?.country) {
        errors.country = "Country is Required!";
      } else if (!values?.phone) {
        errors.phone = "Phone Number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phone)) {
        errors.phone = "Invalid Phone Number";
      } else if (values.phone.length !== 10) {
        errors.phone = "Phone Number must be 10 digits";
      }
      // else if (!values?.label) {
      //   errors.label = "Save As is Required!";
      // }
      return errors;
    },
  });

  const billingFormik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
      country: "",
      phone: "",
      email: "",
      label: "",
    },
    onSubmit: async function (values) {
      setAddOrderBody({
        ...addOrderBody,
        billing_info: {
          firstname: values?.firstname,
          lastname: values?.lastname,
          email: values?.email,
          contact: values?.phone,
          address: selectedBillingAddress
            ? {
                ...selectedBillingAddress,
                address1: values?.address1,
                address2: values?.address2,
                city: values?.city,
                country: values?.country,
                firstname: values?.firstname,
                label: values?.label,
                lastname: values?.lastname,
                phone: values?.phone,
                zipCode: values?.zipCode,
              }
            : {
                address1: values?.address1,
                address2: values?.address2,
                city: values?.city,
                country: values?.country,
                firstname: values?.firstname,
                label: values?.label,
                lastname: values?.lastname,
                phone: values?.phone,
                zipCode: values?.zipCode,
              },
        },
      });
      setIsSelected("payment");

      window.scrollTo({
        top: 0,
        behavior: "smooth", // This makes the scroll smooth
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.firstname) {
        errors.firstname = "First Name is Required!";
      } else if (!values?.lastname) {
        errors.lastname = "Last Name is Required!";
      } else if (!values?.address1) {
        errors.address1 = "Address One is Required!";
      } else if (!values?.zipCode) {
        errors.zipCode = "Pin Code is Required!";
      } else if (!values?.city) {
        errors.city = "City is Required!";
      } else if (!values?.country) {
        errors.country = "Country is Required!";
      } else if (!values?.phone) {
        errors.phone = "Phone Number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phone)) {
        errors.phone = "Invalid Phone Number";
      } else if (values.phone.length !== 10) {
        errors.phone = "Phone Number must be 10 digits";
      }
      // else if (!values?.label) {
      //   errors.label = "Save As is Required!";
      // }
      return errors;
    },
  });

  const [sameAsBilling, setSameAsBilling] = useState(false);

  const handleCheckboxChange = (e) => {
    setSameAsBilling(e.target.checked);
    if (e.target.checked) {
      billingFormik.setValues(shippingFormik.values);
      setSelectedBillingAddress(selectedShippingAddress);
      setIsSelected("payment");
    } else {
      billingFormik.resetForm();
      setSelectedBillingAddress(null);
    }
  };

  const billingAddressSelectHandle = (item) => {
    setSelectedBillingAddress(item);
    setSelectBillingAddressToggle(false);
    billingFormik.setValues({
      ...billingFormik.values,
      address1: item?.address1,
      address2: item?.address2,
      zipCode: item?.zipCode,
      phone: item?.phone,
      city: item?.city,
      country: item?.country,
      label: item?.label,
    });
  };

  const shippingAddressSelectHandle = (item) => {
    setSelectedShippingAddress(item);
    setSelectShippingAddressToggle(false);
    shippingFormik.setValues({
      ...shippingFormik.values,
      address1: item?.address1,
      address2: item?.address2,
      zipCode: item?.zipCode,
      phone: item?.phone,
      city: item?.city,
      country: item?.country,
      label: item?.label,
    });
  };

  useEffect(() => {
    if (customerProfileDataIsSuccess) {
      const { customer } = customerProfileData;
      console.log("customer", customer);
      shippingFormik.setFieldValue("firstname", customer?.firstname);
      shippingFormik.setFieldValue("lastname", customer?.lastname);
      shippingFormik.setFieldValue("email", customer?.email);
      customer?.addresses[0] &&
        setSelectedShippingAddress(customer?.addresses[0]);
      customer?.addresses[0] &&
        setSelectedBillingAddress(customer?.addresses[0]);
      customer?.addresses[0] &&
        shippingFormik.setValues({
          ...shippingFormik.values,
          firstname: customer?.addresses[0]?.firstname,
          lastname: customer?.addresses[0]?.lastname,
          address1: customer?.addresses[0]?.address1,
          address2: customer?.addresses[0]?.address2,
          zipCode: customer?.addresses[0]?.zipCode,
          phone: customer?.addresses[0]?.phone,
          city: customer?.addresses[0]?.city,
          country: customer?.addresses[0]?.country,
          label: customer?.addresses[0]?.label,
        });
      customer?.addresses[0] &&
        billingFormik.setValues({
          ...billingFormik.values,
          firstname: customer?.addresses[0]?.firstname,
          lastname: customer?.addresses[0]?.lastname,
          address1: customer?.addresses[0]?.address1,
          address2: customer?.addresses[0]?.address2,
          zipCode: customer?.addresses[0]?.zipCode,
          phone: customer?.addresses[0]?.phone,
          city: customer?.addresses[0]?.city,
          country: customer?.addresses[0]?.country,
          label: customer?.addresses[0]?.label,
        });
      setAddOrderBody({
        ...addOrderBody,
        cart: myBagitems.map((item) => ({
          product_id: item?.product_id,
          isAccessories: item?.isAccessories,
          readingGlassesPower: item?.readingGlassesPower,
          isReadingGlasses: item?.isReadingGlasses
            ? item?.isReadingGlasses
            : false,
          name: item?.name,
          title: item?.name,
          images: item?.image,
          sku: item?.sku,
          quantity: item?.quantity,
          // price: item?.prices.price,
          variants: [],
          attributes: item.attributes,
          orderStatus: selectedPaymentMode == "payzapp" ? "Pending" : "Placed",
          netreCare: [],
          lensDetails:
            item?.lensDetails?.map((lens) => ({
              _id: lens?._id,
              name: lens?.name,
              lensCode: lens?.lensCode,
              type: lens?.type,
              // price: lens?.price,
              category: lens?.category || "", // Adjust the category based on your data
              image: lens?.image,
              prescription: lens?.prescriptionDetail, // Assuming this holds prescription details
              addons: lens?.addons,
            })) || [],
        })),

        shippingOption: "netre",
        couponCode: orderDetails?.couponCode?.couponCode,
        paymentMethod: selectedPaymentMode,
        status: selectedPaymentMode === "payzapp" ? "Pending" : "Placed",
        // subTotal: subTotal,
        shippingCost: 0,
        // total: Math.ceil(calculateCouponOffer(orderDetails?.couponCode)),
        netreCashUsed: 0,
        // discount: Math.ceil(
        //   parseFloat(
        //     total - calculateCouponOffer(orderDetails?.couponCode)
        //   )?.toFixed(2)
        // ),
        customerId: customerProfileData?.customer?._id,
        checkoutType: "customer",
        isGift: orderDetails?.isGift,
        giftMessage: orderDetails?.giftMessage,
      });
    }
  }, [customerProfileDataIsSuccess, myBagitems, selectedPaymentMode]);

  const [addOrder] = useAddOrderMutation();
  const [sendPaymentRequest] = useSendPaymentRequestMutation();

  const addOrderHandle = () => {
    setCheckoutLoader(true);
    const paymentRequestBody = {
      amount: addOrderBody?.total,
      customer_id: addOrderBody?.customerId,
      customer_email: addOrderBody.user_info?.email,
      customer_phone: addOrderBody.user_info?.contact,
      first_name: addOrderBody.user_info?.firstname,
      last_name: addOrderBody.user_info?.lastname,
    };

    console.log(
      "addOrderBody",
      addOrderBody,
      orderDetails?.couponCode?.couponCode
    );
    addOrder({
      body: { ...addOrderBody, remarkMessage: remarkMsgVel },
      user: localStorage.getItem("user"),
    }).then((res) => {
      if (res?.data) {
        if (addOrderBody.paymentMethod === "payzapp") {
          sendPaymentRequest({
            body: { _id: res.data.data._id },
          }).then((res) => {
            if (res?.data) {
              if (res.data.status === "NEW") {
                var url = res.data.payment_links.web;
                return (window.location.href = url);
              }
            }
            if (res?.error) {
              return toast.error(`${res?.error?.data?.message}`);
            }
          });
        } else {
          toast.success(`Order placed successfully.`);
          navigate("/profile-details#order-history");
        }
        setCheckoutLoader(false);
      } else {
        setCheckoutLoader(false);
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  };
  const [shoppingIsCompleted, setShoppingIsCompleted] = useState(false);
  const [billingIsCompleted, setBillingIsCompleted] = useState(false);
  useEffect(() => {
    shippingFormik?.values?.firstname !== "" &&
    shippingFormik?.values?.lastname !== "" &&
    shippingFormik?.values?.address1 !== "" &&
    shippingFormik?.values?.zipCode !== "" &&
    shippingFormik?.values?.city !== "" &&
    shippingFormik?.values?.country !== "" &&
    shippingFormik?.values?.phone !== ""
      ? // &&shippingFormik?.values?.email !== ""
        // &&    shippingFormik?.values?.label !== ""
        setShoppingIsCompleted(true)
      : setShoppingIsCompleted(false);

    billingFormik?.values?.firstname !== "" &&
    billingFormik?.values?.lastname !== "" &&
    billingFormik?.values?.address1 !== "" &&
    billingFormik?.values?.zipCode !== "" &&
    billingFormik?.values?.city !== "" &&
    billingFormik?.values?.country !== "" &&
    billingFormik?.values?.phone !== ""
      ? // &&billingFormik?.values?.email !== ""
        //  &&    billingFormik?.values?.label !== ""
        setBillingIsCompleted(true)
      : setBillingIsCompleted(false);
  }, [billingFormik, shippingFormik]);

  const isNotGo = () => {
    return myBagitems?.some((item) =>
      item?.quantity < item?.lensDetails?.length ? true : false
    );
  };

  const {
    data: codStatus,
    isSuccess: codStatusSuccess,
    isLoading: codStatusIsLoading,
    isError: codStatusIsError,
    error: codStatusError,
  } = useGetCODStatusQuery();
  console.log("codStatus", codStatus?.cod_status);

  // =========================== ADD COUPON ====================

  const [checkAddCoupon] = useCheckAddCouponMutation();

  const couponApplyHandle = (item) => {
    if (checkCouponCodeIsValid(item)) {
      dispatch(
        addOrderDetails({
          ...orderDetails,
          couponCode: item,
        })
      );
      setAddOrderBody({
        ...addOrderBody,
        couponCode: item?.couponCode,
      });
      setCouponsToggle(false);
      toast.success(`${item?.couponCode} applied`);
    }
  };
  const checkEndDateOfCoupon = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();
    if (currentTime > endTime || coupon?.couponQuantity === 0) {
      return true;
    } else {
      return false;
    }
  };

  const {
    data: customerBagData,
    isSuccess: customerBagIsSuccess,
    isLoading: customerBagIsLoading,
    isError: customerBagIsError,
    error: customerBagError,
  } = useGetCustomerBagDataQuery(
    { user: localStorage.getItem("user") },
    { skip: !localStorage.getItem("user") }
  );

  const {
    data: couponData,
    isSuccess: couponDataIsSuccess,
    isLoading: couponDataIsLoading,
    isError: couponDataIsError,
    error: couponDataError,
  } = useGetCouponListQuery({ user: user, couponType: "online" });

  const checkCouponCodeIsValid = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();

    if (currentTime > endTime) {
      toast.error("Sorry! This coupon has expired");
      return false;
    }
    if (subTotal() < coupon?.minimumAmount) {
      toast.error(
        `Oops! This coupon is valid on shopping of Rs ${coupon?.minimumAmount} or More`
      );
      return false;
    }
    if (coupon?.couponQuantity === 0) {
      toast.error(`Uh oh! This coupon is no longer available`);
      return false;
    }
    return true;
  };

  const formateDate = (date) => {
    const estimatedDeliveryDate = new Date(date);
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate());
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedEstimatedDeliveryDate =
      estimatedDeliveryDate.toLocaleDateString("en-GB", options);
    return formattedEstimatedDeliveryDate;
  };

  const couponFormik = useFormik({
    initialValues: {
      couponCode: "",
    },
    onSubmit: async function (values) {
      checkAddCoupon({
        body: {
          couponCode: values?.couponCode,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          if (checkCouponCodeIsValid(res?.data?.data)) {
            dispatch(
              addOrderDetails({
                ...orderDetails,
                couponCode: res?.data?.data,
              })
            );

            setAddOrderBody({
              ...addOrderBody,
              couponCode: res?.data?.data?.couponCode,
            });

            toast.success(`${res?.data?.data?.couponCode} applied`);
          }
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    // validate: (values) => {
    //   let errors = {};
    //   if (!values?.couponCode) {
    //     errors.couponCode = "Coupon Code is Required!";
    //   }
    //   return errors;
    // },
  });

  return (
    <div className="w-full lg:px-[25px] lg:pb-[25px] emd:px-[20px] emd:pb-[20px] small:px-[10px] small:pb-[10px]">
      <div className="w-full">
        <Breadcrumbs
          breadcrumbs={[{ label: "Shopping Tray", link: "/shopping-bag" }]}
        />
      </div>
      {myBagitems?.length === 0 ? (
        <div className="w-full flex-col my-52 flex items-center justify-center">
          <span className="text-light_secondary text-2xl">
            Your Bag is empty!
          </span>
          <BTN
            className={"mt-16 py-1 px-4 lg:w-auto md:w-auto small:w-fit"}
            title="Start shopping"
            bordered
            to={"/search"}
          />
        </div>
      ) : (
        <div className="w-full flex emd:flex-col lg:flex-row flex-wrap items-start justify-between">
          <div className="lg:w-[60%] emd:w-full small:w-full">
            {/* ============================ SHIPPING DETAILS =========================== */}
            <div className="w-full bg-gray flex flex-col p-5">
              <div
                className="w-full flex items-center justify-between cursor-pointer"
                onClick={() =>
                  setIsSelected(isSelected !== "shipping" ? "shipping" : "")
                }
              >
                <span className="text-xl cursor-pointer select-none">
                  1. Shipping Details
                </span>
                <div className="material-symbols-outlined text-2xl text-primary">
                  {shoppingIsCompleted ? "check_circle" : "circle"}
                </div>
              </div>
              {isSelected === "shipping" && (
                <div className="w-full mt-7 flex flex-col items-start">
                  <div className="w-[90%] border-b border-[#44444480] flex items-center">
                    <span
                      onClick={() => setIsStandardDeliver(true)}
                      className={` ${
                        isStandardDelivery ? "border-b-2 border-primary" : ""
                      } pb-3 select-none cursor-pointer`}
                    >
                      Standard Delivery
                    </span>
                    {/* <span
                    onClick={() => setIsStandardDeliver(false)}
                    className={` ${
                      !isStandardDelivery ? "border-b-2 border-primary" : ""
                    } pb-3 ms-7 select-none cursor-pointer `}
                  >
                    Collect in Store
                  </span> */}
                  </div>
                  {isStandardDelivery ? (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        shippingFormik.handleSubmit();
                      }}
                      className="w-full flex flex-col items-start"
                    >
                      <div className="w-full mt-3 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"First Name"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="firstname"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.firstname}
                          onBlur={shippingFormik.handleBlur}
                          errorMsg={shippingFormik.errors.firstname}
                          touched={shippingFormik.touched.firstname}
                        />
                        <INPUT
                          placeHolder={"Last Name"}
                          mainClass={
                            "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                          }
                          name="lastname"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.lastname}
                          onBlur={shippingFormik.handleBlur}
                          errorMsg={shippingFormik.errors.lastname}
                          touched={shippingFormik.touched.lastname}
                        />
                      </div>
                      <INPUT
                        placeHolder={"Address 1"}
                        mainClass={"mt-2"}
                        name="address1"
                        onChange={shippingFormik.handleChange}
                        val={shippingFormik.values.address1}
                        onBlur={shippingFormik.handleBlur}
                        errorMsg={shippingFormik.errors.address1}
                        touched={shippingFormik.touched.address1}
                        rComp={
                          user && (
                            <span
                              className="underline text-primary select-none cursor-pointer"
                              onClick={() =>
                                setSelectShippingAddressToggle(true)
                              }
                            >
                              Select
                            </span>
                          )
                        }
                      />
                      <INPUT
                        placeHolder={"Address 2 (Optional)"}
                        mainClass={"mt-2"}
                        name="address2"
                        onChange={shippingFormik.handleChange}
                        val={shippingFormik.values.address2}
                        touched={shippingFormik.touched.address2}
                      />
                      <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"Pin code"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="zipCode"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.zipCode}
                          onBlur={shippingFormik.handleBlur}
                          errorMsg={shippingFormik.errors.zipCode}
                          touched={shippingFormik.touched.zipCode}
                        />
                        <INPUT
                          placeHolder={"City"}
                          mainClass={
                            "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                          }
                          name="city"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.city}
                          onBlur={shippingFormik.handleBlur}
                          errorMsg={shippingFormik.errors.city}
                          touched={shippingFormik.touched.city}
                        />
                      </div>

                      <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"Country"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="country"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.country}
                          onBlur={shippingFormik.handleBlur}
                          errorMsg={shippingFormik.errors.country}
                          touched={shippingFormik.touched.country}
                        />
                        <INPUT
                          placeHolder={"Email"}
                          mainClass={
                            "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                          }
                          name="email"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.email}
                          onBlur={shippingFormik.handleBlur}
                          errorMsg={shippingFormik.errors.email}
                          touched={shippingFormik.touched.email}
                        />
                      </div>

                      <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"Ph. No."}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="phone"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.phone}
                          onBlur={shippingFormik.handleBlur}
                          errorMsg={shippingFormik.errors.phone}
                          touched={shippingFormik.touched.phone}
                        />

                        <INPUT
                          placeHolder={"Save as ( home, work )"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="label"
                          onChange={shippingFormik.handleChange}
                          val={shippingFormik.values.label}
                          onBlur={shippingFormik.handleBlur}
                          // errorMsg={shippingFormik.errors.label}
                          touched={shippingFormik.touched.label}
                        />
                      </div>

                      <BTN
                        title={"CONTINUE"}
                        bordered
                        type="submit"
                        onP={shippingFormik.handleSubmit}
                        className="border-border_color text-base text-center px-1 rounded-none text-border_color mt-7 w-[48%] py-1 bg-gray"
                      />
                    </form>
                  ) : (
                    <div className="flex flex-col items-start lg:w-[50%] emd:w-full small:w-full">
                      <span className="text-lg mt-10 ">
                        Select Pick up store
                      </span>
                      <INPUT
                        placeHolder={"Enter pincode/ location"}
                        mainClass={"mt-2"}
                        rComp={
                          <div className="material-symbols-outlined ">
                            <img src={SEARCH_IC} alt="" className="w-5" />
                          </div>
                        }
                      />
                      <div className="flex items-center mt-3 select-none">
                        <input
                          type="checkbox"
                          id="home_store"
                          className="cursor-pointer w-4 h-4 accent-primary "
                        />
                        <label
                          className="ms-2 cursor-pointer"
                          htmlFor="home_store"
                        >
                          Use My Home Store
                        </label>
                      </div>
                      <div className="p-3 mt-3 border border-light_secondary flex flex-col emd:w-full small:w-full lg:w-[95%]">
                        <div className="w-full flex items-center justify-between">
                          <span className="text-primary text-base">
                            My Home Store
                          </span>
                          <img
                            src={CLOSE_BLACK_IC}
                            alt=""
                            className="cursor-pointer"
                          />
                        </div>
                        <div className="mt-3">
                          Shop No 12, Nr Iskon Ambli Road, 384300 Lorem Ipsum
                        </div>
                        <div className="mt-3 flex justify-start items-center">
                          <div className="flex items-center">
                            <div className="material-symbols-outlined">
                              <img src={CALL_IC} alt="" className="w-5" />
                            </div>
                            <span className="ms-2">9903909083</span>
                          </div>
                          <div className="flex items-center ms-5">
                            <div className="material-symbols-outlined">
                              <img src={SCHEDULE_IC} alt="" className="w-5" />
                            </div>
                            <span className="ms-2">11:00 am 7:00 pm</span>
                          </div>
                        </div>
                      </div>
                      <BTN
                        title={"CONTINUE"}
                        bordered
                        className="border-border_color text-base text-center px-1 rounded-none text-border_color mt-10 w-[48%] py-1 bg-gray"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* ============================ BILLING DETAILS =========================== */}
            <div className="w-full bg-gray flex flex-col mt-5 p-5">
              <div
                className="w-full flex items-center justify-between cursor-pointer"
                onClick={() => {
                  shoppingIsCompleted && shippingFormik.handleSubmit();
                  shoppingIsCompleted &&
                    setIsSelected(isSelected !== "billing" ? "billing" : "");
                }}
                // onClick={() => {
                //   isSelected === "shipping"
                //     ? shippingFormik.handleSubmit()
                //     : setIsSelected(isSelected !== "billing" ? "billing" : "");
                // }}
              >
                <span className="text-xl cursor-pointer select-none">
                  2. Billing Details
                </span>
                <div className="material-symbols-outlined text-2xl text-primary">
                  {billingIsCompleted ? "check_circle" : "circle"}
                </div>
              </div>
              {isSelected === "billing" && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    billingFormik.handleSubmit();
                  }}
                  className="w-full mt-7 flex flex-col items-start"
                >
                  <div className="flex items-center select-none">
                    <input
                      type="checkbox"
                      id="same_as"
                      className="cursor-pointer w-4 h-4 accent-primary"
                      checked={sameAsBilling}
                      onChange={handleCheckboxChange}
                    />
                    <label className="ms-2 cursor-pointer" htmlFor="same_as">
                      Same as shipping details
                    </label>
                  </div>
                  <div className="w-full mt-3 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                    <INPUT
                      placeHolder={"First Name"}
                      mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                      name="firstname"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.firstname}
                      onBlur={billingFormik.handleBlur}
                      errorMsg={billingFormik.errors.firstname}
                      touched={billingFormik.touched.firstname}
                    />
                    <INPUT
                      placeHolder={"Last Name"}
                      mainClass={
                        "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                      }
                      name="lastname"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.lastname}
                      onBlur={billingFormik.handleBlur}
                      errorMsg={billingFormik.errors.lastname}
                      touched={billingFormik.touched.lastname}
                    />
                  </div>
                  <INPUT
                    placeHolder={"Address 1"}
                    mainClass={"mt-2"}
                    name="address1"
                    onChange={billingFormik.handleChange}
                    val={billingFormik.values.address1}
                    onBlur={billingFormik.handleBlur}
                    errorMsg={billingFormik.errors.address1}
                    touched={billingFormik.touched.address1}
                    rComp={
                      user && (
                        <span
                          className="underline text-primary select-none cursor-pointer"
                          onClick={() => setSelectBillingAddressToggle(true)}
                        >
                          Select
                        </span>
                      )
                    }
                  />
                  <INPUT
                    placeHolder={"Address 2 (Optional)"}
                    mainClass={"mt-2"}
                    name="address2"
                    onChange={billingFormik.handleChange}
                    val={billingFormik.values.address2}
                    touched={billingFormik.touched.address2}
                  />
                  <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                    <INPUT
                      placeHolder={"Pin code"}
                      mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                      name="zipCode"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.zipCode}
                      onBlur={billingFormik.handleBlur}
                      errorMsg={billingFormik.errors.zipCode}
                      touched={billingFormik.touched.zipCode}
                    />
                    <INPUT
                      placeHolder={"City"}
                      mainClass={
                        "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                      }
                      name="city"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.city}
                      onBlur={billingFormik.handleBlur}
                      errorMsg={billingFormik.errors.city}
                      touched={billingFormik.touched.city}
                    />
                  </div>

                  <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                    <INPUT
                      placeHolder={"Country"}
                      mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                      name="country"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.country}
                      onBlur={billingFormik.handleBlur}
                      errorMsg={billingFormik.errors.country}
                      touched={billingFormik.touched.country}
                    />
                    <INPUT
                      placeHolder={"Email"}
                      mainClass={
                        "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                      }
                      name="email"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.email}
                      onBlur={billingFormik.handleBlur}
                      errorMsg={billingFormik.errors.email}
                      touched={billingFormik.touched.email}
                    />
                  </div>

                  <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                    <INPUT
                      placeHolder={"Ph. No."}
                      mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                      name="phone"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.phone}
                      onBlur={billingFormik.handleBlur}
                      errorMsg={billingFormik.errors.phone}
                      touched={billingFormik.touched.phone}
                    />

                    <INPUT
                      placeHolder={"Save as ( home, work )"}
                      mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                      name="label"
                      onChange={billingFormik.handleChange}
                      val={billingFormik.values.label}
                      onBlur={billingFormik.handleBlur}
                      // errorMsg={billingFormik.errors.label}
                      touched={billingFormik.touched.label}
                    />
                  </div>
                  <BTN
                    title={"CONTINUE"}
                    bordered
                    type="submit"
                    onP={billingFormik.handleSubmit}
                    className="border-border_color text-base text-center px-1 rounded-none text-border_color mt-7 w-[48%] py-1 bg-gray"
                  />
                </form>
              )}
            </div>
            {/* ============================ PAYMENT DETAILS =========================== */}
            <div className="w-full bg-gray flex flex-col mt-5 p-5">
              <div
                className="w-full flex items-center justify-between cursor-pointer"
                onClick={() => {
                  shoppingIsCompleted &&
                    billingIsCompleted &&
                    billingFormik.handleSubmit();
                  shoppingIsCompleted &&
                    billingIsCompleted &&
                    setIsSelected(isSelected !== "payment" ? "payment" : "");
                }}
                // onClick={() => {
                //   isSelected === "billing"
                //     ? billingFormik.handleSubmit()
                //     : setIsSelected(
                //         isSelected !== "payment" ||
                //           (shoppingIsCompleted && billingIsCompleted)
                //           ? "payment"
                //           : ""
                //       );
                // }}
              >
                <span className="text-xl select-none">3. Payment Details</span>
              </div>
              {isSelected === "payment" && (
                <div className="w-full mt-7 flex flex-col items-start">
                  {/* <div className="w-full flex flex-col p-3 bg-white">
                  <span className="text-lg">Netre Pay</span>
                  <div className="w-full bg-gray p-3 mt-3 flex items-center justify-between">
                    <div className="flex flex-col">
                      <span className="text-base">Available Points</span>
                      <span className="text-base">Points : 0.11</span>
                    </div>
                    <input
                      type="radio"
                      className="w-5 h-5 accent-primary cursor-pointer"
                      name="netre"
                      value="netre"
                      checked={selectedPaymentMode === "netre"}
                      onChange={(e) => setSelectedPaymentMode(e.target.value)}
                    />
                  </div>
                </div> */}
                  <div className="w-full flex flex-col mt-4 p-3 bg-white">
                    <div className="w-full flex flex-col">
                      <span className="text-lg">Payment Mode</span>
                      <div className="w-full bg-gray p-3 mt-3 flex items-center justify-between">
                        <label
                          htmlFor="payzapp"
                          className="flex items-center cursor-pointer w-full"
                        >
                          <div className="material-symbols-outlined ">
                            <img src={CREDIT_CARD_IC} alt="" className="w-8" />
                          </div>
                          <span className="text-base ms-2">
                            Card / UPI / Net Banking
                          </span>
                        </label>
                        <input
                          id="payzapp"
                          type="radio"
                          className="w-5 h-5 accent-primary cursor-pointer"
                          name="payzapp"
                          value="payzapp"
                          checked={selectedPaymentMode === "payzapp"}
                          onChange={(e) =>
                            setSelectedPaymentMode(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    {codStatus?.cod_status && (
                      <div className="w-full flex flex-col">
                        <div className="w-full bg-gray p-3 mt-3 flex items-center justify-between">
                          <label
                            htmlFor="cash"
                            className="flex items-center w-full cursor-pointer"
                          >
                            <div className="material-symbols-outlined">
                              <img src={CURRENCY_IC} alt="" className="w-8" />
                            </div>
                            <span className="text-base ms-2">COD</span>
                          </label>
                          <input
                            id="cash"
                            type="radio"
                            className="w-5 h-5 accent-primary cursor-pointer"
                            name="Cash"
                            value="Cash"
                            checked={selectedPaymentMode === "Cash"}
                            onChange={(e) =>
                              setSelectedPaymentMode(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <BTN
                    title={"CONTINUE"}
                    className="text-base text-center px-1 rounded-none  mt-10 w-[48%] py-1"
                    onP={addOrderHandle}
                    loading={checkoutLoader}
                  />
                </div>
              )}
            </div>
            <INPUT
              rows={3}
              placeHolder={"Enter Remark Message (Optional)"}
              className={"bg-white border w-full"}
              mainClass={"mt-5 bg-gray p-5 pb-0"}
              val={remarkMsgVel}
              onChange={(m) => setRemarkMsgVal(m.target.value)}
            />
          </div>
          <div className="xl:w-[30%] lg:w-[35%] emd:w-full small:w-full small:mt-5 emd:mt-5 lg:mt-0 bg-gray flex flex-col p-5">
            <span className="text-xl">Order Summary</span>
            {myBagitems?.map((item, index) => {
              return <OrderSummaryProductCard key={index} product={item} />;
            })}
            <div className="w-full flex flex-col mt-8 pb-3 border-b border-secondary">
              <div className="w-full flex items-center justify-between">
                <span>Frames</span>
                <span>₹{frameTotal}</span>
              </div>
              {lensTotal !== 0 && (
                <div className="w-full flex items-center justify-between">
                  <span>Lens</span>
                  <span>₹{lensTotal}</span>
                </div>
              )}
              {addonTotal !== 0 && (
                <div className="w-full flex items-center justify-between">
                  <span>Addons</span>
                  <span>₹{addonTotal}</span>
                </div>
              )}
              <div className="w-full border-b border-dashed border-light_secondary my-2"></div>
              <div className="w-full flex items-center justify-between mt-2">
                <span>Sub Total</span>
                <span>₹{subTotal()}</span>
              </div>
              <div className="w-full flex items-center mt-2 justify-between">
                <span>Delivery</span>
                <span>Free</span>
              </div>
              {Math.round(
                parseFloat(
                  subTotal() - calculateCouponOffer(orderDetails?.couponCode)
                )?.toFixed(2)
              ) !== 0 && (
                <div className="w-full flex items-center mt-2 justify-between">
                  <span>Offer discount</span>
                  <span>
                    - ₹
                    {Math.round(
                      parseFloat(
                        subTotal() -
                          calculateCouponOffer(orderDetails?.couponCode)
                      )?.toFixed(2)
                    )}
                  </span>
                </div>
              )}

              {orderDetails?.couponCode?.couponCode === undefined ? (
                <div className="w-full flex flex-col items-start">
                  <INPUT
                    placeHolder={"Enter Coupon Code"}
                    className={" border border-light_secondary"}
                    mainClass={"mt-4"}
                    name="couponCode"
                    onChange={couponFormik.handleChange}
                    val={couponFormik.values.couponCode}
                    onBlur={couponFormik.handleBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        couponFormik.handleSubmit();
                      }
                    }}
                    rComp={
                      couponFormik.values.couponCode === "" ? (
                        <></>
                      ) : (
                        <span
                          className="underline underline-offset-4 text-primary select-none cursor-pointer"
                          onClick={couponFormik.handleSubmit}
                        >
                          Apply
                        </span>
                      )
                    }
                  />
                  {customerBagData?.appliedCoupon !== "" && (
                    <span className="-mt-4 text-primary text-sm">
                      You have one or more offers. Check it out!
                    </span>
                  )}
                  <span
                    className="view_offers text-primary mt-1 underline cursor-pointer"
                    onClick={() => setCouponsToggle(true)}
                  >
                    View offers
                  </span>
                </div>
              ) : (
                <div className="flex items-center mt-2">
                  <div className="bg-primary px-2 py-1 rounded flex items-center">
                    <div className="flex items-center text-base">
                      <img src={OFFER_TAG_IC} alt="" />
                      <span className="text-white ms-2">
                        {orderDetails?.couponCode?.couponCode}
                      </span>
                    </div>
                    <img
                      onClick={() =>
                        dispatch(
                          removeOrderDetails({
                            items: myBagitems,
                            orderDetails: {
                              isGift: orderDetails?.isGift,
                              giftMessage: orderDetails?.giftMessage,
                              couponCode: null,
                            },
                          })
                        )
                      }
                      src={CLOSE_IC_18}
                      alt=""
                      className="cursor-pointer ms-2"
                    />
                  </div>
                </div>
              )}
              {/* <div className="offers_div mt-2">
                  <div className="offer_chip_div">
                    <div className="offer_chip">
                      <img src={OFFER_TAG_IC} alt="" />
                      <span className="offer_chip_title">
                        {orderDetails?.couponCode?.couponCode}
                      </span>
                      <img
                        onClick={() =>
                          dispatch(
                            removeOrderDetails({
                              items: myBagitems,
                              orderDetails: {
                                isGift: orderDetails?.isGift,
                                giftMessage: orderDetails?.giftMessage,
                                couponCode: null,
                              },
                            })
                          )
                        }
                        src={CLOSE_IC_18}
                        alt=""
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </div> */}

              {/* {orderDetails?.couponCode?.couponCode && (
                <div className="flex items-center mt-2">
                  <div className="bg-primary px-2 py-1 rounded flex items-center">
                    <div className="flex items-center text-base">
                      <img src={OFFER_TAG_IC} alt="" />
                      <span className="text-white ms-2">
                        {orderDetails?.couponCode?.couponCode}
                      </span>
                    </div>
                  </div>
                </div>
              )} */}
              {/* <div className="w-full flex items-center mt-2 justify-between">
              <span>Offer discount</span>
              <span>₹4470</span>
            </div> */}
            </div>
            <div className="w-full mt-3 flex items-center justify-between">
              <span className="font-semibold text-lg mont-bold">
                Total amount
              </span>
              <span className="mont-bold text-lg">
                ₹
                {formatCurrency(
                  Math.round(calculateCouponOffer(orderDetails?.couponCode))
                )}
              </span>
            </div>
          </div>
        </div>
      )}
      {/* ============================== BILLING ADDRESS ====================== */}
      <MODEL
        showModel={selectBillingAddressToggle}
        setShowModel={setSelectBillingAddressToggle}
        title="Select Address"
      >
        <div className="w-full flex items-center justify-center">
          {customerProfileData?.customer?.addresses?.length > 0 ? (
            <div className="w-full min-h-[60dh] max-h-[60dvh] overflow-auto p-10 grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-x-4 gap-y-4 hide_scrollbar items-center">
              {customerProfileData?.customer?.addresses?.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => billingAddressSelectHandle(item)}
                    className={` ${
                      item?._id === selectedBillingAddress?._id
                        ? "border border-primary"
                        : ""
                    } bg-gray p-4 overflow-hidden cursor-pointer select-none w-full h-44 justify-between flex flex-col`}
                  >
                    <div className="w-full flex justify-between items-center h-12 overflow-hidden">
                      <span className="text-lg font-bold h-full text-ellipsis">
                        {item?.firstname} {item?.label && ` (${item?.label})`}
                      </span>
                    </div>
                    <span className="text-base mt-2 w-full text-wrap truncate h-12 overflow-hidden text-ellipsis">
                      {item?.address1}, {item?.address2}
                    </span>
                    <div className="flex items-center mt-2">
                      <div className="material-symbols-outlined ">
                        <img src={CALL_IC} alt="" className="w-5" />
                      </div>
                      <span className="ms-1">{item?.phone}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-[30vh] w-full flex flex-col items-center text-center text-light_secondary justify-center">
              No Address Found
            </div>
          )}
        </div>
      </MODEL>

      {/* ============================== SHIPPING ADDRESS ====================== */}
      <MODEL
        showModel={selectShippingAddressToggle}
        setShowModel={setSelectShippingAddressToggle}
        title="Select Address"
      >
        <div className="w-full flex items-center justify-center">
          {customerProfileData?.customer?.addresses?.length > 0 ? (
            <div className="w-full min-h-[60dh] max-h-[60dvh] overflow-auto p-10 grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-x-4 gap-y-4 hide_scrollbar items-center">
              {customerProfileData?.customer?.addresses?.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => shippingAddressSelectHandle(item)}
                    className={` ${
                      item?._id === selectedShippingAddress?._id
                        ? "border border-primary"
                        : ""
                    } bg-gray overflow-hidden p-4 cursor-pointer select-none w-full h-44 justify-between flex flex-col`}
                  >
                    <div className="w-full flex justify-between items-center h-12 overflow-hidden">
                      <span className="text-lg font-bold h-full text-ellipsis">
                        {item?.firstname} {item?.label && ` (${item?.label})`}
                      </span>
                    </div>
                    <span className="text-base mt-2 w-full text-wrap truncate h-12 overflow-hidden text-ellipsis">
                      {item?.address1}, {item?.address2}
                    </span>
                    <div className="flex items-center mt-2">
                      <div className="material-symbols-outlined text-xl">
                        <img src={CALL_IC} alt="" className="w-5" />
                      </div>
                      <span className="ms-1">{item?.phone}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-[30vh] w-full flex flex-col items-center text-center text-light_secondary justify-center">
              No Address Found
            </div>
          )}
        </div>
      </MODEL>

      {/* ============================== COUPON MODEL ====================== */}
      <MODEL
        showModel={couponsToggle}
        setShowModel={setCouponsToggle}
        title="Select coupon"
      >
        <div className="w-full">
          {couponData?.coupons?.length !== 0 ? (
            <div className="w-full min-h-[60dh] max-h-[60dvh] overflow-auto lg:px-10 emd:px-5 small:px-4 pb-10 grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-x-4 gap-y-4 hide_scrollbar items-center">
              {couponData?.coupons?.map((item, index) => {
                return item?.status === "show" ? (
                  <div
                    key={index}
                    className={`${
                      orderDetails?.couponCode?.couponCode === item?.couponCode
                        ? "border-primary border-2"
                        : "border-[#8B94B2] border"
                    } p-4 select-none lg:h-64 emd:h-56 small:h-48 min-w-full max-w-full flex flex-col justify-between ${
                      checkEndDateOfCoupon(item) ? "opacity-50" : ""
                    } `}
                  >
                    <div className="w-full flex items-center">
                      {/* <span className="text-2xl font-bold">40% OFF</span>
                  <span className="text-lg">For whole order</span> */}
                      <span className="text-2xl font-bold text-primary">
                        {item?.couponCode}
                      </span>
                      {/* <div
                    onClick={() => handleCopyClick(item?.couponCode)}
                    className="flex items-center ms-3 cursor-pointer"
                  >
                    <div className="material-symbols-outlined text-xl text-primary">
                      content_copy
                    </div>
                    <span className="ms-2 text-primary">Copy</span>
                  </div> */}
                    </div>
                    <div className="flex items-center">
                      <ul>
                        <li className="text-light_secondary text-sm">
                          {item?.title?.en}
                        </li>
                        <li className="text-light_secondary text-sm">
                          Minimum Amount : {item?.minimumAmount}
                        </li>
                        <li className="text-light_secondary text-sm">
                          Valid on {formateDate(item?.endTime)} order only
                        </li>
                      </ul>
                    </div>
                    <div className="w-full flex flex-col justify-between">
                      <div className=" flex items-center">
                        <button
                          disabled={checkEndDateOfCoupon(item)}
                          onClick={() => couponApplyHandle(item)}
                          className={`flex items-center ${
                            checkEndDateOfCoupon(item)
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                        >
                          <div className="material-symbols-outlined">
                            <img
                              src={ARROW_FORWARD_BLACK_IC}
                              alt=""
                              className="w-5"
                            />
                          </div>
                          <span className="ms-2">Apply</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          ) : (
            <div className="h-[30vh] w-full flex flex-col items-center text-center text-light_secondary justify-center">
              No Coupons Found
            </div>
          )}
        </div>
      </MODEL>
    </div>
  );
};

export default ShoppingTray;

function OrderSummaryProductCard({ product }) {
  const navigate = useNavigate();
  const [openLensDetails, setOpenLensDetails] = useState(null);
  const totalWithLens = product?.lensDetails?.reduce(
    (acc, lens) => acc + lens?.price,
    0
  );
  const [selectedShowPrescription, setSelectedShowPrescription] =
    useState(null);
  const totalAddonsCount = product?.lensDetails?.reduce((lensTotal, lens) => {
    return lensTotal + lens?.addons?.length;
  }, 0);

  const totalWithAddons = product?.lensDetails?.reduce((lensSum, lens) => {
    const addonsPrice = lens?.addons?.reduce((addonsSum, addon) => {
      return addonsSum + addon?.price;
    }, 0);
    return lensSum + addonsPrice;
  }, 0);

  return (
    <div className="w-full flex flex-col bg-white p-4 mt-[25px]">
      <div className="flex w-full mb-3">
        <div
          className="lg:min-w-[140px] lg:max-w-[140px] lg:min-h-[120px] lg:max-h-[120px]
    emd:min-w-[140px] emd:max-w-[140px] emd:min-h-[120px] emd:max-h-[120px]
    small:min-w-[120px] small:max-w-[120px] small:min-h-[100px] small:max-h-[100px]
     object-contain flex items-center justify-center overflow-hidden bg-gray"
        >
          <img
            onClick={() =>
              navigate(
                product?.slug
                  ? `/products/${product?.slug}`
                  : `/products/${product?._id}`
              )
            }
            src={
              product?.image?.length > 0
                ? product?.image[0]?.replace(
                    "upload/",
                    "upload/if_w_gt_150/w_150/if_end/"
                  )
                : ""
            }
            className="w-[120px] cursor-pointer"
            alt=""
          />
        </div>
        <div
          onClick={() =>
            navigate(
              product?.slug
                ? `/products/${product?.slug}`
                : `/products/${product?._id}`
            )
          }
          className="cursor-pointer lg:w-[calc(100%-140px)] emd:w-[calc(100%-140px)] small:w-[calc(100%-120px)] pl-4 flex flex-col"
        >
          <div className="w-full flex lg:flex-row emd:flex-row mxs:flex-row lm:flex-row small:flex-col-reverse small:items-start emd:items-start lg:items-start justify-between">
            <span className="mont-regular lg:text-lg emd:text-lg small:text-base w-[95%]">
              {product?.name}
            </span>
          </div>
          <span className="text-base mont-regular">
            <span className="currency_symbol">₹</span>
            {product?.prices?.price}
          </span>
          <span className="text-sm mt-2 text-primary">
            <span className="text-sm text-secondary">Quantity - </span>
            {product?.quantity}
          </span>
        </div>
      </div>
      {product?.lensDetails?.length > 0 && (
        <div className="w-full flex flex-col">
          <div className=" w-full flex flex-col">
            <div
              onClick={() =>
                setOpenLensDetails(
                  product?.product_id !== openLensDetails
                    ? product?.product_id
                    : null
                )
              }
              className="cursor-pointer w-full flex items-center justify-between select-none"
            >
              <div className="text-base mont-regular uppercase">
                Lens Details{" "}
                <span className="text-sm mont-bold">
                  <span className="mont-regular">( </span>
                  {product?.lensDetails?.length}{" "}
                  <span className="mont-regular"> Lens</span>
                  <span className="mont-regular">
                    {totalAddonsCount > 0 && " + "}
                  </span>
                </span>
                {totalAddonsCount > 0 ? (
                  <span className="text-sm mont-bold text-primary">
                    {totalAddonsCount}
                    <span className="mont-regular text-primary"> Addons</span>
                  </span>
                ) : (
                  ""
                )}
                <span className="mont-regular"> )</span>
              </div>
              <img
                src={
                  product?.product_id === openLensDetails
                    ? ARROW_KEYWORD_UP
                    : ARROW_KEYWORD_DOWN
                }
                alt=""
                className=""
              />
            </div>

            {product?.product_id === openLensDetails &&
              product?.lensDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex items-start justify-between mt-2 pb-2"
                  >
                    <div
                      onClick={() =>
                        setSelectedShowPrescription(item?.prescriptionDetail)
                      }
                      className="flex items-start"
                    >
                      <span>{index + 1}.</span>
                      <div className="text-base ms-2 cursor-pointer flex flex-col">
                        <span className="flex text-base">
                          {item.name + " Lens"} &nbsp;
                          <span className="text-nowrap whitespace-pre ">
                            (₹ {item.price})
                          </span>
                        </span>
                        {item?.addons?.map((addon, addonIndex) => {
                          return (
                            <span
                              key={addonIndex}
                              className="ms-2 text-light_secondary text-sm"
                            >
                              <span className="me-2 text-light_secondary">
                                {addonIndex + 1}.
                              </span>
                              {addon?.name} &nbsp;
                              <span className="text-nowrap whitespace-pre  text-light_secondary">
                                (₹ {addon.price})
                              </span>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {product?.isReadingGlasses === true && (
        <div className="w-full flex flex-col">
          <div className=" w-full flex flex-col">
            <div
              onClick={() =>
                setOpenLensDetails(
                  product?.product_id !== openLensDetails
                    ? product?.product_id
                    : null
                )
              }
              className="cursor-pointer w-full flex items-center justify-between select-none"
            >
              <div className="text-base mont-regular uppercase">
                Power Details
              </div>
              <img
                src={
                  product?.product_id === openLensDetails
                    ? ARROW_KEYWORD_UP
                    : ARROW_KEYWORD_DOWN
                }
                alt=""
                className=""
              />
            </div>
            {product?.product_id === openLensDetails &&
              product?.readingGlassesPower?.map((power, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex items-start justify-start mt-2"
                  >
                    <span className="flex text-base items-center w-full text-light_secondary">
                      Frame {index + 1} Power = &nbsp;
                      <span className="text-secondary text-base">{power}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className="w-full flex justify-between mt-2 pt-2 border-t border-dashed border-light_secondary">
        <span>
          Total Price{" "}
          {product?.lensDetails?.length > 0
            ? `(Frame + Lens${totalAddonsCount > 0 ? ` + Addons` : ``})`
            : ``}
        </span>
        <span className="text-base mont-regular">
          <span className="currency_symbol">₹</span>
          {product?.lensDetails?.length > 0
            ? product?.prices?.price * product?.quantity +
              totalWithLens +
              totalWithAddons
            : product?.prices?.price * product?.quantity}
        </span>
      </div>

      {/* =================== PRESCRIPTION DETAILS MODEL =============== */}
      <MODEL
        setShowModel={() => setSelectedShowPrescription(null)}
        showModel={selectedShowPrescription !== null}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Prescription details</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setSelectedShowPrescription(null)}
            />
          </div>
          <div className="w-full my-5 px-5">
            <div className="xl:flex-row emd:flex-row small:flex-col fix_lg:flex-col flex items-center justify-between">
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 flex flex-col justify-evenly items-center xl:border-r emd:border-r small:border-b fix_lg:border-b xl:border-b-0 emd:border-b-0 small:border-r-0 fix_lg:border-r-0 border-light_secondary  xl:pr-3 fix_lg:pr-0 emd:pr-3 small:pr-0 xl:pb-0 emd:pb-0 small:pb-2 fix_lg:pb-2">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Left Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.leftEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {selectedShowPrescription?.leftEye?.left_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.leftEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.leftEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 lg:pl-3 fix_lg:pl-0 emd:pl-3 small:pl-0 flex flex-col justify-evenly items-center">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Right Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.rightEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {item?.leftEye?.right_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.rightEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.rightEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MODEL>
    </div>
  );
}

{
  /* <div className="w-full flex flex-col items-center mt-2 mb-2">
<div className="w-full flex flex-col items-start justify-between bg-white p-2">
  <div className="w-full flex items-start justify-between pb-2">
    <div className="w-1/2 overflow-hidden">
      <img
        src={product.image?.[0]?.replace(
          "upload/",
          "upload/if_w_gt_300/w_300/if_end/"
        )}
        alt=""
        className="w-[250px] h-[150px] object-contain"
      />
    </div>
    <div className="flex h-[150px] flex-col items-start justify-start w-[48%] overflow-hidden">
      <div className="flex flex-col">
        <span className="text-lg font-semibold">{product.name}</span>
        <span className="text-sm">SKU {product.sku}</span>
        <span className="text-sm mt-2">Qty {product.quantity}</span>
      </div>
      <div className="w-full mt-2 flex items-center">
        {product?.lensDetails?.price && (
          <span>₹{product.prices.price * product.quantity}</span>
        )}
      </div>
    </div>
  </div>
  <div className="w-full border-b border-light_secondary">
    {product?.lensDetails?.length > 0 && (
      <div className="w-full flex flex-col pb-2">
        <span className="mx-16 text-2xl">+</span>
        <div className=" w-full flex flex-col">
          <div className="py-1 text-base uppercase">Lens Details :</div>
          {product?.lensDetails?.map((item, index) => {
            return (
              <div
                key={index}
                className="w-full flex justify-between items-center pb-2 pr-5"
              >
                <div className="flex items-center">
                  <img
                    src={item?.image}
                    alt=""
                    className="min-w-[50px] max-w-[50px] bg-gray"
                  />
                  <span className="text-base ms-3">
                    {item?.name + " Lens"} &nbsp;
                    <span className="text-nowrap whitespace-pre ">(₹ {item?.price})</span>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )}
  </div>
  <div className="w-full mt-2 flex items-center justify-end">
    {product?.lensDetails?.price ? (
      <span>
        ₹
        {product.prices.price * product.quantity +
          product?.lensDetails?.price}
      </span>
    ) : (
      <span>₹{product.prices.price * product.quantity}</span>
    )}
  </div>
</div>
</div> */
}
