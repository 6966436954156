import React, { useRef } from "react";
import "./Home.scss";
import { Breakpoint } from "react-socks";
import LazyImageLoader from "../Master/LazyImageLoader";
import Slider from "react-slick/lib/slider";
import { useNavigate } from "react-router-dom";
import { ARROW_KEYWORD_LEFT, ARROW_KEYWORD_RIGHT } from "../../assets";
function MainBanner({ homeData }) {
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-sliding
    autoplaySpeed: 2000, // Set the interval (3 seconds)
    // vertical: true,
    // verticalSwiping: true,
    // rtl: true,
  };

  const navigate = useNavigate();

  return (
    <>
      <Breakpoint
        large
        up
        className="relative w-full flex items-center mt-10"
        style={{ objectFit: "cover", height: "auto" }}
      >
        <Slider
          {...settings}
          className=" w-full aspect-[16/5] flex flex-col overflow-hidden justify-center items-center"
        >
          {homeData?.main_banner_images?.map((image, index) => {
            return (
              <img
                key={index}
                src={image?.desktop}
                alt="Product"
                className="w-full h-auto object-cover"
                onClick={() => navigate(image?.redirect)}
              />
            );
          })}
        </Slider>
        <img
          src={ARROW_KEYWORD_RIGHT}
          onClick={() => {
            document.querySelector(".slick-arrow.slick-next").click();
          }}
          alt=""
          className="w-9 rounded-full h-fit bg-white bg-opacity-70 cursor-pointer absolute right-2"
        />
        <img
          src={ARROW_KEYWORD_LEFT}
          onClick={() => {
            document.querySelector(".slick-arrow.slick-prev").click();
          }}
          alt=""
          className="w-9 rounded-full h-fit bg-white bg-opacity-70 cursor-pointer absolute left-2"
        />
      </Breakpoint>
      <Breakpoint medium down style={{ objectFit: "cover", height: "auto" }}>
        <Slider
          {...settings}
          className="w-full aspect-[27/40] flex flex-col overflow-hidden justify-center items-center"
        >
          {homeData?.main_banner_images?.map((image, index) => {
            return (
              <img
                key={index}
                src={image?.mobile}
                alt="Product"
                className="w-full h-auto object-cover"
                onClick={() => navigate(image?.redirect)}
              />
            );
          })}
        </Slider>
      </Breakpoint>
    </>
  );
}
export default MainBanner;

{
  /* {homeData?.main_banner_images?.map((item, index) => {
          return (
            <LazyImageLoader
              key={index}
              imgClassName="Home-banner"
              imgSrc={item?.desktop}
              imgAlt="Main Banner"
              imgHeight="100dvh"
              imgWidth="100vw"
              imgObjectFit="cover"
            />
          );
        })} */
}

{
  /* {homeData?.main_banner_images?.map((item, index) => {
          return (
            <LazyImageLoader
              key={index}
              imgClassName="Home-banner"
              imgSrc={item?.mobile}
              imgAlt="Main Banner"
              imgHeight="100dvh"
              imgWidth="100vw"
              imgObjectFit="cover"
            />
          );
        })} */
}
