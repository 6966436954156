import React, { useCallback, useEffect, useRef, useState } from "react";
import BTN from "../../Master/BTN";
import {
  homeSlice,
  useGetCustomerHomeTryOnOrderDataQuery,
  useGetOrderDataMutationMutation,
  useGetOrderDataQuery,
  useRemoveFromCustomerHomeTryOnOrderDataMutation,
} from "../../../redux/slices/homeSlice";
import LOADER from "../../Master/LOADER";
import { fakeData } from "../../../json";
import Skeleton from "react-loading-skeleton";
import MODEL from "../../Master/MODEL";
import {
  ARROW_KEYWORD_DOWN,
  ARROW_KEYWORD_UP,
  CLOSE_BLACK_IC,
} from "../../../assets";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const OrderHistory = () => {
  const location = useLocation();

  const [orderType, setOrderType] = useState(
    location?.state?.orderTypeHome === "home" ? "home" : ""
  );
  const user = localStorage.getItem("user");
  const dispatch = useDispatch();

  const {
    data: customerOrderData,
    isSuccess: customerOrderDataIsSuccess,
    isLoading: customerOrderDataIsLoading,
    isError: customerOrderDataIsError,
    error: customerOrderDataError,
  } = useGetOrderDataQuery(
    { user: user, orderType: orderType },
    { skip: !user }
  );

  const {
    data: customerHomeTryOnOrderData,
    isSuccess: customerHomeTryOnOrderDataIsSuccess,
    isLoading: customerHomeTryOnOrderDataIsLoading,
    isError: customerHomeTryOnOrderDataIsError,
    error: customerHomeTryOnOrderDataError,
  } = useGetCustomerHomeTryOnOrderDataQuery(
    { user: user },
    { skip: !user || orderType !== "home" }
  );

  const [orderData, setOrderData] = useState([]);

  const [getOrderDataMutation] = useGetOrderDataMutationMutation();

  const [page, setPage] = useState(1);
  const [allLoaded, setAllLoaded] = useState(false); // To track if all items are loaded
  const [items, setItems] = useState([]); // Holds the grid items
  const [loading, setLoading] = useState(false); // Loading state
  const limit = 10; // Limit per page
  const observer = useRef(); // To track the 10th item for intersection

  const fetchData = async (page, limit) => {
    const data = await getOrderDataMutation({
      page: page,
      limit: limit,
      orderType: orderType,
      user: user,
    }).then((res) => {
      if (res.data) {
        if (res.data.orders.length === 0) {
          setAllLoaded(true);
        }
        // console.log(res.data);
        return res.data.orders;
      }
      if (res.error) {
        console.log(res.error);
      }
    });
    return data;
  };
  // Load data on page change
  const loadItems = async () => {
    setLoading(true);
    const newItems = await fetchData(page, limit); // Fetch new items based on page
    // setItems((prev) => [...prev, ...newItems]); // Append new items to the list
    // check if  the prev items are present or not only add those which are not present
    setItems((prev) => {
      // Create a Map where the key is the item._id and the value is the item itself
      const map = new Map();

      // Combine prev and newItems arrays
      const combinedItems = [...prev, ...newItems];

      // Iterate over the combined array and set each item in the Map using its _id as the key
      combinedItems.forEach((item) => map.set(item._id, item));

      // Return an array of the values from the Map (which will be unique by _id)
      return Array.from(map.values());
    });
    setLoading(false);
  };
  const resetAll = () => {
    if (observer.current) observer.current.disconnect();
    observer.current = null;
    setItems([]);
    setAllLoaded(false);
    setPage(1);
    loadItems();
  };
  // Intersection observer callback
  const lastItemRef = useCallback(
    (node) => {
      if (loading) return; // If loading, do nothing

      // Disconnect previous observer
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // When the 10th item (or last item in the list) is in view, increment page number
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node); // Observe the new node
    },
    [loading]
  );
  useEffect(() => {
    if (!allLoaded) {
      loadItems();
    }
  }, [page]);
  useEffect(() => {
    resetAll();
  }, [orderType]); //add your reseting dependencies here

  return (
    <div className="w-full overflow-hidden flex flex-col">
      <div className="flex items-center justify-between pb-4 border-b border-light_secondary">
        <span className="text-2xl font-bold">Order History</span>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        {/* ====================== EMPTY SCREEN UI ======================== */}
        {/* <div className="mt-5 w-full flex flex-col items-center justify-center">
          <span className="mt-10 text-center text-4xl text-[#44444499]">You have not placed any order yet</span>
          <BTN title="START SHOPPING" bordered className="mt-7 px-5 py-1 w-fit rounded-none" />
        </div> */}
        {/* =============================================================== */}
        <div className="w-full select-none my-7 flex items-center justify-start hide_scrollbar overflow-x-auto overflow-y-hidden">
          <div
            className={`px-4 py-1 text-center cursor-pointer text-nowrap whitespace-pre  ${
              orderType === ""
                ? "bg-secondary text-white"
                : "border border-secondary"
            }`}
            onClick={() => setOrderType("")}
          >
            All Orders
          </div>
          <div
            className={`px-4 py-1 text-center cursor-pointer text-nowrap whitespace-pre  ms-4 ${
              orderType === "online"
                ? "bg-secondary text-white"
                : "border border-secondary"
            }`}
            onClick={() => setOrderType("online")}
          >
            Online Orders
          </div>
          <div
            className={`px-4 py-1 text-center cursor-pointer text-nowrap whitespace-pre  ms-4 ${
              orderType === "instore"
                ? "bg-secondary text-white"
                : "border border-secondary"
            }`}
            onClick={() => setOrderType("instore")}
          >
            In Store Orders
          </div>
          <div
            className={`px-4 py-1 text-center cursor-pointer text-nowrap whitespace-pre  ms-4 ${
              orderType === "home"
                ? "bg-secondary text-white"
                : "border border-secondary"
            }`}
            onClick={() => setOrderType("home")}
          >
            Try at home Orders
          </div>
        </div>

        {orderType !== "home" ? (
          <div className="w-full flex flex-col items-center">
            {/* ============================== FOR LG [ loop through customerOrderData.orders and display each orders.cart ] =============================== */}

            {/* <div className="w-full h-[30dvh] flex flex-col items-center justify-center">
              <LOADER isLoading={customerOrderDataIsLoading} />
            </div> */}
            {customerOrderDataIsLoading ? (
              fakeData?.map((item, index) => {
                return (
                  <div className="w-full" key={index}>
                    <div className="w-full mb-5 lg:flex emd:hidden small:hidden items-start justify-between pb-2 border-b border-light_secondary">
                      <div className="w-1/3 flex items-center justify-center overflow-hidden bg-gray">
                        <Skeleton className="w-[250px] h-[200px] object-contain" />
                      </div>
                      <div className="flex flex-col items-start justify-between w-[64%] h-[200px] overflow-hidden">
                        <div className="w-full flex items-start justify-center">
                          <div className="w-full flex flex-col">
                            <span className="text-lg font-semibold w-1/2">
                              <Skeleton count={1} />
                            </span>
                            <span className="text-sm w-1/2">
                              <Skeleton count={1} />
                            </span>
                            <span className="text-sm w-1/2">
                              <Skeleton count={1} />
                            </span>
                            {/* <span className="text-lg mt-5"> ₹{data?.cart[0]?.price}</span> */}
                          </div>
                          <div className="flex flex-col w-1/2">
                            <Skeleton className="uppercase w-44 py-1.5 px-5 rounded-none text-sm" />
                            <Skeleton className="uppercase w-44 py-1.5 px-5 rounded-none text-sm lg:mt-4 emd:mt-0 small:mt-0" />
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="mont-bold mb-1 ">
                            <Skeleton count={1} />
                          </div>
                          <div className="w-full border-light_secondary border-t pt-4">
                            <Skeleton count={1} />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ===================================================================== */}
                    <div className="w-full mt-5 lg:hidden emd:flex small:flex items-start flex-col justify-between border-b border-light_secondary">
                      <div className="w-full flex justify-between">
                        <div className="w-[58%] flex items-center justify-center overflow-hidden bg-gray">
                          <Skeleton className="w-fit h-[200px] object-contain" />
                        </div>
                        <div className="w-[38%] flex flex-col">
                          <span className="text-lg font-semibold">
                            <Skeleton count={1} />
                          </span>
                          <span className="text-sm w-1/2">
                            <Skeleton count={1} />
                          </span>
                          <span className="text-sm w-1/2">
                            <Skeleton count={1} />
                          </span>
                          {/* <span className="text-lg mt-5">₹{data?.cart[0]?.price}</span> */}
                        </div>
                      </div>
                      <div className="w-full border-light_secondary border-t mt-4 pt-4">
                        <span className="text-base text-primary">
                          <Skeleton count={1} />
                        </span>
                      </div>
                      <div className="w-full my-5 flex justify-between items-center">
                        <Skeleton className="uppercase w-44 py-1.5 px-5 rounded-none text-sm" />
                        <Skeleton className="uppercase w-44 py-1.5 px-5 rounded-none text-sm lg:mt-4 emd:mt-0 small:mt-0 ms-1" />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {items.map((item, index) => {
                  if (index === items.length - 1) {
                    //without expanding single order to multiple cards
                    // return (
                    // <div className="w-full flex flex-col items-center" ref={lastItemRef} key={index}>
                    //   <OrderHistoryCard data={item} />
                    // </div>)
                    return item.cart.map((cartItem, index) => {
                      if (index === 0) {
                        return (
                          <div
                            className="w-full flex flex-col items-center"
                            ref={lastItemRef}
                            key={index}
                          >
                            <OrderHistoryCard
                              data={{ ...item, cart: [cartItem] }}
                              key={item._id}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="w-full flex flex-col items-center"
                            key={index}
                          >
                            <OrderHistoryCard
                              data={{ ...item, cart: [cartItem] }}
                              key={item._id + index}
                            />
                          </div>
                        );
                      }
                    });
                  } else {
                    //without expanding single order to multiple cards
                    // return <div className="w-full flex flex-col items-center" key={index}> <OrderHistoryCard data={item} /> </div>;
                    return item.cart.map((cartItem, index) => {
                      if (index === 0) {
                        return (
                          <div
                            className="w-full flex flex-col items-center"
                            key={index}
                          >
                            <OrderHistoryCard
                              data={{ ...item, cart: [cartItem] }}
                              key={item._id}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="w-full flex flex-col items-center"
                            key={index}
                          >
                            <OrderHistoryCard
                              data={{ ...item, cart: [cartItem] }}
                              key={item._id + index}
                            />
                          </div>
                        );
                      }
                    });
                  }
                })}
              </>
            )}

            {!items?.length > 0 ? (
              <div className="h-[30vh] flex flex-col items-center text-center text-light_secondary justify-center">
                No Orders Found - {items.length}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="w-full flex flex-col items-center">
            {customerHomeTryOnOrderData?.data?.map((order, index) => {
              return <HomeTryOnOrderCard data={order} key={index} />;
            })}
            {!customerHomeTryOnOrderData?.data?.length > 0 ? (
              <div className="h-[30vh] flex flex-col items-center text-center text-light_secondary justify-center">
                No Orders Found - {items.length}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderHistory;

function OrderHistoryCard({ data }) {
  const navigate = useNavigate();
  const [selectedShowPrescription, setSelectedShowPrescription] =
    useState(null);
  const estimatedDeliveryDate = new Date(data?.createdAt);
  estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 7);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedEstimatedDeliveryDate =
    estimatedDeliveryDate.toLocaleDateString("en-GB", options);

  //return and exchange buttton should be visible only until 10 days of order placed
  const [showReturnExchangeButton, setShowReturnExchangeButton] =
    useState(false);
  const orderUpdatedTimestamp = data.updatedAt;
  useEffect(() => {
    const checkReturnExchangeButtonVisibility = () => {
      const orderTime = new Date(orderUpdatedTimestamp);
      const currentTime = new Date();
      const timeDifference = currentTime - orderTime; // Difference in milliseconds

      const tenDays = 14 * 24 * 60 * 60 * 1000; // 10 days in milliseconds

      if (timeDifference <= tenDays) {
        setShowReturnExchangeButton(true);
      } else {
        setShowReturnExchangeButton(false);
      }
    };

    // Check the button visibility on component mount
    checkReturnExchangeButtonVisibility();

    // Optionally, set an interval to check periodically
    const intervalId = setInterval(checkReturnExchangeButtonVisibility, 1000); // Check every second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [orderUpdatedTimestamp]);

  const formatedSchedualeDeliveryDate = (dateStr) => {
    const date = new Date(dateStr);
    // Extract day, month, and year
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = date.toLocaleString("default", { month: "long" }); // Gets full month name
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const isMoreThan24Hours = (timestamp) => {
    const givenTime = new Date(timestamp).getTime();
    const currentTime = new Date().getTime();

    const timeDifference = currentTime - givenTime;
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

    return timeDifference > twentyFourHoursInMilliseconds;
  };

  const totalAddonsCount = data?.cart[0]?.lensDetails?.reduce(
    (lensTotal, lens) => {
      return lensTotal + lens?.addons?.length;
    },
    0
  );
  const [openLensDetails, setOpenLensDetails] = useState(null);

  return (
    <div className="w-full bg-gray p-5 mb-5 flex flex-col">
      <div className="flex w-full">
        <div
          className="lg:min-w-[200px] lg:max-w-[200px] lg:min-h-[160px] lg:max-h-[160px]
    emd:min-w-[200px] emd:max-w-[200px] emd:min-h-[160px] emd:max-h-[160px]
    small:min-w-[120px] small:max-w-[120px] small:min-h-[100px] small:max-h-[100px] object-contain flex items-center justify-center overflow-hidden bg-white"
        >
          <img
            onClick={() =>
              navigate(
                data?.cart[0]?.slug
                  ? `/products/${data?.cart[0]?.slug}`
                  : `/products/${data?.cart[0]?.product_id}`
              )
            }
            src={
              data?.cart[0]?.images?.[0]?.length > 0
                ? data?.cart[0]?.images?.[0]?.replace(
                    "upload/",
                    "upload/if_w_gt_600/w_600/if_end/"
                  )
                : ""
            }
            className="lg:w-[180px] emd:w-[180px] small:w-[100px] cursor-pointer"
            alt=""
          />
        </div>
        <div className="lg:w-[calc(100%-200px)] emd:w-[calc(100%-200px)] small:w-[calc(100%-120px)] flex justify-between overflow-hidden">
          <div className="pl-4 flex flex-col">
            <div className="w-full flex flex-col">
              <div className="mont-bold mb-1 lg:text-base emd:text-base small:text-sm">
                Order #{data?.invoice}
              </div>
              <span
                onClick={() =>
                  navigate(
                    data?.cart[0]?.slug
                      ? `/products/${data?.cart[0]?.slug}`
                      : `/products/${data?.cart[0]?.product_id}`
                  )
                }
                className="mont-regular text-wrap lg:text-lg emd:text-lg small:text-sm cursor-pointer"
              >
                {data?.cart[0]?.title}
              </span>
              <span className="text-sm mt-3">
                Quantity : {data?.cart[0]?.quantity}
              </span>
              <span className="mont mt-3 mont-bold text-xs">Order ID</span>
              <span className="mont mt-1 text-wrap mont-bold text-xs">
                {data?._id}
              </span>
            </div>
          </div>
          {data?.cart[0]?.orderStatus === "Not Placed" ||
          (!showReturnExchangeButton &&
            data?.cart[0]?.orderStatus === "Delivered") ? (
            <div className="lg:flex emd:hidden small:hidden uppercase w-44 py-1.5 justify-end  text-red-400 rounded-none text-sm text-center">
              order failed
            </div>
          ) : (
            <div className="lg:flex emd:hidden small:hidden flex-col">
              {data?.cart[0]?.isAccessories !== true ? (
                <div className="lg:flex emd:hidden small:hidden flex-col">
                  <BTN
                    title={
                      data?.cart[0]?.orderStatus === "Placed" ||
                      data?.cart[0]?.orderStatus === "In Making" ||
                      data?.cart[0]?.orderStatus === "Prepared" ||
                      data?.cart[0]?.orderStatus === "Pending"
                        ? "Track Order"
                        : "Return"
                    }
                    bordered
                    to={
                      data?.cart[0]?.orderStatus === "Placed" ||
                      data?.cart[0]?.orderStatus === "In Making" ||
                      data?.cart[0]?.orderStatus === "Prepared" ||
                      data?.cart[0]?.orderStatus === "Pending"
                        ? `/track-order/${data?.orderId}/${data?.cart[0]?.sku}`
                        : `/return-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
                    }
                    className="uppercase w-44 py-1.5 px-5 rounded-none text-sm"
                    disabled={
                      data?.cart[0]?.orderStatus === "Returned" ||
                      data?.cart[0]?.orderStatus === "Return Initiated" ||
                      data?.cart[0]?.orderStatus === "Exchange Initiated" ||
                      data?.cart[0]?.orderStatus === "Cancelled" ||
                      data?.cart[0]?.orderStatus === "Exchanged"
                    }
                  />

                  <BTN
                    title={
                      data?.cart[0]?.orderStatus === "Placed" ||
                      data?.cart[0]?.orderStatus === "In Making" ||
                      data?.cart[0]?.orderStatus === "Prepared" ||
                      data?.cart[0]?.orderStatus === "Pending"
                        ? "Cancel Order"
                        : "Exchange"
                    }
                    bordered
                    to={
                      data?.cart[0]?.orderStatus === "Placed" ||
                      data?.cart[0]?.orderStatus === "In Making" ||
                      data?.cart[0]?.orderStatus === "Prepared" ||
                      data?.cart[0]?.orderStatus === "Pending"
                        ? `/cancel-order/${data?.orderId}/${data?.cart[0]?.product_id}`
                        : `/exchange-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
                    }
                    className="uppercase w-44 py-1.5 px-5 rounded-none text-sm lg:mt-4 emd:mt-0 small:mt-0"
                    disabled={
                      data?.cart[0]?.orderStatus === "Prepared" ||
                      data?.cart[0]?.orderStatus === "Returned" ||
                      data?.cart[0]?.orderStatus === "Return Initiated" ||
                      data?.cart[0]?.orderStatus === "Exchange Initiated" ||
                      data?.cart[0]?.orderStatus === "Cancelled" ||
                      data?.cart[0]?.orderStatus === "Exchanged" ||
                      (data?.cart[0]?.orderStatus === "Placed" &&
                        isMoreThan24Hours(data?.createdAt))
                    }
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>

      {data?.cart[0]?.lensDetails?.length > 0 && (
        <div className="w-full flex flex-col mt-4">
          <div className=" w-full flex flex-col">
            <div
              onClick={() =>
                setOpenLensDetails(
                  data?.cart[0]?.product_id !== openLensDetails
                    ? data?.cart[0]?.product_id
                    : null
                )
              }
              className="cursor-pointer w-full flex items-center justify-between select-none"
            >
              <div className="text-base mont-regular uppercase">
                Lens Details{" "}
                <span className="text-sm mont-bold">
                  <span className="mont-regular">( </span>
                  {data?.cart[0]?.lensDetails?.length}{" "}
                  <span className="mont-regular"> Lens</span>
                  <span className="mont-regular">
                    {totalAddonsCount > 0 && " + "}
                  </span>
                </span>
                {totalAddonsCount > 0 ? (
                  <span className="text-sm mont-bold text-primary">
                    {totalAddonsCount}
                    <span className="mont-regular text-primary"> Addons</span>
                  </span>
                ) : (
                  ""
                )}
                <span className="mont-regular"> )</span>
              </div>

              <img
                src={
                  data?.cart[0]?.product_id === openLensDetails
                    ? ARROW_KEYWORD_UP
                    : ARROW_KEYWORD_DOWN
                }
                alt=""
                className=""
              />
            </div>

            {data?.cart[0]?.product_id === openLensDetails &&
              data?.cart[0]?.lensDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex items-start justify-between mt-2 pb-2"
                  >
                    <div
                      onClick={() =>
                        setSelectedShowPrescription(item?.prescriptionDetail)
                      }
                      className="flex items-start"
                    >
                      <span>{index + 1}.</span>
                      <div className="text-base ms-2 cursor-pointer flex flex-col">
                        <span className="flex text-base">
                          {item.name + " Lens"} &nbsp;
                          <span className="text-nowrap whitespace-pre ">
                            (₹ {item.price})
                          </span>
                        </span>
                        {item?.addons?.map((addon, addonIndex) => {
                          return (
                            <span
                              key={addonIndex}
                              className="ms-2 text-light_secondary text-sm"
                            >
                              <span className="me-2 text-light_secondary">
                                {addonIndex + 1}.
                              </span>
                              {addon?.name} &nbsp;
                              <span className="text-nowrap whitespace-pre  text-light_secondary">
                                (₹ {addon.price})
                              </span>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {data?.cart[0]?.isReadingGlasses === true && (
        <div className="w-full flex flex-col mt-4">
          <div className=" w-full flex flex-col">
            <div
              onClick={() =>
                setOpenLensDetails(
                  data?.cart[0]?.product_id !== openLensDetails
                    ? data?.cart[0]?.product_id
                    : null
                )
              }
              className="cursor-pointer w-full flex items-center justify-between select-none"
            >
              <div className="text-base mont-regular uppercase">
                Power Details{" "}
              </div>
              <img
                src={
                  data?.cart[0]?.product_id === openLensDetails
                    ? ARROW_KEYWORD_UP
                    : ARROW_KEYWORD_DOWN
                }
                alt=""
                className=""
              />
            </div>

            {data?.cart[0]?.product_id === openLensDetails &&
              data?.cart[0]?.readingGlassesPower?.map((power, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex items-start justify-start mt-2"
                  >
                    <span className="flex text-base items-center w-full text-light_secondary">
                      Frame {index + 1} Power = &nbsp;
                      <span className="text-secondary text-base">{power}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <div className="w-full lg:flex emd:hidden small:hidden justify-end mt-2">
        {data?.deliveryDate &&
        (data?.cart[0]?.orderStatus === "Placed" ||
          data?.cart[0]?.orderStatus === "In Making" ||
          data?.cart[0]?.orderStatus === "Prepared") ? (
          <span className="text-base text-primary">
            Your Scheduled delivery date is{" "}
            {formatedSchedualeDeliveryDate(data?.deliveryDate)}
          </span>
        ) : (
          <span className="text-base text-primary">
            {data?.cart[0]?.orderStatus === "Placed" ||
            data?.cart[0]?.orderStatus === "In Making" ||
            data?.cart[0]?.orderStatus === "Prepared"
              ? `Order ${data?.cart[0]?.orderStatus} : Estimated delivery by ${formattedEstimatedDeliveryDate}`
              : data?.cart[0]?.orderStatus === "Delivered"
              ? `Order ${data?.cart[0]?.orderStatus}`
              : `Order ${data?.cart[0]?.orderStatus}`}
          </span>
        )}
      </div>

      {data?.cart[0]?.orderStatus === "Not Placed" ||
      (!showReturnExchangeButton &&
        data?.cart[0]?.orderStatus === "Delivered") ? (
        <div className="w-full lg:hidden emd:flex small:flex text-center uppercase pt-3 border-t border-dashed border-light_secondary justify-start text-red-400 rounded-none text-sm">
          order failed
        </div>
      ) : (
        <div className="border-t border-dashed border-light_secondary mt-2 pt-5 lg:hidden emd:flex small:flex flex-col justify-between">
          <div className="w-full mb-4">
            {data?.deliveryDate &&
            (data?.cart[0]?.orderStatus === "Placed" ||
              data?.cart[0]?.orderStatus === "In Making" ||
              data?.cart[0]?.orderStatus === "Prepared") ? (
              <span className="text-base text-primary">
                Your Scheduled delivery date is{" "}
                {formatedSchedualeDeliveryDate(data?.deliveryDate)}
              </span>
            ) : (
              <span className="text-base text-primary">
                {data?.cart[0]?.orderStatus === "Placed" ||
                data?.cart[0]?.orderStatus === "In Making" ||
                data?.cart[0]?.orderStatus === "Prepared"
                  ? `Order ${data?.cart[0]?.orderStatus} : Estimated delivery by ${formattedEstimatedDeliveryDate}`
                  : data?.cart[0]?.orderStatus === "Delivered"
                  ? `Order ${data?.cart[0]?.orderStatus}`
                  : `Order ${data?.cart[0]?.orderStatus}`}
              </span>
            )}
          </div>
          <div className="w-full flex items-center justify-between">
            <BTN
              title={
                data?.cart[0]?.orderStatus === "Placed" ||
                data?.cart[0]?.orderStatus === "In Making" ||
                data?.cart[0]?.orderStatus === "Prepared" ||
                data?.cart[0]?.orderStatus === "Pending"
                  ? "Track Order"
                  : "Return"
              }
              bordered
              to={
                data?.cart[0]?.orderStatus === "Placed" ||
                data?.cart[0]?.orderStatus === "In Making" ||
                data?.cart[0]?.orderStatus === "Prepared" ||
                data?.cart[0]?.orderStatus === "Pending"
                  ? `/track-order/${data?.orderId}/${data?.cart[0]?.sku}`
                  : `/return-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
              }
              className="uppercase w-fit py-1.5 emd:px-5 small:px-1.5 rounded-none emd:text-sm small:text-xs"
              disabled={
                data?.cart[0]?.orderStatus === "Returned" ||
                data?.cart[0]?.orderStatus === "Return Initiated" ||
                data?.cart[0]?.orderStatus === "Exchange Initiated" ||
                data?.cart[0]?.orderStatus === "Cancelled" ||
                data?.cart[0]?.orderStatus === "Exchanged"
              }
            />

            <BTN
              title={
                data?.cart[0]?.orderStatus === "Placed" ||
                data?.cart[0]?.orderStatus === "In Making" ||
                data?.cart[0]?.orderStatus === "Prepared" ||
                data?.cart[0]?.orderStatus === "Pending"
                  ? "Cancel Order"
                  : "Exchange"
              }
              bordered
              to={
                data?.cart[0]?.orderStatus === "Placed" ||
                data?.cart[0]?.orderStatus === "In Making" ||
                data?.cart[0]?.orderStatus === "Prepared" ||
                data?.cart[0]?.orderStatus === "Pending"
                  ? `/cancel-order/${data?.orderId}/${data?.cart[0]?.product_id}`
                  : `/exchange-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
              }
              className="uppercase w-fit py-1.5 emd:px-5 small:px-1.5 rounded-none emd:text-sm small:text-xs lg:mt-4 emd:mt-0 small:mt-0 ms-1"
              disabled={
                data?.cart[0]?.orderStatus === "Prepared" ||
                data?.cart[0]?.orderStatus === "Returned" ||
                data?.cart[0]?.orderStatus === "Return Initiated" ||
                data?.cart[0]?.orderStatus === "Exchange Initiated" ||
                data?.cart[0]?.orderStatus === "Cancelled" ||
                data?.cart[0]?.orderStatus === "Exchanged" ||
                (data?.cart[0]?.orderStatus === "Placed" &&
                  isMoreThan24Hours(data?.createdAt))
              }
            />
          </div>
        </div>
      )}

      {/* =================== PRESCRIPTION DETAILS MODEL =============== */}
      <MODEL
        setShowModel={() => setSelectedShowPrescription(null)}
        showModel={selectedShowPrescription !== null}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Prescription details</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setSelectedShowPrescription(null)}
            />
          </div>
          <div className="w-full my-5 px-5">
            <div className="xl:flex-row emd:flex-row small:flex-col fix_lg:flex-col flex items-center justify-between">
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 flex flex-col justify-evenly items-center xl:border-r emd:border-r small:border-b fix_lg:border-b xl:border-b-0 emd:border-b-0 small:border-r-0 fix_lg:border-r-0 border-light_secondary  xl:pr-3 fix_lg:pr-0 emd:pr-3 small:pr-0 xl:pb-0 emd:pb-0 small:pb-2 fix_lg:pb-2">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Left Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.leftEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {selectedShowPrescription?.leftEye?.left_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.leftEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.leftEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 lg:pl-3 fix_lg:pl-0 emd:pl-3 small:pl-0 flex flex-col justify-evenly items-center">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Right Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.rightEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {item?.leftEye?.right_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.rightEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.rightEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MODEL>
    </div>
  );
}

function HomeTryOnOrderCard({ data }) {
  const user = localStorage.getItem("user");
  const [allImagesToggle, setAllImagesToggle] = useState(null);

  // =============== HOME TRY-ON ORDER CANCEL ============
  const [cancelOrderData, setCancelOrderData] = useState(null);

  const [
    removeFromCustomerHomeTryOnOrderData,
    { isLoading, isSuccess, isError, error },
  ] = useRemoveFromCustomerHomeTryOnOrderDataMutation();

  const homeTryOnOrderCancelHandle = () => {
    removeFromCustomerHomeTryOnOrderData({
      user,
      body: { ...cancelOrderData, status: "Cancelled" },
    }).then((res) => {
      if (res?.data?.data?.message) {
        toast.success("Order Cancelled successfully");
        setCancelOrderData(null);
      }
      if (res?.data?.data?.error) {
        toast.error(res?.data?.data?.error);
      }
    });
  };

  return (
    <div className="w-full flex flex-col py-5 mb-5">
      <div className="w-full flex lg:flex-row emd:flex-col small:flex-col justify-between">
        <div className="lg:w-auto emd:w-full small:w-full grid xl:grid-flow-col lg:grid-flow-col emd:grid-cols-3 small:grid-cols-2 gap-4 items-center justify-center">
          {data?.products?.slice(0, 3)?.map((img, index) => {
            return (
              <div
                key={index}
                className={`  ${
                  index !== 0 ? "" : ""
                } relative lg:min-w-[200px] lg:max-w-[200px] lg:min-h-[160px] lg:max-h-[160px]
    emd:min-w-[200px] emd:max-w-[200px] emd:min-h-[160px] emd:max-h-[160px]
    small:min-w-[120px] small:max-w-[120px] small:min-h-[100px] small:max-h-[100px] object-contain flex items-center justify-center overflow-hidden bg-gray m-auto`}
              >
                {data?.products?.length > 3 && index === 2 && (
                  <div
                    onClick={() => setAllImagesToggle(data?.products)}
                    className="w-full h-full cursor-pointer absolute bg-black bg-opacity-15 text-black text-xl flex items-center justify-center"
                  >
                    + {data?.products?.length - 3} More...
                  </div>
                )}
                <img
                  src={
                    img?.images?.length > 0
                      ? img?.images[0]?.replace(
                          "upload/",
                          "upload/if_w_gt_600/w_600/if_end/"
                        )
                      : ""
                  }
                  className="lg:w-[180px] emd:w-[180px] small:w-[100px]"
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-col">
          <span className="mont mt-3 mont-bold text-xs">
            Inquiry #{data?.inquiryNo}
          </span>
          <div className="mt-5">
            <span className="text-sm mt-3">
              Total Frames : {data?.products?.length}
            </span>
          </div>
        </div>
      </div>

      <div className="w-full mt-4 border-t border-b border-light_secondary py-2 flex justify-between items-center">
        {data?.status === "Placed" || data?.status === "Dispatched" ? (
          <span className="text-base text-primary">
            Try at Home :{" "}
            {data?.deliveryDate + ", Between " + data?.deliveryTime}
          </span>
        ) : (
          <span className="text-base text-primary">Order {data?.status}</span>
        )}
        {data?.status === "Placed" && (
          <BTN
            bordered
            className={"w-fit h-fit px-2 py-1 text-sm"}
            title={"Cancel"}
            onP={() => setCancelOrderData(data)}
          />
        )}
      </div>

      {/* ============================== ALL FRAMES IMAGES LISTING ====================== */}

      <MODEL
        showModel={allImagesToggle !== null}
        setShowModel={() => setAllImagesToggle(null)}
        title="Images"
      >
        <div className="w-full min-h-[60dh] max-h-[60dvh] overflow-auto p-10 grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-x-4 gap-y-4 hide_scrollbar items-center">
          {data?.products?.map((img, index) => {
            return (
              <div
                key={index}
                className={`relative w-full h-44 object-contain flex items-center justify-center overflow-hidden bg-gray`}
              >
                <img
                  src={
                    img?.images?.length > 0
                      ? img?.images[0]?.replace(
                          "upload/",
                          "upload/if_w_gt_600/w_600/if_end/"
                        )
                      : ""
                  }
                  className="lg:w-[180px] emd:w-[180px] small:w-[100px]"
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </MODEL>

      {/* ============================== CANCEL ORDER ====================== */}
      <MODEL
        showModel={cancelOrderData !== null}
        setShowModel={() => setCancelOrderData(null)}
        title="Cancel Order"
        isDialogue={true}
      >
        <div className="w-full p-4 flex flex-col items-center border-t border-light_secondary">
          <span className="text-lg text-center my-2">
            Are you sure, You want to cancel this Home Try-on Order ?
          </span>
          <div className="flex items-center justify-evenly mt-6 w-full">
            <BTN
              title="No"
              onP={() => setCancelOrderData(null)}
              bordered
              className={"w-28 py-1 px-2"}
            />
            <BTN
              onP={() => homeTryOnOrderCancelHandle()}
              title="Yes"
              className={"w-28 py-1 px-2"}
            />
          </div>
        </div>
      </MODEL>
    </div>
  );
}

// ["Placed" , "Delivered" , "Returned" , "Return Initiated", "Exchanged", "Exchange Initiated", "Cancelled"]

// ["Placed" ,
//  "In Making" ,
//   "Prepared" ,
//    "Delivered" ,
//     "Returned" ,
//      "Return Initiated",
//       "Exchanged",
//        "Exchange Initiated",
//         "Cancelled"]

// ====================================

{
  /* <div className="w-full lg:flex emd:hidden small:hidden n flex-col mb-5  bg-gray p-8">
<div className="w-full lg:flex emd:hidden small:hidden items-start justify-between">
  <div className="w-1/3 flex flex-col items-center justify-center overflow-hidden">
    <img
      src={data?.cart[0]?.images?.[0].replace(
        "upload/",
        "upload/if_w_gt_300/w_300/if_end/"
      )}
      alt=""
      className="w-[250px] h-[150px] object-contain bg-gray"
    />
    {data?.cart[0]?.lensDetails && (
      <div className="flex flex-col items-center justify-center w-full">
        <div className="text-3xl pt-1 bg-[#dfdfe0] rounded-full min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] flex items-center justify-center text-center">
          +
        </div>
        <img
          src={data?.cart[0]?.lensDetails[0]?.image}
          alt=""
          className="min-w-[50px] max-w-[50px] mt-8 bg-gray"
        />
      </div>
    )}
  </div>
  <div className="flex flex-col items-start justify-between w-[64%] min-h-[150px] overflow-hidden">
    <div className="w-full flex items-start justify-center">
      <div className="w-full flex flex-col">
        <div className="mont-bold mb-1">Order #{data?.invoice}</div>
        <span className="text-lg font-semibold">
          {data?.cart[0]?.title}
        </span>
        <span className="text-sm">SKU : {data?.cart[0]?.sku}</span>
        <span className="text-sm">
          Quantity : {data?.cart[0]?.quantity}
        </span>
      </div>
      {data?.cart[0]?.orderStatus === "Not Placed" ||
      (!showReturnExchangeButton &&
        data?.cart[0]?.orderStatus === "Delivered") ? (
        <div className="flex text-center"></div>
      ) : (
        <div className="flex flex-col">
          <div className="mont mb-1 text-xs">Order ID</div>
          <div className="mont mb-1 text-xs">&nbsp;{data?._id}</div>
          <BTN
            title={
              data?.cart[0]?.orderStatus === "Placed" ||
              data?.cart[0]?.orderStatus === "In Making" ||
              data?.cart[0]?.orderStatus === "Prepared" ||
              data?.cart[0]?.orderStatus === "Pending"
                ? "Track Order"
                : "Return"
            }
            bordered
            to={
              data?.cart[0]?.orderStatus === "Placed" ||
              data?.cart[0]?.orderStatus === "In Making" ||
              data?.cart[0]?.orderStatus === "Prepared" ||
              data?.cart[0]?.orderStatus === "Pending"
                ? `/track-order/${data?.orderId}/${data?.cart[0]?.sku}`
                : `/return-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
            }
            className="uppercase w-44 py-1.5 px-5 rounded-none text-sm"
            disabled={
              data?.cart[0]?.orderStatus === "Returned" ||
              data?.cart[0]?.orderStatus === "Return Initiated" ||
              data?.cart[0]?.orderStatus === "Exchange Initiated" ||
              data?.cart[0]?.orderStatus === "Cancelled" ||
              data?.cart[0]?.orderStatus === "Exchanged"
            }
          />

          <BTN
            title={
              data?.cart[0]?.orderStatus === "Placed" ||
              data?.cart[0]?.orderStatus === "In Making" ||
              data?.cart[0]?.orderStatus === "Pending"
                ? "Cancel Order"
                : "Exchange"
            }
            bordered
            to={
              data?.cart[0]?.orderStatus === "Placed" ||
              data?.cart[0]?.orderStatus === "In Making" ||
              data?.cart[0]?.orderStatus === "Prepared" ||
              data?.cart[0]?.orderStatus === "Pending"
                ? `/cancel-order/${data?.orderId}/${data?.cart[0]?.product_id}`
                : `/exchange-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
            }
            className="uppercase w-44 py-1.5 px-5 rounded-none text-sm lg:mt-4 emd:mt-0 small:mt-0"
            disabled={
              data?.cart[0]?.orderStatus === "Returned" ||
              data?.cart[0]?.orderStatus === "Return Initiated" ||
              data?.cart[0]?.orderStatus === "Exchange Initiated" ||
              data?.cart[0]?.orderStatus === "Cancelled" ||
              data?.cart[0]?.orderStatus === "Exchanged" ||
              (data?.cart[0]?.orderStatus === "Placed" &&
                isMoreThan24Hours(data?.createdAt))
            }
          />
        </div>
      )}
    </div>
    <div className="w-full">
      {data?.cart[0]?.lensDetails && (
        <div className="mt-5 flex flex-col">
          <span className="text-lg font-semibold">Lens Details :</span>
          {data?.cart[0]?.lensDetails?.map((item, index) => {
            return (
              <div key={index} className="flex mt-2 ms-3">
                <span className="text-base">{index + 1}.&nbsp;</span>
                <div className="flex flex-col">
                  <span className="text-base font-semibold">
                    {item?.name}{" "}
                    <span className="ms-1 text-base font-semibold">{`(₹ ${item?.price})`}</span>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="w-full border-light_secondary border-t pt-4 mt-4">
        {data?.deliveryDate &&
        (data?.cart[0]?.orderStatus === "Placed" ||
          data?.cart[0]?.orderStatus === "In Making" ||
          data?.cart[0]?.orderStatus === "Prepared") ? (
          <span className="text-base text-primary">
            Your Scheduled delivery date is{" "}
            {formatedSchedualeDeliveryDate(data?.deliveryDate)}
          </span>
        ) : (
          <span className="text-base text-primary">
            {data?.cart[0]?.orderStatus === "Placed" ||
            data?.cart[0]?.orderStatus === "In Making" ||
            data?.cart[0]?.orderStatus === "Prepared"
              ? `Order ${data?.cart[0]?.orderStatus} : Estimated delivery by ${formattedEstimatedDeliveryDate}`
              : data?.cart[0]?.orderStatus === "Delivered"
              ? `Order ${data?.cart[0]?.orderStatus}`
              : `Order ${data?.cart[0]?.orderStatus}`}
          </span>
        )}
      </div>
    </div>
  </div>
</div>
</div> */
}

// ================================================= mobile ui ===================================================

{
  /* <div className="w-full mt-5 lg:hidden emd:flex small:flex items-start flex-col justify-between  bg-gray p-8">
<div className="w-full flex flex-col">
  <div className="w-full flex emd:flex-row small:flex-col items-center justify-center overflow-hidden">
    <img
      src={data?.cart[0]?.images?.[0].replace(
        "upload/",
        "upload/if_w_gt_300/w_300/if_end/"
      )}
      alt=""
      className="w-[250px] emd:h-[150px] small:h-[100px] object-contain"
    />
    {data?.cart[0]?.lensDetails && (
      <div className="flex emd:flex-row small:flex-col items-center justify-start emd:ms-5 small:ms-0">
        <div className="emd:text-3xl small:text-xl bg-[#dfdfe0] rounded-full emd:min-w-[30px] emd:max-w-[30px] emd:min-h-[30px] emd:max-h-[30px] small:min-w-[25px] small:max-w-[25px] small:min-h-[25px] small:max-h-[25px] flex items-center justify-center text-center">
          +
        </div>
        <img
          src={data?.cart[0]?.lensDetails[0]?.image}
          alt=""
          className="min-w-[50px] max-w-[50px] emd:ms-5 small:mt-4 emd:mt-0 small:ms-0 bg-gray"
        />
      </div>
    )}
  </div>
  <div className="w-full emd:mt-4 small:mt-6 flex flex-col">
    <span className="text-lg font-semibold">
      {data?.cart[0]?.title}
    </span>
    <span className="text-sm">SKU : {data?.cart[0]?.sku}</span>
    <span className="text-sm">
      Quantity : {data?.cart[0]?.quantity}
    </span>
  </div>
  <div className="w-full">
    {data?.cart[0]?.lensDetails && (
      <div className="mt-4 flex flex-col">
        <span className="text-lg font-semibold">Lens Details :</span>
        {data?.cart[0]?.lensDetails?.map((item, index) => {
          return (
            <div key={index} className="flex mt-2 ms-3">
              <span className="text-base">{index + 1}.&nbsp;</span>
              <div className="flex flex-col">
                <span className="text-base font-semibold">
                  {item?.name}{" "}
                  <span className="ms-1 text-base font-semibold">{`(₹ ${item?.price})`}</span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    )}
  </div>
</div>
<div className="w-full border-light_secondary border-t mt-4 pt-4">
  {data?.deliveryDate &&
  (data?.cart[0]?.orderStatus === "Placed" ||
    data?.cart[0]?.orderStatus === "In Making" ||
    data?.cart[0]?.orderStatus === "Prepared") ? (
    <span className="text-base text-primary">
      Your Scheduled delivery date is{" "}
      {formatedSchedualeDeliveryDate(data?.deliveryDate)}
    </span>
  ) : (
    <span className="text-base text-primary">
      {data?.cart[0]?.orderStatus === "Placed" ||
      data?.cart[0]?.orderStatus === "In Making" ||
      data?.cart[0]?.orderStatus === "Prepared"
        ? `Order ${data?.cart[0]?.orderStatus} : Estimated delivery by ${formattedEstimatedDeliveryDate}`
        : data?.cart[0]?.orderStatus === "Delivered"
        ? `Order ${data?.cart[0]?.orderStatus}`
        : `Order ${data?.cart[0]?.orderStatus}`}
    </span>
  )}
</div>
{data?.cart[0]?.orderStatus === "Not Placed" ||
(!showReturnExchangeButton &&
  data?.cart[0]?.orderStatus === "Delivered") ? (
  <div className="flex text-center my-5"></div>
) : (
  <div className="w-full mt-5 flex justify-between items-center">
    <BTN
      title={
        data?.cart[0]?.orderStatus === "Placed" ||
        data?.cart[0]?.orderStatus === "In Making" ||
        data?.cart[0]?.orderStatus === "Prepared" ||
        data?.cart[0]?.orderStatus === "Pending"
          ? "Track Order"
          : "Return"
      }
      bordered
      to={
        data?.cart[0]?.orderStatus === "Placed" ||
        data?.cart[0]?.orderStatus === "In Making" ||
        data?.cart[0]?.orderStatus === "Prepared" ||
        data?.cart[0]?.orderStatus === "Pending"
          ? `/track-order/${data?.orderId}/${data?.cart[0]?.sku}`
          : `/return-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
      }
      className="uppercase w-fit py-1.5 emd:px-5 small:px-1.5 rounded-none emd:text-sm small:text-xs"
      disabled={
        data?.cart[0]?.orderStatus === "Returned" ||
        data?.cart[0]?.orderStatus === "Return Initiated" ||
        data?.cart[0]?.orderStatus === "Exchange Initiated" ||
        data?.cart[0]?.orderStatus === "Cancelled" ||
        data?.cart[0]?.orderStatus === "Exchanged"
      }
    />

    <BTN
      title={
        data?.cart[0]?.orderStatus === "Placed" ||
        data?.cart[0]?.orderStatus === "In Making" ||
        data?.cart[0]?.orderStatus === "Pending"
          ? "Cancel Order"
          : "Exchange"
      }
      bordered
      to={
        data?.cart[0]?.orderStatus === "Placed" ||
        data?.cart[0]?.orderStatus === "In Making" ||
        data?.cart[0]?.orderStatus === "Prepared" ||
        data?.cart[0]?.orderStatus === "Pending"
          ? `/cancel-order/${data?.orderId}/${data?.cart[0]?.product_id}`
          : `/exchange-order/${data?.orderId}/${data?.cart[0]?.product_id}/${data?.cart[0]?.sku}`
      }
      className="uppercase w-fit py-1.5 emd:px-5 small:px-1.5 rounded-none emd:text-sm small:text-xs lg:mt-4 emd:mt-0 small:mt-0 ms-1"
      disabled={
        data?.cart[0]?.orderStatus === "Returned" ||
        data?.cart[0]?.orderStatus === "Return Initiated" ||
        data?.cart[0]?.orderStatus === "Exchange Initiated" ||
        data?.cart[0]?.orderStatus === "Cancelled" ||
        data?.cart[0]?.orderStatus === "Exchanged" ||
        (data?.cart[0]?.orderStatus === "Placed" &&
          isMoreThan24Hours(data?.createdAt))
      }
    />
  </div>
)}
</div> */
}
