import React from "react";

const RatingCard = ({ rating, totalRating, mainClassName }) => {
  return (
    <div
      className={` ${
        mainClassName ? `${mainClassName}` : "px-2 py-1"
      } flex rounded-full space-x-1 items-center bg-white border-[0.5px] border-[#444444]`}
    >
      <span className="lg:text-sm emd:text-[12px] small:text-[8px]">
        {parseInt(rating).toFixed(1) || 0}
      </span>
      <button
        type="button"
        className={` text-primary lg:text-[10px] emd:text-[10px] small:text-[8px]`}
        style={{ fontSize: "8px" }}
      >
        ★
      </button>
      <span className="lg:flex emd:flex small:hidden lg:text-xs emd:text-[10px] small:text-[8px] text-[#9B9797]">
        |
      </span>
      <span className="lg:flex emd:flex small:hidden lg:text-sm emd:text-[12px] small:text-[10px] text-[#9B9797]">
        ({totalRating > 99 ? "99+" : totalRating || 0})
      </span>
    </div>
  );
};

export default RatingCard;
