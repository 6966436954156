import React, { useEffect, useState } from "react";
import {
  CLOSE_IC,
  CLOSE_IC_18,
  DELETE_IC,
  LIKED_IC,
  UNLIKE_IC,
} from "../../../assets";
import {
  useAddToCustomerBagMutation,
  useAddToWishlistMutation,
  useRemoveFromCustomerBagMutation,
  useRemoveFromWishlistMutation,
} from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import useGetUser from "../../../hooks/useGetUser";
import { removeFromMyBag, setMyBag } from "../../../redux/slices/myBagSlice";
import { useNavigate } from "react-router-dom";

const ShoppingBagProductCard = ({ product, setLensStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useGetUser();
  const myBagItems = useSelector((state) => state.myBagData.items);

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);
  const [addToWishlist] = useAddToWishlistMutation();
  const [removeFromWishlist] = useRemoveFromWishlistMutation();

  const handleWishlist = () => {
    if (!wishListed) {
      if (!loggedIn) {
        toast.error("Please log in to add into Wishlist");
        return;
      }
      if (!product._id) {
        return;
      }
      addToWishlist({
        body: {
          product_id: product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(true);
          toast.success("Added to Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    } else {
      removeFromWishlist({
        body: {
          product_id: product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(false);
          toast.success("Removed from Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    }
  };

  // ===================== REMOVE FROM BAG HANDLE ==================

  const [removeFromCustomerBag] = useRemoveFromCustomerBagMutation();

  const removeToMyBagHandel = (isAllDelete) => {
    if (loggedIn) {
      removeFromCustomerBag({
        body: {
          product_id: product?._id,
          decrement: false,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${product?.name} removed from bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(removeFromMyBag({ id: product?._id, isAllDelete }));
    }
  };

  // estimated delievery date is the date 7 days after todays date in Dec 20, 2023 format
  const estimatedDeliveryDate = new Date();
  estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate() + 7);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedEstimatedDeliveryDate =
    estimatedDeliveryDate.toLocaleDateString("en-US", options);
  const [wishListed, setWishListed] = useState(false);
  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (wishlistData) {
      if (product._id) {
        const found = wishlistData.find(
          (element) => element._id === product._id
        );
        if (found) {
          setWishListed(true);
        } else {
          setWishListed(false);
        }
      }
    }
  }, [wishlistData]);

  // ====================== REMOVE LENS HANDLE ================================

  const removeLensFromMyBag = (productId, lensId, prescriptionId) => {
    if (loggedIn) {
      removeFromCustomerBag({
        body: {
          product_id: productId,
          lens_id: lensId,
          prescription_id: prescriptionId,
          decrement: false,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens Removed`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    }
    // else {
    //   let newBagDataWithRemoveLens = myBagItems.map((item) => {
    //     if (item.product_id === productId) {
    //       return {
    //         ...item,
    //         lensDetails: null,
    //       };
    //     }
    //     return item;
    //   });
    //   dispatch(setMyBag(newBagDataWithRemoveLens));

    //   toast.success(`Lens Removed`);
    // }
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-col border-b border-secondary mt-[25px] pb-[20px]">
        <div className="flex">
          <div className="product_img_div p-2">
            <img
              src={product.image?.[0]?.replace(
                "upload/",
                "upload/if_w_gt_400/w_400/if_end/"
              )}
              alt=""
              className="product_img"
            />
          </div>

          <div className="lg:min-w-[calc(100%-400px)] lg:max-w-[calc(100%-400px)] emd:min-w-[calc(100%-200px)] emd:max-w-[calc(100%-200px)] small:min-w-[calc(100%-130px)] small:max-w-[calc(100%-130px] flex flex-col items-start justify-between ps-3 lg:pe-3 emd:pe-0 small:pe-0">
            <div className="w-full flex justify-between flex-col ">
              <div className="w-full flex items-start justify-between">
                <div className="w-full flex flex-col justify-between">
                  <span className="product_card_title lg:text-[20px] emd:text-[16px] small:text-[16px]">
                    {product.name}
                  </span>
                  <span className="product_card_title lg:text-[20px] emd:text-[16px] small:text-[16px]">
                    ₹{product.prices.price * product.quantity}
                  </span>
                </div>
                <span className="ms-3">
                  {wishListed ? (
                    <img src={LIKED_IC} alt="" onClick={handleWishlist} />
                  ) : (
                    <img src={UNLIKE_IC} alt="" onClick={handleWishlist} />
                  )}
                </span>
              </div>
              <span className="product_card_desc lg:text-[15px] emd:text-[14px] small:text-[10px] lg:mt-[10px] emd:mt-[4px] small:mt-[4px]">
                SKU-{product.sku}
              </span>
              <span className="product_card_desc lg:text-[15px] emd:text-[14px] small:text-[10px] lg:mt-[10px] emd:mt-[4px] small:mt-[4px]">
                Quantity-{product.quantity}
              </span>
            </div>
            <div className="w-full block">
              <div
                className={` justify-end w-full flex items-end pt-[20px]`}
                // className={` ${
                //   product?.lensDetails ? "justify-between" : "justify-end"
                // } w-full flex items-end pt-[20px]`}
              >
                {/* <div className="w-full flex items-end justify-between py-[20px] lg:border-b border-light_secondary"> */}

                {/* {product?.lensDetails && (
                  <button
                    onClick={() => {
                      navigate(user?.customer ? "/lens" : "/login", {
                        state: { id: product?._id },
                      });
                      setLensStep(1);
                    }}
                    className="border-secondary border py-1 px-2 font-bold text-nowrap whitespace-pre  lg:text-base emd:text-base small:text-[12px] text-secondary lg:mt-0 emd:mt-0 small:mt-1"
                  >
                    Add Lens
                  </button>
                )} */}
                <img
                  src={DELETE_IC}
                  alt=""
                  className="delete_ic"
                  onClick={() => removeToMyBagHandel(true)}
                />
              </div>
              {/* <div className="delivery_time mt-3">
              Estimated Delivery: {formattedEstimatedDeliveryDate}
            </div> */}
            </div>
          </div>
        </div>
        {/* <div className="w-full lg:hidden emd:block small:block border-b pb-[10px] border-secondary"> */}
        {/* <div className="w-full flex items-center justify-between py-[0px] lg:border-b border-light_secondary mt-2">
            <div className="flex flex-col items-start">
              <button
                onClick={() => {
                  navigate("/lens", { state: { id: product?._id } });
                  setLensStep(1);
                }}
                className="bg-primary py-1 px-2 font-bold text-nowrap whitespace-pre  lg:text-base emd:text-base small:text-[12px] text-white"
              >
                Add Lens
              </button>
            </div>
            <img
              src={DELETE_IC}
              alt=""
              className="delete_ic"
              onClick={removeToMyBagHandel}
            />
          </div> */}
        {/* <div className="delivery_time mt-2">
          Estimated Delivery: {formattedEstimatedDeliveryDate}
        </div> */}
        {/* </div> */}
        {/* {product?.lensDetails && (
          <div className="w-full flex flex-col">
            <span className="mx-16 text-2xl">+</span>
            <div className=" w-full flex flex-col">
              <div className="flex items-center w-fit py-2 bg-gray px-2">
                <span className="text-base">
                  {product?.lensDetails?.name + " Lens"}
                </span>
                <img
                  src={CLOSE_IC}
                  alt=""
                  className="w-5 ms-5 cursor-pointer"
                  onClick={() => removeLensHandle(product?._id)}
                />
              </div>
            </div>
            {<span className="text-sm text-red-400 mt-1">error msg</span>}
          </div>
        )} */}
        {product?.lensDetails?.length > 0 && (
          <div className="w-full flex flex-col mt-2">
            <span className="lg:ml-44 emd:ml-20 small:mx-16 text-2xl">+</span>
            <div className=" w-full flex flex-col">
              <div className="py-1 text-base uppercase">Lens Details :</div>
              {product?.lensDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center pb-2 "
                  >
                    <div className="flex items-center">
                      <img
                        src={item?.image}
                        alt=""
                        className="min-w-[50px] max-w-[50px] bg-gray"
                      />
                      <span className="text-base ms-3">
                        {item?.name + " Lens"} &nbsp;
                        <span className="text-nowrap whitespace-pre ">(₹ {item?.price})</span>
                      </span>
                    </div>
                    <img
                      src={CLOSE_IC}
                      alt=""
                      className="w-5 ms-5 cursor-pointer"
                      onClick={() =>
                        removeLensFromMyBag(
                          product?._id,
                          item?._id,
                          item?.prescriptionDetail?._id
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
            {/* {<span className="text-sm text-red-400 mt-1">error msg</span>} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingBagProductCard;
