import React, { useEffect, useState } from "react";
import "./Sidebar.scss";
import {
  ARROW_KEYWORD_LEFT,
  ARROW_KEYWORD_LEFT_PRIMARY,
  ARROW_KEYWORD_RIGHT,
  CLOSE_BLACK_IC,
  CLOSE_IC,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({
  sidebarToggle,
  setSidebarToggle,
  headerLabelsData,
  headerCategoriesData,
}) => {
  const navigate = useNavigate();
  const wishlistData = useSelector((state) => state.wishlist.data);
  const [showBrowse, setShowBrowse] = useState(false);
  const [menuStack, setMenuStack] = useState([]); // Stack to keep track of navigation path

  const sortCategories = (data) => {
    if (data) {
      return data
        .map((category) => ({
          ...category,
          sortOrder: parseInt(category.sortOrder, 10), // Convert sortOrder to a number
          children: sortCategories(category.children), // Recursively sort children
        }))
        .sort((a, b) => a.sortOrder - b.sortOrder); // Sort current level by sortOrder
    }
  };

  useEffect(() => {
    if (headerCategoriesData) {
      const sorted = sortCategories(headerCategoriesData);
      setMenuStack([{ title: "Main Menu", items: sorted }]);
    }
  }, [headerCategoriesData, showBrowse]);

  const handleCategoryClick = (category) => {
    if (category.children && category.children.length > 0) {
      setMenuStack((prev) => [
        ...prev,
        { title: category.name.en, items: category.children },
      ]);
    }
  };

  const handleBackClick = () => {
    if (menuStack.length > 1) {
      setMenuStack((prev) => prev.slice(0, -1)); // Go back one level
    } else {
      setShowBrowse(false); // Go back to the main menu
    }
  };

  const handleBrowseClick = () => {
    setShowBrowse(true);
  };

  const currentMenu = menuStack[menuStack.length - 1]; // Current menu level

  return (
    <div className="sidebar_main z-999999">
      <div className="container-fluid mt-3">
        <div className={`sidebar ${sidebarToggle ? "active" : ""}`}>
          <div className="sidebar_body">
            <div className="logo_div">
              <img src="/Netre-logo.svg" alt="" className="logo" />
              <img
                src={CLOSE_IC}
                alt=""
                className="close cursor-pointer"
                onClick={() => setSidebarToggle(false)}
              />
            </div>
            {!showBrowse ? (
              <div className="w-full flex flex-col">
                <div
                  onClick={() => {
                    navigate(`/favourite`);
                    setSidebarToggle(false);
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="items mont-regular"
                >
                  Wish list ({" "}
                  {wishlistData?.length > 9 ? "9+" : wishlistData?.length} )
                </div>
                {/* <div
                  onClick={() => {
                    navigate(`/aboutus`);
                    setSidebarToggle(false);
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="items mont-regular color2"
                >
                  About Us
                </div> */}
                <div
                  className="w-full h-fit relative flex items-center justify-between"
                  onClick={handleBrowseClick}
                >
                  <div className="items mont-regular flex items-center justify-between">
                    <span>Browse Collection</span>
                    <div className="text-sm rounded-full bg-gray w-[20px] h-[20px] flex items-center justify-center cursor-pointer">
                      <img
                        src={ARROW_KEYWORD_RIGHT}
                        alt=""
                        className="m-auto"
                      />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate(`/search/price=best-selling`);
                    setSidebarToggle(false);
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="items mont-regular color2"
                >
                  Best Sellers
                </div>
                <div
                  onClick={() => {
                    navigate(`/home-tryon`);
                    setSidebarToggle(false);
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="items mont-regular color2"
                >
                  Home Try on
                </div>
                <div
                  onClick={() => {
                    navigate(`/lens-only`);
                    setSidebarToggle(false);
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="items mont-regular"
                >
                  Lens Only
                </div>
                <div
                  onClick={() => {
                    navigate(`/search/price=in-offer`);
                    setSidebarToggle(false);
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="items mont-regular"
                >
                  Offers
                </div>
                <div
                  onClick={() => {
                    navigate(`/become-retailer`);
                    setSidebarToggle(false);
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="items mont-regular color2"
                >
                  For Business
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col">
                {/* Back Button and Current Category Title */}
                <div
                  onClick={handleBackClick}
                  className="w-full flex items-center px-4 my-2 cursor-pointer"
                >
                  <div className="text-primary text-xl">
                    <img src={ARROW_KEYWORD_LEFT_PRIMARY} alt="" />
                  </div>
                  <span className="ms-2 text-xl text-primary">
                    {menuStack?.length > 1
                      ? currentMenu?.title
                      : "Browse Collection"}
                  </span>
                </div>

                <div className="w-full flex flex-col">
                  {currentMenu?.items.map((item) => (
                    <div
                      key={item._id}
                      className="w-full flex items-center justify-between py-2 px-4 mb-2"
                    >
                      <div
                        onClick={() => {
                          navigate(
                            item?.slug
                              ? `/categories/${item?.slug}`
                              : `/categories/${item?._id}`
                          );
                          setSidebarToggle(false);
                          setMenuStack([]);
                          setShowBrowse(false);
                          document.body.style.overflow = "auto";
                          document.body.style.height = "auto";
                        }}
                        className="text-base hover:text-primary cursor-pointer"
                      >
                        {item.name.en}
                      </div>
                      {item.children && item.children.length > 0 && (
                        <div
                          onClick={() => handleCategoryClick(item)}
                          className="text-sm rounded-full bg-gray w-[20px] h-[20px] flex items-center justify-center cursor-pointer"
                        >
                          <img
                            src={ARROW_KEYWORD_RIGHT}
                            alt=""
                            className="m-auto"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`sidebar_overlay ${
            sidebarToggle === true ? "active" : ""
          }`}
          onClick={() => {
            setSidebarToggle(false);
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
            setMenuStack([]);
            setShowBrowse(false);
          }}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;

// import React, { useEffect, useState } from "react";
// import "./Sidebar.scss";
// import { CLOSE_BLACK_IC } from "../../assets";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

// const Sidebar = ({
//   sidebarToggle,
//   setSidebarToggle,
//   headerLabelsData,
//   headerCategoriesData,
// }) => {
//   const navigate = useNavigate();
//   const wishlistData = useSelector((state) => state.wishlist.data);
//   const [showBrowse, setShowBrowse] = useState(false);
//   const [categoryData, setCategoryData] = useState([]);
//   const [menuStack, setMenuStack] = useState([]); // Stack to keep track of navigation path

//   const sortCategories = (data) => {
//     if (data) {
//       return data
//         .map((category) => ({
//           ...category,
//           sortOrder: parseInt(category.sortOrder, 10), // Convert sortOrder to a number
//           children: sortCategories(category.children), // Recursively sort children
//         }))
//         .sort((a, b) => a.sortOrder - b.sortOrder); // Sort current level by sortOrder
//     }
//   };

//   useEffect(() => {
//     if (headerCategoriesData) {
//       const sorted = sortCategories(headerCategoriesData);
//       setMenuStack([{ title: "Main Menu", items: sorted }]);
//       // setCategoryData(sorted);
//     }
//   }, [headerCategoriesData]);

//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedSubCategory, setSelectedSubCategory] = useState([]);
//   const [selectedTitle, setSelectedTitle] = useState([]);
//   const [selectedCategoryArray, setSelectedCategoryArray] = useState([]);

//   // Handle click to go deeper into the menu
//   const handleCategoryClick = (category) => {
//     if (category.children && category.children.length > 0) {
//       setMenuStack((prev) => [
//         ...prev,
//         { title: category.name.en, items: category.children },
//       ]);
//     }
//   };

//   // Handle back button click
//   const handleBackClick = () => {
//     setMenuStack((prev) => prev.slice(0, -1)); // Remove the last item to go up one level
//   };

//   const currentMenu = menuStack[menuStack.length - 1]; // Current menu level

//   return (
//     <div className="sidebar_main z-999999">
//       <div className="container-fluid mt-3">
//         <div className={`sidebar ${sidebarToggle ? "active" : ""}`}>
//           <div className="sidebar_body">
//             <div className="logo_div">
//               <img src="/Netre-logo.svg" alt="" className="logo" />
//               <img
//                 src={CLOSE_BLACK_IC}
//                 alt=""
//                 className="close cursor-pointer"
//                 onClick={() => setSidebarToggle(false)}
//               />
//             </div>
//             {!showBrowse ? (
//               <div className="w-full flex flex-col">
//                 <div
//                   onClick={() => {
//                     navigate(`/favourite`);
//                     setSidebarToggle(false);
//                     document.body.style.overflow = "auto";
//                     document.body.style.height = "auto";
//                   }}
//                   className="items mont-regular"
//                 >
//                   Wish list ({" "}
//                   {wishlistData?.length > 9 ? "9+" : wishlistData?.length} )
//                 </div>
//                 <div
//                   onClick={() => {
//                     navigate(`/aboutus`);
//                     setSidebarToggle(false);
//                     document.body.style.overflow = "auto";
//                     document.body.style.height = "auto";
//                   }}
//                   className="items mont-regular color2"
//                 >
//                   About Us
//                 </div>
//                 <div
//                   className="w-full h-fit relative"
//                   onClick={() => {
//                     setShowBrowse(true);
//                   }}
//                 >
//                   <div
//                     onClick={() => {
//                       setShowBrowse(true);
//                       setSelectedCategory(
//                         categoryData?.length > 0 ? categoryData : []
//                       );
//                       setSelectedTitle(
//                         categoryData?.length > 0 ? categoryData[0] : []
//                       );
//                       setSelectedCategoryArray([
//                         categoryData?.length > 0 ? categoryData[0] : {},
//                       ]);
//                     }}
//                     className="items mont-regular"
//                   >
//                     Browse Collection
//                   </div>
//                 </div>
//                 <div
//                   onClick={() => {
//                     navigate(`/home-tryon`);
//                     setSidebarToggle(false);
//                     document.body.style.overflow = "auto";
//                     document.body.style.height = "auto";
//                   }}
//                   className="items mont-regular color2"
//                 >
//                   Home Try on
//                 </div>
//                 <div
//                   onClick={() => {
//                     navigate(`/search`);
//                     setSidebarToggle(false);
//                     document.body.style.overflow = "auto";
//                     document.body.style.height = "auto";
//                   }}
//                   className="items mont-regular"
//                 >
//                   Offers
//                 </div>
//                 <div
//                   onClick={() => {
//                     navigate(`/become-retailer`);
//                     setSidebarToggle(false);
//                     document.body.style.overflow = "auto";
//                     document.body.style.height = "auto";
//                   }}
//                   className="items mont-regular color2"
//                 >
//                   For Business
//                 </div>
//               </div>
//             ) : (
//               <div className="w-full flex flex-col">
//                 {/* Back Button and Current Category Title */}
//                 {menuStack.length > 1 && (
//                   <div
//                     onClick={handleBackClick}
//                     className="w-full flex items-center px-4 my-2 cursor-pointer"
//                   >
//                     <div className="text-primary text-xl">⟨</div>
//                     <span className="ms-2 text-xl text-primary">
//                       {currentMenu.title}
//                     </span>
//                   </div>
//                 )}

//                 <div className="w-full flex flex-col">
//                   {currentMenu.items.map((item) => (
//                     <div
//                       key={item._id}
//                       onClick={() => handleCategoryClick(item)}
//                       className="hover:bg-gray hover:text-primary w-full cursor-pointer flex items-center justify-between py-2 px-4 space-y-2"
//                     >
//                       <div className="text-base">{item.name.en}</div>
//                       {item.children && item.children.length > 0 && (
//                         <div className="ms-2 text-base">⟩</div>
//                       )}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//         <div
//           className={`sidebar_overlay ${
//             sidebarToggle === true ? "active" : ""
//           }`}
//           onClick={() => {
//             setSidebarToggle(false);
//             document.body.style.overflow = "auto";
//             document.body.style.height = "auto";
//           }}
//         ></div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;
