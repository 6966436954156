import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Account from "./Account";
import OrderHistory from "./OrderHistory";
import Coupons from "./Coupons";
import Payment from "./Payment";
import ReturnOrder from "./ReturnOrder";
import CancelOrder from "./CancelOrder";
import ExchangeOrder from "./ExchangeOrder";
import TrackOrder from "./TrackOrder";
import { CALL_IC, CLOSE_BLACK_IC, MAIL_IC } from "../../../assets";
import useGetUser from "../../../hooks/useGetUser";
import { useDispatch } from "react-redux";
import { setMyBag } from "../../../redux/slices/myBagSlice";
import { setWishlist } from "../../../redux/slices/wishlistSlice";
import { setHomeTryOn } from "../../../redux/slices/homeTryonSlice";
import MODEL from "../../Master/MODEL";
import BTN from "../../Master/BTN";

const ProfileDetails = () => {
  let user = localStorage.getItem("user");
  const navigate = useNavigate();
  const { hash } = useLocation();
  const dispatch = useDispatch();

  const [logoutModelToggle, setLogoutModelToggle] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem("loginModelClosed");
    localStorage.removeItem("user");
    localStorage.removeItem("isServiceable");
    dispatch(setMyBag([]));
    dispatch(setWishlist([]));
    dispatch(setHomeTryOn([]));
    navigate("/login");
  };

  useEffect(() => {
    if (!user) {
      localStorage.removeItem("user");
      localStorage.removeItem("isServiceable");
      navigate("/login");
    }
  }, [user]);

  return (
    <div className="w-full flex lg:flex-row emd:flex-col small:flex-col">
      <div className="lg:w-[20%] h-fit edm:hidden small:hidden py-6 px-6 bg-gray lg:flex flex-col items-start justify-start">
        <a
          href="#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Your Account
        </a>
        <a
          href="#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Order History
        </a>
        {/* <a
          href="#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Coupons
        </a> */}
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
          onClick={() => setLogoutModelToggle(true)}
        >
          Logout
        </span>

        <span className="text-xl mt-10">Need Assistance ?</span>
        <div className="flex flex-col items-start">
          <Link
            to={"/contact-us"}
            className="underline mt-3 cursor-pointer select-none"
          >
            Contact Us
          </Link>
          <div className="flex items-center mt-3">
            <div className="material-symbols-outlined ">
              <img src={CALL_IC} alt="" className="w-6" />
            </div>
            <a href={`tel:18002028223`} className="ms-2 cursor-pointer">18002028223 </a>
          </div>
          <div className="flex items-center">
            <div className="material-symbols-outlined ">
              <img src={MAIL_IC} alt="" className="w-6" />
            </div>
            <span className="ms-2">
              <a href="mailto:info@netre.in" className="email-link">
                info@netre.in
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray p-4 small:flex emd:flex lg:hidden items-center justify-start overflow-x-auto overflow-y-hidden text-nowrap whitespace-pre  hide_scrollbar">
        <a
          href="#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  select-none cursor-pointer`}
        >
          Your Account
        </a>
        <a
          href="#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Order History
        </a>
        {/* <a
          href="#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Coupons
        </a> */}
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
          onClick={() => setLogoutModelToggle(true)}
        >
          Logout
        </span>
      </div>

      <div className="lg:w-[80%] emd:w-full small:w-full lg:p-6 emd:p-4 small:p-4">
        {hash === "#your-account" ? (
          <Account />
        ) : hash === "#order-history" ? (
          <OrderHistory />
        ) : (
          (hash === "#coupons" && <Coupons />) || <Account />
        )}
      </div>
      {/* ------------------ LOGOUT MODEL ----------------------- */}
      <MODEL
        setShowModel={setLogoutModelToggle}
        showModel={logoutModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-2">
            <span className="text-lg mont-bold">Logout</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setLogoutModelToggle(false);
              }}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <span className="lg:text-xl emd:text-lg small:text-lg text-center my-5">
              Are you sure, You want to logout ?
            </span>
            <div className="flex w-full items-center justify-center mt-5">
              <BTN title={"Confirm"} className={"py-1 px-5 w-fit"} onP={handleLogout} />
              <BTN title={"Cancel"} bordered className={"py-1 px-5 w-fit ms-4"} onP={()=>setLogoutModelToggle(false)} />
            </div>
          </div>
        </div>
      </MODEL>
    </div>
  );
};

export default ProfileDetails;
