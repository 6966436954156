import { useNavigate } from "react-router-dom";
import React from "react";
import Slider from "react-slick/lib/slider";
import { Breakpoint } from "react-socks";

export default function NetreBlurim({ homeData }) {
  const navigate = useNavigate();

  const lArrayData = [
    {
      desktop: "/banner_1_d.png",
      mobile: "/banner_1_m.png",
      redirect: "/",
    },
    {
      desktop: "/banner_3_d.png",
      mobile: "/banner_3_m.png",
      redirect: "/",
    },
    {
      desktop: "/banner_5_d.png",
      mobile: "/banner_5_m.png",
      redirect: "/",
    },
    {
      desktop: "/banner_7_d.png",
      mobile: "/banner_7_m.png",
      redirect: "/",
    },
  ];

  const rArrayData = [
    {
      desktop: "/banner_2_d.png",
      mobile: "/banner_2_m.png",
      redirect: "/",
    },
    {
      desktop: "/banner_4_d.png",
      mobile: "/banner_4_m.png",
      redirect: "/",
    },
    {
      desktop: "/banner_6_d.png",
      mobile: "/banner_6_m.png",
      redirect: "/",
    },
    {
      desktop: "/banner_8_d.png",
      mobile: "/banner_8_m.png",
      redirect: "/",
    },
  ];
  
  var lSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-sliding
    autoplaySpeed: 2000, // Set the interval (3 seconds)
    vertical: true,
    verticalSwiping: true,
    rtl: false,
    pauseOnHover: false,
  };

  var rSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-sliding
    autoplaySpeed: 2000, // Set the interval (3 seconds)
    vertical: true,
    verticalSwiping: true,
    rtl: true,
    pauseOnHover: false,
  };

  return (
    <div className="h-full w-full flex items-center justify-center">
      <Breakpoint
        large
        up
        className="flex items-center justify-center gap-[30px] px-[45px] pb-[30px] pt-0"
      >
        <div className="w-1/2 h-auto">
          <Slider
            {...lSettings}
            className=" w-full aspect-[3/2] flex flex-col overflow-hidden justify-center items-center"
          >
            {/* {lArrayData?.map( */}
            {homeData?.two_branding_banners?.left_slider?.map(
              (image, index) => {
                return (
                  <img
                    key={index}
                    src={image?.desktop}
                    // src={"/new/main_banner_600.jpg"}
                    alt="Product"
                    className="w-full h-auto object-cover"
                    onClick={() => navigate(image?.redirect)}
                  />
                );
              }
            )}
          </Slider>
        </div>
        <div className="w-1/2 h-auto">
          <Slider
            {...rSettings}
            className=" w-full aspect-[3/2] flex flex-col overflow-hidden justify-center items-center"
          >
            {/* {rArrayData?.reverse()?.map((image, index) => { */}
            {homeData?.two_branding_banners?.right_slider?.slice()?.reverse()?.map((image, index) => {
                return (
                  <img
                    key={index}
                    src={image?.desktop}
                    // src={"/new/main_banner_600.jpg"}
                    alt="Product"
                    className="w-full h-auto object-cover"
                    onClick={() => navigate(image?.redirect)}
                  />
                );
              })}
          </Slider>
        </div>
      </Breakpoint>
      <Breakpoint
        medium
        down
        className="h-full flex flex-col items-center justify-center pb-[30px] pt-0"
      >
        <div className="w-full h-full">
          <Slider
            {...lSettings}
            className=" w-full aspect-[1/1] flex flex-col overflow-hidden justify-center items-center"
          >
            {/* {lArrayData?.map( */}
            {homeData?.two_branding_banners?.left_slider?.map(
              (image, index) => {
                return (
                  <img
                    key={index + 1}
                    src={image?.mobile}
                    alt="Product"
                    className="w-full h-full object-contain"
                    onClick={() => navigate(image?.redirect)}
                  />
                );
              }
            )}
          </Slider>
        </div>
        <div className="w-full h-full">
          <Slider
            {...rSettings}
            className=" w-full aspect-[1/1] flex flex-col overflow-hidden justify-center items-center"
          >
            {/* {rArrayData?.map( */}
            {homeData?.two_branding_banners?.right_slider?.slice()?.reverse()?.map(
              (image, index) => {
                return (
                  <img
                    key={index}
                    src={image?.mobile}
                    alt="Product"
                    className="w-full h-full object-contain"
                    onClick={() => navigate(image?.redirect)}
                  />
                );
              }
            )}
          </Slider>
        </div>
      </Breakpoint>
    </div>
  );
}

{
  /* <div className="NetreBlueRim_Container">
<div
  className="NetreBlueRim-Section_Left"
  style={{
    backgroundImage: `url('${homeData?.two_branding_banners?.banners[0]?.background}')`,
  }}
>
  <div className="Content_Container">
    <div className="Content-1st">
      <div className="Netre_Logo_left">
        <img
          src={homeData?.two_branding_banners?.banners[0]?.title_image}
          alt="logo"
          height="100%"
          width="100%"
        />
      </div>
      <div className="Content-Para">
        {homeData?.two_branding_banners?.banners[0]?.description}
      </div>
      <div
        onClick={() =>
          navigate(homeData?.two_branding_banners?.banners[0]?.redirect)
        }
        className="Discover-btn"
      >
        {homeData?.two_branding_banners?.banners[0]?.button_text}
      </div>
    </div>
  </div>
  <div className="Netre_glassImg_left lg:pb-8 emd:pb-0 small:pb-0">
    <img
      src={homeData?.two_branding_banners?.banners[0]?.frame_image}
      alt="glassImg"
      className="w-full h-full object-contain"
    />
  </div>
</div>
<div
  className="NetreBlueRim-Section_Right"
  style={{
    backgroundImage: `url('${homeData?.two_branding_banners?.banners[1]?.background}')`,
  }}
>
  <div className="Content_Container">
    <div className="Content-1st">
      <div className="Netre_Logo_right">
        <img
          src={homeData?.two_branding_banners?.banners[1]?.title_image}
          alt="logo"
          height="100%"
          width="100%"
        />
      </div>
      <div className="Content-Para">
        {homeData?.two_branding_banners?.banners[1]?.description}
      </div>
      <div
        onClick={() =>
          navigate(homeData?.two_branding_banners?.banners[1]?.redirect)
        }
        className="Discover-btn"
      >
        {homeData?.two_branding_banners?.banners[1]?.button_text}
      </div>
    </div>
  </div>
  <div className="Netre_glassImg_right lg:pb-8 emd:pb-0 small:pb-0">
    <img
      src={homeData?.two_branding_banners?.banners[1]?.frame_image}
      alt="glassImg"
      className="w-full h-full object-contain"
    />
  </div>
</div>
</div> */
}
