import React, { useEffect, useRef, useState } from "react";
import { Breakpoint } from "react-socks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import "./Retailer.scss";
import {
  useGetProductsMutationMutation,
  useGetRetailerDtaQuery,
} from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";
import { ProductCard } from "../Category/Category";
import Slider from "../../home/BestSellingFrame/Slider/Slider";
import "../../home/BestSellingFrame/Slider/slider.scss";
import {
  ARROW_BACK_BLACK_IC,
  ARROW_FORWARD_BLACK_IC,
  MOBILE_RETAILER_BANNER,
  RETAILER_BANNER,
} from "../../../assets";
import { useSelector } from "react-redux";
import InventoryCard from "./InventoryCard";

const testimonials = [
  {
    id: 1,
    text: "I have purchased this product from the shop with good quality and staff behaviour was also good. Must try it!",
    author: "Jyotika Shah",
  },
  {
    id: 2,
    text: "2 I have purchased this product from the shop with good quality and staff behaviour was also good. Must try it!",
    author: "Jyotika Shah",
  },
  {
    id: 2,
    text: " 3 I have purchased this product from the shop with good quality and staff behaviour was also good. Must try it!",
    author: "Jyotika Shah",
  },
  // Add more testimonials here
];

const Retailer = ({
  setIsRetailerPage,
  eyeFramesRef,
  newArrivalsRef,
  contactUsRef,
  ourStoreRef,
}) => {
  useEffect(() => {
    setIsRetailerPage(true);
  }, []);

  const params = useParams();
  const [products, setProducts] = useState([]);
  const [getProductsMutation] = useGetProductsMutationMutation();

  useEffect(() => {
    getProductsMutation({
      body: {
        attributes: {
          Brand: "Bluerim",
        },
      },
      page: 1,
      limit: 6,
    }).then((res) => {
      if (res.data) {
        if (res.data) {
          setProducts(res.data?.products);
        }
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  }, []);

  const [current, setCurrent] = useState(0);

  const prevTestimonial = () => {
    setCurrent(current === 0 ? testimonials.length - 1 : current - 1);
  };

  const nextTestimonial = () => {
    setCurrent(current === testimonials.length - 1 ? 0 : current + 1);
  };

  const {
    data: retailerData,
    isSuccess: retailerDataIsSuccess,
    isLoading: retailerDataIsLoading,
    isError: retailerDataIsError,
    error: retailerDataError,
  } = useGetRetailerDtaQuery({ name: params?.retailerName });

  console.log("retailer ---->", retailerData?.data?.inventory);
  return (
    <div className="retailer w-full h-auto flex flex-col items-center">
      <Breakpoint large up>
        <div className="h-[100dvh] w-full flex items-center justify-center">
          <img
            src={RETAILER_BANNER}
            className="object-cover h-full w-full"
            alt=""
          />
        </div>
      </Breakpoint>
      <Breakpoint medium down>
        <div className="h-[100dvh] w-full flex items-center justify-center object-cover">
          <img
            src={MOBILE_RETAILER_BANNER}
            className="object-cover h-full w-full"
            alt=""
          />
        </div>
      </Breakpoint>
      {/* ------------------------------------------------------------------ */}
      <div ref={eyeFramesRef} className="w-full flex flex-col">
        <div className="flex justify-between lg:px-5 emd:px-0 small:px-0 items-center gap-7 mt-10">
          <div className="w-[30%] border"></div>
          <div className="text-3xl text-nowrap whitespace-pre ">Eye Frames</div>
          <div className="w-[30%] border"></div>
        </div>
        <div className="w-full">
          {!retailerData?.data?.inventory?.length > 0 ? (
            <div className="no_product_found">No Products</div>
          ) : (
            <div className="cards">
              {retailerData?.data?.inventory &&
                retailerData?.data?.inventory
                  ?.slice(0, 6)
                  ?.map((item, index) => (
                    <InventoryCard
                      key={index}
                      product={item}
                      // route={"/virtual-tryon"}
                      storeType={"Virtual"}
                    />
                  ))}
            </div>
          )}
        </div>
      </div>

      {/* ------------------------------------------------------------------ */}
      <div
        className="Flexible_Frames_Container min-w-[98%] max-w-[98%] mt-10"
        style={{
          backgroundImage: `url(${homeData?.promotion_banner?.background})`,
        }}
      >
        <div className="Section_title_container">
          <div className="section-title font-fc-mont">
            {homeData?.promotion_banner?.title}
          </div>
          <div className="Section_Sub_title font-fc-sabon text-center">
            {homeData?.promotion_banner?.subtitle}
          </div>
        </div>
        <div
          //   onClick={() => navigate(homeData?.promotion_banner?.redirect)}
          className="try-now-btn mont-regular text-nowrap whitespace-pre "
        >
          {homeData?.promotion_banner?.button_text}
          <div className="overlay"></div>
        </div>
      </div>
      {/* ------------------------------------------------------------------ */}
      <div ref={newArrivalsRef} className="w-full mb-10">
        <div className="w-full flex mt-10 items-center justify-between">
          <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
          <span className="lg:text-3xl emd:text-xl small:text-lg text-center text-nowrap whitespace-pre ">
            New Arrivals
          </span>
          <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
        </div>
        <Slider products={retailerData?.data?.inventory} isRetailer={true} />
      </div>
      {/* ------------------------------------------------------------------ */}
      <div ref={ourStoreRef} className="w-full mb-10 mt-5 px-10 bg-gray ">
        <div className="relative bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 flex justify-center items-center text-center">
          <div className="w-1/2 text-center">
            <h3 className="text-lg text-center font-medium text-yellow-700">
              Customer Reviews
            </h3>
            <h2 className="lg:text-3xl emd:text-2xl small:text-xl text-center font-bold text-gray-900 my-4">
              Your Experience With Us!
            </h2>
            <p className="text-lg text-center text-gray-600 italic mb-4 text-light_secondary">
              "{testimonials[current].text}"
            </p>
            <p className="text-sm text-center text-gray-800 font-semibold">
              {testimonials[current].author}
            </p>
          </div>
          <div className="absolute inset-y-0 left-0 flex items-center">
            <button
              onClick={prevTestimonial}
              className="p-2 rounded-full bg-white shadow hover:bg-gray flex items-center justify-center px-4 outline-none ring-0"
            >
              {/* <ChevronLeftIcon className="h-6 w-6 text-gray-600" /> */}
              <div className="material-symbols-outlined">
                <img src={ARROW_BACK_BLACK_IC} alt="" className="w-6" />
              </div>
            </button>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center">
            <button
              onClick={nextTestimonial}
              className="p-2 rounded-full bg-white shadow hover:bg-gray flex items-center justify-center px-4 outline-none ring-0"
            >
              <div className="material-symbols-outlined">
                <img src={ARROW_FORWARD_BLACK_IC} alt="" className="w-6" />
              </div>
            </button>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------------------------ */}
      <div
        ref={contactUsRef}
        className="flex flex-col emd:flex-row items-center emd:items-start bg-white w-full"
      >
        {/* Left Side - Image */}
        <div className="w-full h-[500px] emd:w-1/2">
          <img
            src="/eyeglasses-brands.webp"
            alt="Store"
            className="w-full h-[500px] object-cover"
          />
        </div>

        {/* Right Side - Text */}
        <div className="w-full h-full emd:w-1/2 text-gray-700">
          <div className="h-[500px] flex flex-col justify-between p-4 mxs:p-6 emd:p-8">
            <div className="">
              <h2 className="text-lg font-semibold">OUR STORE</h2>
              <p className="mt-2 text-light_secondary">
                {retailerData?.data?.storeInfo?.address} <br />
                {retailerData?.data?.storeInfo?.zipcode}
              </p>
            </div>

            {/* <div className="">
              <h2 className="text-lg font-semibold">STORE TIMING</h2>
              <p className="mt-2 text-light_secondary">
                MON - SAT | 10AM-8.30PM
              </p>
            </div> */}

            <div>
              <h2 className="text-lg font-semibold">CONTACT US</h2>
              <p className="mt-2 text-light_secondary">+91 8230763009</p>
              <p className="text-light_secondary">ntere@info.in</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Retailer;

const homeData = {
  main_banner_status: true,
  main_banner_images: [
    {
      mobile: "/eyeglasses-brands.webp",
      desktop: "/eyeglasses-brands.webp",
    },
  ],
  curated_glasses_status: true,
  curated_glasses: {
    title: "Browse Our Curated Glasses",
    subtitle: "",
    banners: [
      {
        image: " /frame.png ",
        background: "/Rectangle 575.png",
        box_img: "/prop.png",
        button_text: "Home",
        redirect: "/categories/6685401437297f48e556d53c",
      },
      {
        image: "/glass@2x2.png",
        background: "/Rectangle 575(2).png",
        box_img: "/prop.png",
        button_text: "Optical Frame",
        redirect: "/categories/668540397db56839146d6f82",
      },
      {
        image: "/Sunglass.png",
        background: "/Rectangle 575(3).png",
        box_img: "/prop.png",
        button_text: "Sunglasses",
        redirect: "/categories/668540567db56839146d6f8e",
      },
    ],
  },
  grid_banners_status: true,
  grid_banners: {
    left_banner: {
      image: "/eyeglasses-brands.webp",
      background: "image_url",
      button_text: "VIEW PRODUCTS",
      redirect: "URL",
    },
    right_banner: {
      title: "try it online",
      subtitle: "Want To Try Before Buy !",
      button_text: "VIEW PRODUCTS",
      background: "/3rd-back.png",
      image: "/33d-model.png",
      redirect: "URL",
    },
  },
  promotion_banner_status: true,
  promotion_banner: {
    title: "Nimbus",
    subtitle: "Flexible, Unbreakable Frames",
    button_text: "VIEW PRODUCTS",
    image: "image_url",
    background: "/FlexibleFramesBanner.png",
    redirect: "URL",
  },
  brand_filter_products_status: true,
  brand_filter_products: {
    title: "Eye Frames",
    subtitle: "",
  },
  shapes_section_status: true,
  shapes_section: {
    title: "Browse Wise Face Shapes",
    subtitle: "",
    button_text: "SEE ALL FRAME",
    banners: [
      {
        image:
          "/lenskart-air-la-e13861-c2-eyeglasses_untitled_session13505_147117-PhotoRoo m.png-PhotoRoom.png",
        background: "/glass.png ",
        button_text: "SQUARE",
        redirect: "URL",
      },
      {
        image:
          "/lenskart-air-la-e13861-c2-eyeglasses_untitled_session13505_147117-PhotoRoo m.png-PhotoRoom.png",
        background: "/glass.png ",
        button_text: "ROUND",
        redirect: "URL",
      },
      {
        image:
          "/lenskart-air-la-e13861-c2-eyeglasses_untitled_session13505_147117-PhotoRoo m.png-PhotoRoom.png",
        background: "/glass.png ",
        button_text: "RECTANGLE",
        redirect: "URL",
      },
      {
        image:
          "/lenskart-air-la-e13861-c2-eyeglasses_untitled_session13505_147117-PhotoRoo m.png-PhotoRoom.png",
        background: "/glass.png ",
        button_text: "AVIATOR",
        redirect: "URL",
      },
    ],
    redirect: "URL",
  },
  best_selling_section_status: true,
  best_selling_section: {
    title: "Best Selling Frame",
    subtitle: "",
  },
  two_branding_banners_status: true,
  two_branding_banners: {
    title: "",
    subtitle: "",
    banners: [
      {
        title_image: "/Netre-logo.svg",
        description:
          " A timeless bond built on passion. join our vibrant community rooted in a rich history.",
        frame_image: "/Rectangle 741.png",
        background: "/Rectangle 575.png",
        button_text: "Discover",
        redirect: "URL",
      },
      {
        title_image: "/half_txt.png",
        description:
          "A timeless bond built on passion. join our vibrant community rooted in a rich history.",
        frame_image: "/Rectangle 741.png",
        background: "/Rectangle 738.png",
        button_text: "Discover",
        redirect: "URL",
      },
    ],
  },
  feature_banners_status: true,
  feature_banners: {
    title: "What We Offer At Netre !",
    subtitle:
      "A timeless bond built on passion. join our vibrant community rooted in a rich history.",
    banners: [
      {
        image: "/eyeglasses-brands.webp",
        background: "image_url",
        button_text: "Try at Store",
        redirect: "#",
      },
      {
        image: "/eyeglasses-brands.webp",
        background: "image_url",
        button_text: "EASY RETURN",
        redirect: "#",
      },
      {
        image: "/eyeglasses-brands.webp",
        background: "image_url",
        button_text: "ABOUT BRAND",
        redirect: "#",
      },
    ],
  },
  lens_options_status: true,
  lens_options: {
    title: "Lense Options",
    subtitle: "",
    option_tabs: [
      {
        title: "Polorization",
        image1: "image_url",
        image2: "image_url",
        button_text: "Try at Store",
        redirect: "URL",
      },
      {
        title: "Blue Light",
        image1: "image_url",
        image2: "image_url",
        button_text: "Try at Store",
        redirect: "URL",
      },
      {
        title: "Tints",
        image1: "image_url",
        image2: "image_url",
        button_text: "Try at Store",
        redirect: "URL",
      },
      {
        title: "Mirror",
        image1: "image_url",
        image2: "image_url",
        button_text: "Try at Store",
        redirect: "URL",
      },
      {
        title: "Transitions",
        image1: "image_url",
        image2: "image_url",
        button_text: "Try at Store",
        redirect: "URL",
      },
      {
        title: "UV / Clear",
        image1: "image_url",
        image2: "image_url",
        button_text: "Try at Store",
        redirect: "URL",
      },
    ],
  },
};
