import { toast } from "react-toastify";
import { useGetProductsMutationMutation } from "../../../../redux/slices/homeSlice";
import "./slider.scss";
import SliderChild from "./SliderChild";
import React, { useEffect, useState } from "react";
import { ProductCard } from "../../../Pages/Category/Category";
import { ARROW_BACK_IC, ARROW_FORWARD_IC } from "../../../../assets";
import RetailerSliderChild from "./RetailerSliderChild";
import SlicerCard from "../../../Master/SlicerCard";

export default function Slider(props) {
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      const scrollImages = document.querySelector(".best_selling");
      const scrollLength = scrollImages.scrollWidth - scrollImages.clientWidth;
      const leftButton = document.querySelector(".left");
      const rightButton = document.querySelector(".right");

      function checkScroll() {
        const currentScroll = scrollImages.scrollLeft;
        if (currentScroll === 0) {
          leftButton.setAttribute("disabled", "true");
          rightButton.removeAttribute("disabled");
        } else if (currentScroll === scrollLength) {
          rightButton.setAttribute("disabled", "true");
          leftButton.removeAttribute("disabled");
        } else {
          leftButton.removeAttribute("disabled");
          rightButton.removeAttribute("disabled");
        }
      }

      scrollImages.addEventListener("scroll", checkScroll);
      window.addEventListener("resize", checkScroll);
      checkScroll();
    });
  }, []);

  function leftScroll() {
    const scrollImages = document.querySelector(".best_selling");
    scrollImages?.scrollBy({
      left: -295,
      behavior: "smooth",
    });
  }

  function rightScroll() {
    const scrollImages = document.querySelector(".best_selling");
    scrollImages?.scrollBy({
      left: 295,
      behavior: "smooth",
    });
  }

  return (
    <div className="cover">
      
        <button className="left" onClick={leftScroll}>
          <div
            className="material-symbols-outlined"
            style={{ color: "#A9772B" }}
          >
            <img src={ARROW_BACK_IC} alt="" className="w-8" />
          </div>
        </button>
      
      {props?.products && (
        <div
          className={`best_selling cards ${
            props?.products?.length < 4
              ? "lg:justify-center emd:justify-center small:justify-start"
              : ""
          }`}
        >
          {props?.isRetailer === true
            ? props?.products?.slice(0, 6)?.map((item, index) => {
                return <RetailerSliderChild data={item} key={index} />;
              })
            : props?.products?.slice(0, 6)?.map((item, index) => {
                return (
                  <SlicerCard data={item} key={index} blueCutLensIdDataLabel={props?.blueCutLensIdDataLabel} isNewArrival={props?.isNewArrival} />
                );
              })}
        </div>
      )}
      
        <button className="right" onClick={rightScroll}>
          <div
            className="material-symbols-outlined"
            style={{ color: "#A9772B" }}
          >
            <img src={ARROW_FORWARD_IC} alt="" className="w-8" />
          </div>
        </button>
      
    </div>
  );
}

                  // <SliderChild
                  //   data={item}
                  //   key={index}
                  //   blueCutLensIdDataLabel={props?.blueCutLensIdDataLabel}
                  // />