import React from "react";
import Slider from "../home/BestSellingFrame/Slider/Slider";
import SlicerCard from "./SlicerCard";

const LtoRScrollListing = ({
  title,
  data,
  classForTitle,
  blueCutLensIdDataLabel,
}) => {
  return (
    <div className="w-full">
      <div className="w-full flex mt-10 items-center justify-center">
        {/* <hr className="lg:w-[36%] emd:w-[30%] small:w-[20%]" /> */}
        <span
          className={`lg:text-[30px] emd:text-[28px] small:text-[22px] text-center text-nowrap whitespace-pre  ${classForTitle}`}
        >
          {title}
        </span>
        {/* <hr className="lg:w-[36%] emd:w-[30%] small:w-[20%]" /> */}
      </div>
      {data?.length > 1 ? (
        <Slider
          products={data}
          isRetailer={false}
          blueCutLensIdDataLabel={blueCutLensIdDataLabel}
        />
      ) : (
        <div className="w-full py-10 flex items-center justify-center">
          <span className="text-2xl text-light_secondary">No Products</span>
        </div>
      )}
    </div>
  );
};

export default LtoRScrollListing;
