import React, { useEffect, useState } from "react";
import useGetUser from "../../../hooks/useGetUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddToCustomerBagMutation,
  useAddToWishlistMutation,
  useRemoveFromWishlistMutation,
} from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";
import { addToMyBag } from "../../../redux/slices/myBagSlice";

const InventoryCard = (props) => {
  const user = useGetUser();
  const { pathname } = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);
  const [wishListed, setWishListed] = useState(false);
  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (wishlistData) {
      if (props?.product.product_id) {
        const found = wishlistData.find(
          (element) => element._id === props.product.product_id
        );
        if (found) {
          setWishListed(true);
        } else {
          setWishListed(false);
        }
      }
    }
  }, [wishlistData, props]);

  const [addToWishlist] = useAddToWishlistMutation();
  const [removeFromWishlist] = useRemoveFromWishlistMutation();
  const handleWishlist = () => {
    if (!wishListed) {
      if (!loggedIn) {
        toast.error("Please log in to add into Wishlist");
        return;
      }
      if (!props.product.product_id) {
        return;
      }
      addToWishlist({
        body: {
          product_id: props.product.product_id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(true);
          toast.success("Added to Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    } else {
      removeFromWishlist({
        body: {
          product_id: props.product.product_id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(false);
          toast.success("Removed from Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const stock = 10;

  const redirctUrl = `/products/${props?.product?.product_id}`;
  var image = null;

  image = props.product?.images[0] ? props.product?.images[0] : null;
  if (image) {
    var transformedImage = image?.replace(
      "upload/",
      "upload/if_w_gt_400/w_400/if_end/"
    );
  }
  //on click on sku copy the sku to clipboard
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(props.product.sku);
    e.target.innerText = "Copied!";
    setTimeout(() => {
      e.target.innerText = "SKU : " + props.product.sku;
    }, 1000);
  };

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      console.log(props?.product);
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: props?.product?.product_id,
              quantity: 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${props?.product?.name} added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag(props?.product));
      toast.success(`${props?.product?.name} added to bag successfully.`);
    }
  };

  return (
    <div
      className="card"
      style={{
        outline:
          props?.product?.product_id &&
          props?.newFrame?._id &&
          props?.product?.product_id === props?.newFrame?._id
            ? "1px solid var(--color2)"
            : "unset",
      }}
    >
      <div className="top">
        {props.product?.quantity > 0 && (
          <div
            className={
              location.pathname === "/inventory" &&
              props.storeType === "In Store"
                ? "count"
                : "count hide"
            }
          >
            {props.product?.quantity}
          </div>
        )}
      </div>
      <div
        className="middle pointer"
        key={props?.product?.product_id}
        // onClick={() =>
        //   !pathname?.includes("exchange-order") ? navigate(redirctUrl) : null
        // }
      >
        <img
          src={transformedImage ? transformedImage : "/Netre-logo.svg"}
          className="product-image"
          alt="Product"
          title="Product"
          loading="lazy"
        />
      </div>
      <div className="bottom">
        {props?.product?.name && (
          <div
            className={
              location.pathname === "/inventory" &&
              props.storeType === "Virtual"
                ? "title qty-present"
                : "title"
            }
          >
            {props.product?.name}
          </div>
        )}
        {/* <div
            className={
              location.pathname === "/inventory" ? "sku show qty-present" : "sku"
            }
            onClick={copyToClipboard}
          >
            SKU : {props?.product?.sku}
          </div> */}
        <div>₹ {props?.product?.price}</div>

        {/* {location.pathname.includes("exchange-order") ? (
          <span
            className="add_to_bag_text"
            onClick={() => {
              props.setNewFrame(null);
              props.setNewFrame(props?.product);
            }}
          >
            {props?.product?.product_id &&
            props?.newFrame?._id &&
            props?.product?.product_id === props?.newFrame?._id
              ? "Selected For Exchanged"
              : "Exchange Now"}
          </span>
        ) : (
          <span className="add_to_bag_text" onClick={() => addToMyBagHandel()}>
            Add to bag
          </span>
        )} */}
      </div>
      {props.product?.quantity === 0 ||
        props.product?.quantity === "0" ||
        (Number(props.product?.quantity) <= 0 && (
          <div
            className="sold-out"
            // onClick={() =>
            //   !pathname?.includes("exchange-order")
            //     ? navigate(redirctUrl)
            //     : null
            // }
          >
            <div className="div">SOLD OUT</div>
          </div>
        ))}
      {Number(stock) <= 0 && (
        <div
          className="sold-out"
          // onClick={() =>
          //   !pathname?.includes("exchange-order") ? navigate(redirctUrl) : null
          // }
        >
          <div className="div">SOLD OUT</div>
        </div>
      )}
      {Number(stock) > 0 &&
        location.pathname === "/inventory" &&
        (props.operation === "Add" ||
          props.operation === "Return" ||
          props.operation === "Exchange") && (
          <div className="quantity">
            <div className="label">Qty</div>
          </div>
        )}
    </div>
  );
};

export default InventoryCard;
