import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const homeSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL}` }),
  tagTypes: ["wishlistUpdated"],
  endpoints: (builder) => ({
    getHomePageData: builder.query({
      query: () => {
        return {
          url: "/customer",
          //  headers: { "X-Oc-Restadmin-Id": import.meta.env.VITE_APP_SECRET_KEY }
        };
      },
    }),

    getHomeData: builder.query({
      query: () => {
        return {
          url: "/setting/store/customization/all",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    getAppDownloadData: builder.query({
      query: () => {
        return {
          url: "/netre/get-app-links",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    getHeaderCategoriesData: builder.query({
      query: () => {
        return {
          url: "/category/show",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    getSingleCategoryData: builder.query({
      query: ({ id }) => {
        return {
          url: `/category/${id}`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),
    
    getHeaderLabelsData: builder.query({
      query: () => {
        return {
          url: "/netre/labels",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    getProducts: builder.query({
      query: ({ page, queryParams }) => {
        return {
          url: `/netre/products?${queryParams}&page=${page}&limit=10`,
          // body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg;
      // }
    }),

    // --------------------- GET PRODUCTS MUTATION -------------------

    getProductsMutation: builder.mutation({
      query: ({ body, page, limit, queryParams }) => {
        return {
          url: `/netre/products?${queryParams}&page=${page}&limit=${
            limit ? limit : 10
          }`,
          // url: `/netre/products?${queryParams}`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    // --------------------- --------------------- -------------------
    getFilters: builder.query({
      query: ({ queryParams }) => {
        return {
          url: `/netre/getFilters?${queryParams}`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    setAsMyFilter: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/netre/saveCustomerFilter",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
    }),

    getCustomerFilterMutation: builder.mutation({
      query: ({ user }) => {
        return {
          url: "/netre/getCustomerFilter",
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
    }),

    getProductData: builder.query({
      query: ({ id }) => {
        if (id == null) return;
        return {
          url: `/netre/products/${id}`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),
    getUserProfile: builder.query({
      query: ({ user }) => {
        return {
          url: "/customer/profile",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["profileUpdated"],
    }),

    updateUserProfile: builder.mutation({
      query: ({ body, id, user }) => {
        return {
          url: `/customer/${id}`,
          method: "PUT",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["profileUpdated"],
    }),

    generateOTPforCustomerSignup: builder.mutation({
      query: ({ body }) => {
        return {
          url: "/customer/generateOTP",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),
    getCustomerWishlist: builder.query({
      query: ({ user }) => {
        return {
          url: "/customer/wishlist",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },

      providesTags: ["wishlistUpdated"],
    }),
    addToWishlist: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/customer/wishlist",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["wishlistUpdated"],
    }),
    removeFromWishlist: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/customer/wishlist",
          method: "DELETE",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["wishlistUpdated"],
    }),
    getCustomerBagData: builder.query({
      query: ({ user }) => {
        return {
          url: "/customer/cart",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["customerBagUpdated"],
    }),
    addToCustomerBag: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/customer/cart",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerBagUpdated"],
    }),
    removeFromCustomerBag: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/customer/cart",
          method: "DELETE",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerBagUpdated"],
    }),
    addAddress: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/customer/address",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["profileUpdated"],
    }),
    updateAddress: builder.mutation({
      query: ({ body, id, user }) => {
        return {
          url: `/customer/address/${id}`,
          method: "PUT",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["profileUpdated"],
    }),
    deleteAddress: builder.mutation({
      query: ({ user, id }) => {
        return {
          url: `/customer/address/${id}`,
          method: "DELETE",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["profileUpdated"],
    }),

    getOrderData: builder.query({
      query: ({ user, orderType, page }) => {
        return {
          url: `/order/${
            orderType ? `?orderType=${orderType}&page=${page}` : `?page=${page}`
          }`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["orderUpdated"],
    }),

    getOrderDataMutation: builder.mutation({
      query: ({ user, orderType, page, limit }) => {
        return {
          url: `/order/${
            orderType
              ? `?orderType=${orderType}&page=${page}&limit=${
                  limit ? limit : 10
                }`
              : `?page=${page}&limit=${limit ? limit : 10}`
          }`,
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
    }),

    getOrderDetailsData: builder.query({
      query: ({ user, orderId }) => {
        return {
          url: `/order/${orderId}`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
    }),

    addOrder: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/order/add",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["orderUpdated", "customerBagUpdated", "profileUpdated"],
    }),
    sendPaymentRequest: builder.mutation({
      query: ({ body }) => {
        return {
          url: "/netre/initiateJuspayPayment",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
      invalidatesTags: ["orderUpdated", "customerBagUpdated", "profileUpdated"],
    }),
    checkPaymentStatus: builder.mutation({
      query: ({ body }) => {
        return {
          url: "/netre/checkPaymentStatus",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    returnOrder: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: `/order/return`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["orderUpdated"],
    }),

    cancelOrder: builder.mutation({
      query: ({ body, orderId, user }) => {
        return {
          url: `/orders/${orderId}`,
          method: "PUT",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["orderUpdated"],
    }),

    exchangeOrder: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: `/order/exchange`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["orderUpdated"],
    }),

    scheduleDelivery: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/order/schedule-delivery",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["orderUpdated"],
    }),

    getCouponList: builder.query({
      query: ({ user, couponType }) => {
        return {
          url: `/coupon/?couponType=open`, //open (public) or personal (private)
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["couponUpdated"],
    }),

    contactUs: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: `/netre/contact`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["orderUpdated"],
    }),
    subscribe: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: `/netre/subscribe`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["orderUpdated"],
    }),

    getLensList: builder.query({
      query: ({ user }) => {
        return {
          url: "/lens/show",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["lensUpdated"],
    }),

    getPrescriptionList: builder.query({
      query: ({ user }) => {
        return {
          url: "/customer/getCustomerPrescription",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["prescriptionUpdated"],
    }),

    addPrescription: builder.mutation({
      query: ({ body, user, id }) => {
        return {
          url: `/customer/saveCustomerPrescription${id ? `/${id}` : ""}`,
          method: id ? "PUT" : "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["prescriptionUpdated"],
    }),

    deletePrescription: builder.mutation({
      query: ({ user, id }) => {
        return {
          url: `/customer/deleteCustomerPrescription/${id}`,
          method: "DELETE",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["prescriptionUpdated"],
    }),

    getRetailerDta: builder.query({
      query: ({ name }) => {
        return {
          url: `/netre/retailer/${name}`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
    }),

    getCustomerHomeTryOnData: builder.query({
      query: ({ user }) => {
        return {
          url: "/customer/inquiry/tray",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["customerHomeTryOnUpdated"],
    }),
    addToCustomerHomeTryOnData: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/customer/inquiry/tray",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerHomeTryOnUpdated"],
    }),
    removeFromCustomerHomeTryOnData: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/customer/inquiry/tray",
          method: "DELETE",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerHomeTryOnUpdated"],
    }),

    getCustomerHomeTryOnOrderData: builder.query({
      query: ({ user }) => {
        return {
          url: "/netre/customer/getInquiry",
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["customerHomeTryOnOrderUpdated"],
    }),
    addToCustomerHomeTryOnOrderData: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/netre/customer/addInquiry",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerHomeTryOnOrderUpdated"],
    }),
    removeFromCustomerHomeTryOnOrderData: builder.mutation({
      query: ({ user, body }) => {
        return {
          url: "/netre/getInquiry",
          method: "PUT",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerHomeTryOnOrderUpdated"],
    }),

    checkProductIsPurchased: builder.query({
      query: ({ productId, user }) => {
        return {
          url: `customer/hasPurchased/${productId}`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
    }),

    getReviews: builder.query({
      query: ({ productId, user }) => {
        return {
          url: `/products/reviews/${productId}`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      providesTags: ["productReviewUpdated"],
    }),

    addReview: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: "/products/reviews/add",
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["productReviewUpdated"],
    }),
    checkAddCoupon: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: `/netre/getCouponDetailsFromCode`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerHomeTryOnOrderUpdated"],
    }),

    AddLensOnlyInquiry: builder.mutation({
      query: ({ body, user }) => {
        return {
          url: `/customer/lensOnlyRequest`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${user}`,
          },
        };
      },
      invalidatesTags: ["customerHomeTryOnOrderUpdated"],
    }),

    AddVisionWellnessInquiry: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/netre/visionWellness`,
          method: "POST",
          body: body,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
      invalidatesTags: ["customerHomeTryOnOrderUpdated"],
    }),

    getCODStatus: builder.query({
      query: () => {
        return {
          url: `/setting/store-setting/all`,
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        };
      },
      providesTags: ["productReviewUpdated"],
    }),
  }),
});

export const {
  useGetHomeDataQuery,
  useGetHomePageDataQuery,
  useGetAppDownloadDataQuery,
  useGetHeaderLabelsDataQuery,
  useGetProductsQuery,
  useGetProductsMutationMutation,
  useGetProductDataQuery,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useGenerateOTPforCustomerSignupMutation,
  useGetCustomerWishlistQuery,
  useAddToWishlistMutation,
  useRemoveFromWishlistMutation,
  useGetCustomerBagDataQuery,
  useAddToCustomerBagMutation,
  useRemoveFromCustomerBagMutation,
  useAddAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useGetOrderDataQuery,
  useGetOrderDataMutationMutation,
  useGetOrderDetailsDataQuery,
  useAddOrderMutation,
  useSendPaymentRequestMutation,
  useCheckPaymentStatusMutation,
  useReturnOrderMutation,
  useCancelOrderMutation,
  useExchangeOrderMutation,
  useScheduleDeliveryMutation,
  useGetCouponListQuery,
  useContactUsMutation,
  useSubscribeMutation,
  useGetFiltersQuery,
  useSetAsMyFilterMutation,
  useGetCustomerFilterMutationMutation,
  useGetLensListQuery,
  useGetPrescriptionListQuery,
  useAddPrescriptionMutation,
  useDeletePrescriptionMutation,
  useGetRetailerDtaQuery,
  useGetCustomerHomeTryOnDataQuery,
  useAddToCustomerHomeTryOnDataMutation,
  useRemoveFromCustomerHomeTryOnDataMutation,
  useGetCustomerHomeTryOnOrderDataQuery,
  useAddToCustomerHomeTryOnOrderDataMutation,
  useRemoveFromCustomerHomeTryOnOrderDataMutation,
  useGetHeaderCategoriesDataQuery,
  useCheckProductIsPurchasedQuery,
  useAddReviewMutation,
  useGetReviewsQuery,
  useCheckAddCouponMutation,
  useAddLensOnlyInquiryMutation,
  useAddVisionWellnessInquiryMutation,
  useGetCODStatusQuery,
  useGetSingleCategoryDataQuery,
} = homeSlice;
