import React, { useState } from "react";
import { useGetCouponListQuery } from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";
import BTN from "../../Master/BTN";
import { addOrderDetails } from "../../../redux/slices/myBagSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ARROW_FORWARD_BLACK_IC, CONTENT_COPY_IC } from "../../../assets";
import { fakeData } from "../../../json";
import Skeleton from "react-loading-skeleton";

const Coupons = () => {
  const [couponType, setCouponType] = useState("online");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetails = useSelector((state) => state?.myBagData?.orderDetails);
  const myBagitems = useSelector((state) => state?.myBagData?.items);
  const user = localStorage.getItem("user");

  const total = myBagitems?.reduce(
    (acc, item) => acc + item?.prices?.price * item?.quantity,
    0
  );

  const {
    data: couponData,
    isSuccess: couponDataIsSuccess,
    isLoading: couponDataIsLoading,
    isError: couponDataIsError,
    error: couponDataError,
  } = useGetCouponListQuery(
    { user: user, couponType: couponType },
    // { skip: !user }
    { skip: true }
  );

  const formateDate = (date) => {
    const estimatedDeliveryDate = new Date(date);
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate());
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedEstimatedDeliveryDate =
      estimatedDeliveryDate.toLocaleDateString("en-GB", options);
    return formattedEstimatedDeliveryDate;
  };

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Coupon code copied to clipboard!");
      })
      .catch((err) => {
        toast.success("Failed to copy text: ");
      });
  };

  const checkCouponCodeIsValid = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();

    if (currentTime > endTime) {
      toast.error("Your coupon was expired!");
      return false;
    }
    if (total < coupon?.minimumAmount) {
      toast.error(
        `This coupon is valid for minimum order ${coupon?.minimumAmount}!`
      );
      return false;
    }
    if (coupon?.couponQuantity === 0) {
      toast.error(`This coupon's quantity is ${coupon?.couponQuantity}!`);
      return false;
    }
    return true;
  };

  const checkEndDateOfCoupon = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();
    if (currentTime > endTime || coupon?.couponQuantity === 0) {
      return true;
    } else {
      return false;
    }
  };

  const calculateCouponOffer = (coupon) => {
    if (coupon) {
      if (coupon?.discountType?.type === "fixed") {
        return total - coupon?.discountType?.value;
      } else {
        return parseFloat(
          total - (total * coupon?.discountType?.value) / 100
        ).toFixed(2);
      }
    } else {
      return total;
    }
  };

  const couponApplyHandle = (item) => {
    if (checkCouponCodeIsValid(item)) {
      dispatch(
        addOrderDetails({
          ...orderDetails,
          couponCode: item,
        })
      );
      navigate("/shopping-bag");
      toast.success(`${item?.couponCode} applied`);
    }
  };
  return (
    <div className="w-full overflow-hidden flex flex-col">
      <div className="flex items-center justify-between pb-4 border-b border-light_secondary">
        <span className="text-2xl font-bold">Coupons</span>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        {/* =============================================================== */}
        {/* <div className="w-full select-none my-7 flex items-center justify-start hide_scrollbar overflow-x-auto overflow-y-hidden">
          <div
            className={`px-4 py-1 text-center cursor-pointer text-nowrap whitespace-pre  ${
              couponType === "online"
                ? "bg-secondary text-white"
                : "border border-secondary"
            }`}
            onClick={() => setCouponType("online")}
          >
            Online Coupons
          </div>
          <div
            className={`px-4 py-1 text-center cursor-pointer text-nowrap whitespace-pre  ms-4 ${
              couponType === "store"
                ? "bg-secondary text-white"
                : "border border-secondary"
            }`}
            onClick={() => setCouponType("store")}
          >
            In Store Coupons
          </div>
        </div> */}
        <div className="w-full mt-8 grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-4">
          {!couponDataIsSuccess &&
            fakeData?.splice(0, 6)?.map((item, index) => {
              return (
                <Skeleton
                  key={index}
                  className="p-4 h-64 min-w-full max-w-full"
                />
              );
            })}

          {couponData?.map((item, index) => {
            return item?.status === "show" ? (
              <div
                key={index}
                className={`${
                  orderDetails?.couponCode?.couponCode === item?.couponCode
                    ? "border-primary border-2"
                    : "border-[#8B94B2] border"
                } p-4 select-none h-64 min-w-full max-w-full flex flex-col justify-between ${
                  checkEndDateOfCoupon(item) ? "opacity-50" : ""
                }`}
              >
                <div className="w-full flex items-center">
                  {/* <span className="text-2xl font-bold">40% OFF</span>
                  <span className="text-lg">For whole order</span> */}
                  <span className="text-2xl font-bold text-primary">
                    {item?.couponCode}
                  </span>
                  {/* <div
                    onClick={() => handleCopyClick(item?.couponCode)}
                    className="flex items-center ms-3 cursor-pointer"
                  >
                    <div className="material-symbols-outlined ">
                      <img src={CONTENT_COPY_IC} alt="" className="w-5" />
                    </div>
                    <span className="ms-2 text-primary">Copy</span>
                  </div> */}
                </div>
                <div className="flex items-center">
                  <ul>
                    <li className="text-light_secondary text-sm">
                      {item?.title?.en}
                    </li>
                    <li className="text-light_secondary text-sm">
                      Minimum Amount : {item?.minimumAmount}
                    </li>
                    <li className="text-light_secondary text-sm">
                      Valid on {formateDate(item?.endTime)} order only
                    </li>
                  </ul>
                </div>
                <div className="w-full flex flex-col justify-between">
                  <div className=" flex items-center">
                    <button
                      disabled={checkEndDateOfCoupon(item)}
                      onClick={() => couponApplyHandle(item)}
                      className={`flex items-center ${
                        checkEndDateOfCoupon(item)
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                    >
                      <div className="material-symbols-outlined">
                        <img
                          src={ARROW_FORWARD_BLACK_IC}
                          alt=""
                          className="w-5"
                        />
                      </div>
                      <span className="ms-2">Apply</span>
                    </button>
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
        <BTN
          className={"mt-16 lg:w-auto md:w-auto small:w-fit"}
          title="Start shopping"
          bordered
          to={"/search"}
        />
      </div>
    </div>
  );
};

export default Coupons;
