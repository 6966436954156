import React, { useState } from "react";
import INPUT from "../../Master/INPUT";
import BTN from "../../Master/BTN";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useReturnOrderMutation } from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { ARROW_BACK_BLACK_IC, CALL_IC, MAIL_IC } from "../../../assets";

const ReturnOrder = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("isServiceable");
    navigate("/login");
  };

  const [returnOrder] = useReturnOrderMutation();
  const [damagedProductVal, setDamagedProductVal] = useState("");
  const [wrongSizeVal, setWrongSizeVal] = useState("");
  const [wrongProductVal, setWrongProductVal] = useState("");
  const [otherToggle, setOtherToggle] = useState("");

  const returnOrderFormik = useFormik({
    initialValues: {
      other_reason: "",
    },
    onSubmit: async function (values) {
      returnOrder({
        body: {
          orderId: params?.returnOrderId,
          cart: {
            product_id: params?.returnProductId,
            sku: params?.returnProductSku,
            returnReason: `${
              damagedProductVal +
              ", " +
              wrongSizeVal +
              ", " +
              wrongProductVal +
              ", " +
              values.other_reason
            }`,
          },
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Order returned successfully.`);
          navigate("/profile-details#order-history");
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (
        damagedProductVal === "" &&
        wrongProductVal === "" &&
        wrongSizeVal === "" &&
        otherToggle === ""
      ) {
        // toast.error("Select reason for return order.");
        errors.other_reason = "Select reason for return order.";
      } else if (otherToggle !== "" && values?.other_reason === "") {
        errors.other_reason = "Other Reason is Required!";
      }
      return errors;
    },
  });

  console.log("otherToggle", otherToggle);

  return (
    <div className="w-full flex lg:flex-row emd:flex-col small:flex-col">
      <div className="lg:w-[20%] h-[100dvh] edm:hidden small:hidden py-6 px-6 bg-gray lg:flex flex-col items-start justify-start">
        <Link
          to="/profile-details#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Your Account
        </Link>
        <Link
          to="/profile-details#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Order History
        </Link>
        <Link
          to="/profile-details#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
        >
          Coupons
        </Link>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  mb-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>

        <span className="text-xl mt-10">Need Assistance ?</span>
        <div className="flex flex-col items-start">
          <span className="underline mt-3 cursor-pointer select-none">
            Contact Us
          </span>
          <div className="flex items-center mt-3">
            <div className="material-symbols-outlined">
              <img src={CALL_IC} alt="" className="w-6" />
            </div>
            <a href={`tel:18002028223`} className="ms-2 cursor-pointer">18002028223 </a>
          </div>
          <div className="flex items-center">
            <div className="material-symbols-outlined">
              <img src={MAIL_IC} alt="" className="w-6" />
            </div>
            <span className="ms-2"><a href='mailto:info@netre.in' className="email-link">info@netre.in</a></span>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray p-4 small:flex emd:flex lg:hidden items-center justify-start overflow-x-auto overflow-y-hidden text-nowrap whitespace-pre  hide_scrollbar">
        <Link
          to="/profile-details#your-account"
          className={` ${
            hash === "#your-account" ? "text-primary" : ""
          }  select-none cursor-pointer`}
        >
          Your Account
        </Link>
        <Link
          to="/profile-details#order-history"
          className={` ${
            hash === "#order-history" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Order History
        </Link>
        <Link
          to="/profile-details#coupons"
          className={` ${
            hash === "#coupons" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
        >
          Coupons
        </Link>
        <span
          className={` ${
            hash === "#" ? "text-primary" : ""
          }  ms-6 select-none cursor-pointer`}
          onClick={handleLogout}
        >
          Logout
        </span>
      </div>
      <div className="lg:w-[80%] emd:w-full small:w-full p-6">
        <div className="w-full flex pb-4 border-b border-light_secondary">
          <div
            className="material-symbols-outlined text-2xl cursor-pointer"
            onClick={() => navigate("/profile-details#order-history")}
          >
            <img src={ARROW_BACK_BLACK_IC} alt="" className="w-6" />
          </div>
          <span className="text-2xl font-bold ms-4">Return Order</span>
        </div>
        <div className="lg:w-1/2 emd:w-full small:w-full my-5 flex flex-col">
          <span className="text-lg font-semibold">Select Reason</span>
          <div className="grid grid-rows-2 grid-flow-col gap-4 mt-5">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="damaged"
                className="w-4 h-4 accent-black"
                value={"damaged"}
                checked={damagedProductVal === "damaged"}
                onChange={(e) =>
                  setDamagedProductVal(
                    damagedProductVal === "" ? e.target.value : ""
                  )
                }
              />
              <label htmlFor="damaged" className="ms-2">
                Damaged Product
              </label>
            </div>
            <div className="flex items-start">
              <input
                type="checkbox"
                id="wrong_size"
                className="w-4 h-4 accent-black "
                value={"wrong_size"}
                checked={wrongSizeVal === "wrong_size"}
                onChange={(e) =>
                  setWrongSizeVal(wrongSizeVal === "" ? e.target.value : "")
                }
              />
              <label htmlFor="wrong_size" className="ms-2">
                Wrong Size
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="wrong_product"
                className="w-4 h-4 accent-black "
                value={"wrong_product"}
                checked={wrongProductVal === "wrong_product"}
                onChange={(e) =>
                  setWrongProductVal(
                    wrongProductVal === "" ? e.target.value : ""
                  )
                }
              />
              <label htmlFor="wrong_product" className="ms-2">
                Wrong Product
              </label>
            </div>
            <div className="flex items-start">
              <input
                type="checkbox"
                id="size"
                className="min-w-4 max-w-4 min-h-4 max-h-4 accent-black mb-4" 
                value={"other"}
                checked={otherToggle === "other"}
                onChange={(e) => {
                  setOtherToggle(otherToggle === "" ? e.target.value : "");
                  otherToggle !== "" &&
                    returnOrderFormik?.setErrors({ other_reason: "" });
                }}
              />
              <INPUT
                placeHolder="Other"
                mainClass="w-[110px] ms-2 py-0 mb-0"
                className="py-0 px-1 w-full"
                name="other_reason"
                isDisabled={otherToggle !== "other"}
                onChange={returnOrderFormik.handleChange}
                val={returnOrderFormik.values.other_reason}
                onBlur={returnOrderFormik.handleBlur}
                errorMsg={returnOrderFormik.errors.other_reason}
                touched={returnOrderFormik.touched.other_reason}
              />
            </div>
          </div>
        </div>
        <div className="w-full border-t border-light_secondary pt-5 flex flex-col">
          {/* <span className="text-lg font-semibold">Refund Details</span>
        <div className="w-full flex mt-5 flex-wrap justify-between">
          <div className="lg:w-[35%] emd:w-full small:w-full flex flex-wrap">
            <div className="w-full flex flex-col">
              <span className="text-base">Choose refund method</span>
              <div className="w-full flex mt-4">
                <input
                  type="radio"
                  className="accent-black cursor-pointer"
                  id="source"
                />
                <label
                  className="ms-2 text-sm cursor-pointer select-none"
                  htmlFor="source"
                >
                  To original source of payment
                </label>
              </div>
              <div className="w-full flex items-start mt-4">
                <input
                  type="radio"
                  className="accent-black cursor-pointer"
                  id="new"
                />
                <div className="w-full ms-2 flex items-start justify-start flex-col">
                  <label
                    className="text-sm cursor-pointer select-none"
                    htmlFor="new"
                  >
                    Add new refund method
                  </label>
                  <INPUT
                    placeHolder="Card No."
                    mainClass="w-full"
                    className="w-full py-1 mt-1 px-0"
                  />
                  <div className="mt-1 w-full flex items-center justify-between">
                    <INPUT
                      placeHolder="Expiry MM/YY"
                      mainClass="w-[52%]"
                      className="w-full py-1 px-0"
                    />
                    <INPUT
                      placeHolder="CVV"
                      mainClass="w-[46%]"
                      className="w-full py-1 px-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[63%] emd:w-full small:w-full flex flex-col lg:mt-0 emd:mt-5 small:mt-5">
            <span className="text-base text-nowrap whitespace-pre ">Return Pickup Address</span>
            <div className="bg-gray p-2.5 mt-2 cursor-pointer select-none h-48 lg:min-w-[300px] lg:max-w-[300px] emg:w-full small:w-full flex flex-col justify-between ">
              <div className="w-full flex justify-between items-center">
                <span className="text-lg font-bold">USer Name (Home)</span>
              </div>
              <span className="text-base">
                Shop No 12, Nr Iskon Ambli Road, 384300 Lorem Ipsum
              </span>
              <div className="flex items-center">
                <div className="material-symbols-outlined text-xl">call</div>
                <span className="ms-1">90908070908</span>
              </div>
              <div className="text-primary underline">Edit</div>
            </div>
          </div>
        </div> */}
          <div className="w-full flex items-center lg:mt-0 emd:mt-5 small:mt-5">
            <BTN
              bordered
              title="CANCEL"
              className="lg:w-fit emd:w-fit small:w-[48%] py-1 px-4 text-sm rounded-none"
              onP={() => navigate("/profile-details#order-history")}
            />
            <BTN
              title="RETURN"
              className="ms-3 lg:w-fit emd:w-fit small:w-[48%]  py-1 px-4 text-sm rounded-none"
              type={"submit"}
              onP={returnOrderFormik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnOrder;
