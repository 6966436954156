import React, { useRef } from "react";
import { lens_only_privacy_doc } from "../../assets";

const PDFPreview = ({ showModel, setShowModel, setIAgree, data , heading }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModel(false);
    }
  };

  return (
    <>
      {showModel ? (
        <>
          <div class="modal-overlay" onClick={handleClickOutside}>
            <div class="modal-content" ref={modalRef}>
              <div class="modal-header">
                <h3>{heading}</h3>
                <button
                  type="button"
                  class="close-button"
                  data-modal-toggle="crud-modal"
                >
                  <div
                    className="material-symbols-outlined clear_ic"
                    onClick={() => setShowModel(!showModel)}
                  >
                    close
                  </div>
                </button>
              </div>
              <div class="modal-body">
                {/* <iframe
                  src={`https://docs.google.com/viewer?url=${window.location.origin}${lens_only_privacy_doc}&embedded=true`}
                  width="100%"
                  height="100%"
                  title="DOC Viewer"
                >
                  This browser does not support PDFs. Please download the PDF to
                  view it:
                  <a
                    href={`https://docs.google.com/viewer?url=${window.location.origin}${lens_only_privacy_doc}&embedded=true`}
                  >
                    Download PDF
                  </a>
                </iframe> */}
                <div
                className="w-full h-full overflow-y-auto pb-10 px-5 outline-none"
                  contentEditable="false"
                  dangerouslySetInnerHTML={{
                    __html: data
                  }}
                ></div>
              </div>
              <div
                className="agree_btn"
                onClick={() => {
                  setShowModel(false);
                  setIAgree(true);
                }}
              >
                Agree
              </div>
            </div>
          </div>
          <div class="background-overlay"></div>
        </>
      ) : null}
    </>
  );
};

export default PDFPreview;
