import "./browseFaceShape.scss";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Breakpoint } from "react-socks";

export default function BrowseFaceShape({ homeData }) {
  const navigate = useNavigate();

  //   {
  //     "attributeName": "Shape",
  //     "attributeValues": [
  //         "Round",
  //         "Cat Eye",
  //         "Wayfarer",
  //         "Rectangle",
  //         "Square",
  //         "Hexagon",
  //         "Oval"
  //     ]
  // },

  return (
    <div className="BrowseWiseFaceShape_Container lg:pb-[30px] emd:pb-[20px] small:pb-[20px] ">
      <div className="BrowseWiseFaceShape_Title font-sabon lg:py-10 emd:py-5 small:py-4">
        <h1 className="lg:text-[34px] emd:text-[30px] small:text-[25px] font-sabon">{homeData?.shapes_section?.title}</h1>
      </div>
      <Breakpoint
        large
        up
        className="w-[100%] pt-20 overflow-hidden"
      >
        <div className="BrowseWiseFaceShape_Block lg:px-32 emd:px-20 w-full grid gap-x-0 xl:gap-y-24 fix_lg:gap-y-16 grid-cols-3">
          {homeData?.shapes_section?.banners?.map((item, index) => (
            <div
              onClick={() =>
                navigate(item?.redirect, {
                  state: { shape: item?.button_text },
                })
              }
              className="BrowseWiseFaceShape_card m-auto"
              key={index}
            >
              <div className="Card_img flex items-center">
                <img
                  src={item.background}
                  alt="glass_img"
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="Overlay"></div>
              <div className="Card_img_Overlay">
                <img
                  src={item.image}
                  alt="glass_img"
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="Card_Title lg:text-[24px] emd:text-[20px] small:text-[15px]">
                <span>{item.button_text}</span>
              </div>
            </div>
          ))}
        </div>
      </Breakpoint>

      <Breakpoint className="w-full" medium down>
        <div className="carousel w-full px-4 grid gap-x-4 gap-y-2 grid-cols-2">
          {homeData?.shapes_section?.banners?.map((item, index) => (
            <div
              onClick={() =>
                navigate(item?.redirect, {
                  state: { shape: item?.button_text },
                })
              }
              className="BrowseWiseFaceShape_card h-[40vw] bg-[#f7f7f8] flex flex-col items-center cursor-pointer"
              key={index}
            >
              <div className="Card_img flex items-center">
                <img
                  src={item.background}
                  alt="glass_img"
                  height="100%"
                  width="90%"
                  className="m-auto"
                />
              </div>
              <div className="Overlay"></div>
              <div className="Card_img_Overlay">
                <img
                  src={item.image}
                  alt="glass_img"
                  height="100%"
                  width="100%"
                />
              </div>
              <div className="Card_Title lg:text-[24px] emd:text-[20px] small:text-[15px] py-2">
                <span>{item.button_text}</span>
              </div>
            </div>
          ))}
        </div>
      </Breakpoint>

      <div
        onClick={() => navigate(homeData?.shapes_section?.redirect)}
        className="try-now-btn See_All_Frame_btn py-2 px-3 lg:text-base emd:text-base small:text-sm lg:mt-[10px] emd:mt-[10px] small:mt-[10px] text-nowrap whitespace-pre "
      >
        {homeData?.shapes_section?.button_text}
        <div className="overlay"></div>
      </div>
    </div>
  );
}
